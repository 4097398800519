import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ToolbarComponent } from './toolbar.component';
import { NotificationsModule } from '../notifications/notifications.module';
import { WalletConnectModule } from '../wallet/wallet.module';
import { SharedModule } from 'app/shared/shared.module';
import { MessagesModule } from '../messages/messages.module';
import { ProjectSwitcherModule } from 'app/modules/admin/components/project-switcher/project-switcher.module';
import { EnvSwitcherModule } from '../env-switcher/env-switcher.module';
import { GrantButtonModule } from '../grant-button/grant-button.module';
import { PromoCodeModule } from 'app/modules/admin/components/promo-code/promo-code.module';
import { ShoppingCartHeaderModule } from '../shopping-cart/shopping-cart.module';
import { AsideDocsModule } from '../docs/docs.module';
import { UserModule } from '../user/user.module';
import { RouterModule } from '@angular/router';
@NgModule({
	declarations: [ToolbarComponent],
	imports: [
		CommonModule,
		RouterModule,
		MatIconModule,
		MessagesModule,
		NotificationsModule,
		UserModule,
		SharedModule,
		WalletConnectModule,
		AsideDocsModule,
		ShoppingCartHeaderModule,
		PromoCodeModule,
		GrantButtonModule,
		EnvSwitcherModule,
		ProjectSwitcherModule,
	],
	exports: [ToolbarComponent],
})
export class ToolbarModule {}
