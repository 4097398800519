<!-- custom-icon.component.html -->

<ng-container *ngIf="routerLink; else externalLink">
	<a [routerLink]="routerLink" [ngClass]="class">
		<ng-content></ng-content>
		<img
			class="bg-white hover:bg-violet custom-icon"
			[style]="{
				'-webkit-mask-image': 'url(' + iconPath + ')',
				'mask-image': 'url(' + iconPath + ')'
			}"
		/>
	</a>
</ng-container>

<ng-template #externalLink>
	<ng-container *ngIf="href; else iconButton">
		<a [href]="href" [target]="target" [ngClass]="class">
			<ng-content></ng-content>
			<img
				class="bg-white hover:bg-violet custom-icon"
				[style]="{
					'-webkit-mask-image': 'url(' + iconPath + ')',
					'mask-image': 'url(' + iconPath + ')'
				}"
			/>
		</a>
	</ng-container>
</ng-template>

<ng-template #iconButton>
	<button (click)="handleClick($event)" [ngClass]="class">
		<ng-content></ng-content>

		<img
			class="bg-white hover:bg-violet custom-icon"
			[style]="{
				'-webkit-mask-image': 'url(' + iconPath + ')',
				'mask-image': 'url(' + iconPath + ')'
			}"
		/>
	</button>
</ng-template>
