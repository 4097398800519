<div #hostElement class="body flex w-full">
	<!-- Loading bar -->
	<fuse-loading-bar></fuse-loading-bar>
	<app-parallax-stars></app-parallax-stars>

	<!-- Navigation -->
	<fuse-vertical-navigation
		class="navigation print:hidden"
		[ngClass]="{ fullWidth: isMobile }"
		[mode]="isMobile ? 'over' : 'side'"
		[name]="'mainNavigation'"
		[navigation]="navigation"
		[opened]="!isMobile"
	>
		<!-- Navigation header hook -->
		<ng-container fuseVerticalNavigationContentHeader>
			<mat-icon
				*ngIf="isMobile"
				class="text-white fixed right-5 top-5 z-10"
				(click)="closeNavigation()"
				>close</mat-icon
			>
			<div
				class="pr-13 mt-3 flex justify-end items-center w-full horizontal:absolute"
				*ngIf="isMobile"
			>
				<notifications></notifications>
				<shopping-cart></shopping-cart>
			</div>

			<!-- Logo -->
			<div
				class="flex items-center justify-center w-full h-15 mb-5 px-5 horizontal:mt-6"
			>
				<div class="flex items-center justify-center">
					<img src="assets/images/logo/logo-play.png" class="h-10" />
				</div>
			</div>

			<!-- User -->
			<div class="w-full px-5 gap-1 grid grid-cols-1">
				<div
					class="w-26 h-23 rounded-full bg-white overflow-hidden flex-shrink-0 mx-auto flex items-center justify-center mb-5"
				>
					<div
						class="avatar bg-white w-24 h-24 rounded-full overflow-hidden mb-2"
					>
						<img src="assets/images/avatars/default.png" alt="User avatar" />
					</div>
					<!-- We will implement this when backend sends user picture -->
					<!-- <img
						class="w-full h-full object-cover"
						*ngIf="user && user.profilePicture && user.profilePicture.medium"
						[src]="user.profilePicture.medium"
						alt="User avatar"
						referrerpolicy="no-referrer"
					/> -->

					<mat-icon
						class="w-36 h-36 min-w-36 min-h-36"
						*ngIf="!(user && user.profilePicture && user.profilePicture.medium)"
						[svgIcon]="'heroicons_solid:user-circle'"
					></mat-icon>
				</div>

				<div class="text-center overflow-hidden w-full mb-6">
					<h4 class="text-2xl font-effraHeavy text-white truncate">
						{{ user?.displayName }}
					</h4>
					<p
						class="text-md text-white font-semibold font-poppins truncate opacity-60"
					>
						{{ user?.email }}
					</p>
				</div>
			</div>
			<div class="text-center whitespace-nowrap" *ngIf="isMobile">
				<p class="inline-block">
					<wallet-connect></wallet-connect>
				</p>
				<p class="inline-block ml-2 align-top">
					<app-project-switcher></app-project-switcher>
				</p>
			</div>
		</ng-container>

		<!-- Navigation footer hook -->
		<ng-container fuseVerticalNavigationContentFooter>
			<aside-docs></aside-docs>
		</ng-container>
	</fuse-vertical-navigation>

	<!-- Wrapper -->

	<div class="flex flex-col flex-auto w-full min-w-0">
		<!-- Header -->
		<app-toolbar
			[user]="user"
			[isSidebarOpen]="true"
			(toggleSidebar)="toggleNavigation('mainNavigation')"
		></app-toolbar>

		<!-- Content -->
		<div class="flex flex-col flex-auto">
			<div
				class="flex flex-col flex-auto rounded-tl-md rounded-tr-md md:rounded-tl-20 md:rounded-tr-none"
			>
				<!-- *ngIf="true" hack is required here for router-outlet to work correctly.
			 Otherwise, layout changes won't be registered and the view won't be updated! -->
				<router-outlet *ngIf="true"></router-outlet>
			</div>
		</div>
	</div>
</div>
