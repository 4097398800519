// Angular
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

// Components
import { ReadyAsideMenuComponent } from "./menu.component";

@NgModule({
    imports: [
        // Angular
        CommonModule,
        RouterModule,
    ],
    declarations: [
        ReadyAsideMenuComponent,
    ],
    exports: [
        ReadyAsideMenuComponent,
    ],
})

export class ReadyAsideMenuModule { }
