<div *ngIf="hasCode" class="bg-gradient-primary rounded-md md:rounded-2xl md:rounded-tr-none md:rounded-br-none overflow-hidden mb-5 promo_banner">
    <div class="w-full p-4 md:p-6 bg-gradient-primary shadow-xl promo_drop relative">
        <div class="w-full max-w-160 mx-auto flex flex-col text-white text-center relative z-20">
            <h1 class="text-xl font-bold">You have a promo code activated</h1>

            <div class="my-5 mx-auto py-3 px-5 min-w-40 border-2 border-dashed border-white text-white font-bold">{{promoCode}}</div>

            <button (click)="claimCode()" class="promo_button">
                <span *ngIf="!onClaim">
                    Register Code Now
                </span>
                <mat-progress-spinner
                    *ngIf="onClaim"
                    [diameter]="24"
                    [mode]="'indeterminate'"></mat-progress-spinner>
            </button>

            <a class="mx-auto underline text-gray opacity-60 cursor-pointer mt-5" (click)="changeCode()">Or replace promo code</a>
        </div>
    </div>
</div>
