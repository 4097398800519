import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectService } from 'app/core/services/project.service';
import { Project } from 'app/core/defs/project';
import { FuseNavigationService } from '@fuse/components/navigation';

@Component({
	selector: 'app-project-switcher',
	templateUrl: './project-switcher.component.html',
	styleUrls: ['./project-switcher.component.scss'],
})
export class ProjectSwitcherComponent implements OnInit {
	projects: Project[];
	bottomSheetItems: any[];
	selectedProject: Project;

	constructor(
		private router: Router,
		private projectService: ProjectService,
		private fuseNavigationService: FuseNavigationService,
	) {}

	ngOnInit(): void {
		this.projectService.getProjects().then((projects: Project[]) => {
			this.projects = projects;
			this.bottomSheetItems = this.projects.map(({ name }) => ({
				value: name,
				viewValue: name,
			}));
			projects.find((project: Project) => {
				if (this.fuseNavigationService.projectId === project.id) {
					this.selectedProject = project;
				}
			});
		});
	}

	onProjectChange(project: Project): void {
		if (project) {
			this.router.navigate(['/project', project.id, 'overview']);
			this.fuseNavigationService.projectId = project.id;
		}
	}

	compareObjects(o1: Project, o2: Project): boolean {
		return o1.name === o2.name && o1.id === o2.id;
	}
}
