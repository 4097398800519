import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FuseAlert } from '@fuse/components/alert';
import { PromoCodeService } from '../promo-code.service';

@Component({
    templateUrl: 'claim.component.html',
    styleUrls: ['claim.component.scss'],
})

export class PromoCodeClaimComponent {
    public readonly promoForm: FormGroup;
    public promoCode: string;
    public showAlert: boolean;
    public alert: FuseAlert = {} as FuseAlert;

    constructor(
        public dialogRef: MatDialogRef<PromoCodeClaimComponent>,
        private readonly _promoService: PromoCodeService,
    ) {
        this.promoCode = this._promoService.getPromoCodeValue();

        this.promoForm = new FormGroup({
            code: new FormControl(this.promoCode, Validators.required),
        });

        this._promoService.getPromoObject().subscribe(value => {
            this.promoForm.enable();

            if (value && value.type && value.message) {
                this.alert.type = value.type;
                this.alert.message = value.message;
                this.showAlert = true;
            }
        });
    }

    public claimCode(): void {
        this.showAlert = false;
        this.promoForm.disable();
        this._promoService.addUserToPromoCode();
    }
}
