<div class="flex flex-col gap-4">
    <div class="text-center">
        <h2 mat-dialog-title class="text-xl">Register your promo code</h2>
        <h4 class="text-md">Start by typing your promo code to register on the list. After you are successfully registered, you'l'be able to claim the code.</h4>
    </div>

    <!-- Alert -->
    <fuse-alert
        class="my-5"
        *ngIf="showAlert"
        [appearance]="'outline'"
        [showIcon]="false"
        [type]="alert.type">
        {{alert.message}}
    </fuse-alert>

    <form [formGroup]="promoForm" class="flex flex-col">
        <!-- Promo Code -->
        <mat-form-field appearance="fill">
            <mat-label>Promo Code</mat-label>
            <input required matInput placeholder="Type in your promo code" formControlName="code" />
            <mat-error *ngIf="promoForm.get('code').hasError('required')">Promo Code is required</mat-error>
        </mat-form-field>

        <button
            type="button"
            class="fuse-mat-button-large px-20"
            mat-flat-button
            [color]="'primary'"
            [disabled]="promoForm.disabled"
            (click)="claimCode()">

            <span *ngIf="!promoForm.disabled">
                Register Code Now
            </span>
            <mat-progress-spinner
                *ngIf="promoForm.disabled"
                [diameter]="24"
                [mode]="'indeterminate'"></mat-progress-spinner>
        </button>
    </form>
</div>
