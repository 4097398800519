// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const isPreLaunchMode = true

export const environment = {
    production: false,
    version: '0.0.6',
    url: {
        gen1: {
            base: 'https://us-central1-readymaster-development.cloudfunctions.net/',
        },
        gen2: {
            prefix: 'https://',
            sufix: 'uc.a.run.app',
            project: 'd7qegcmwxa',
        },
        mint: 'https://lightlink-direct-mint-lj5emc5bba-uc.a.run.app'
    },
    nft: {
        api_key: 'ak4ClPYq259ou7IVWWx1OmFr5xDHrzWHk9A3cwgpM1gXB0TBjZRHN7s8ViUZGQ4y',
    },
    projectId: "YRng2YkZVYcqkgHTyfmQ",
    dashboard: {
      apiKey: "AIzaSyBqgM03uupNvY0ZBOCFdssN3rB2LcW6sjA",
      authDomain: "developer-dashboard-99v.firebaseapp.com",
      projectId: "developer-dashboard-99v",
      storageBucket: "developer-dashboard-99v.appspot.com",
      messagingSenderId: "552734030982",
      appId: "1:552734030982:web:6798826e47ca3a19f26507",
      measurementId: "G-R3G1EPMFGJ"
    },
    firebaseMaster: {
      apiKey: "AIzaSyBJ212bey2FAfhN-JZpw4eHi8loM7XUdW4",
      authDomain: "readymaster-development.firebaseapp.com",
      databaseURL: "https://readymaster-development-default-rtdb.firebaseio.com",
      projectId: "readymaster-development",
      storageBucket: "readymaster-development.appspot.com",
      messagingSenderId: "767053156338",
      appId: "1:767053156338:web:480511463f746e3664a56b",
      measurementId: "G-BSGX9EY4TW"
    },
    firestoragePath: "gs://readymaster-development.appspot.com",
    readyGamesDeveloper: {
        apiKey: "AIzaSyCKO_6OhnSLglFuoOvLwmQR-RQ991JV8Zw",
        authDomain: "readygamesdevelopper.firebaseapp.com",
        projectId: "readygamesdevelopper",
        storageBucket: "readygamesdevelopper.appspot.com",
        messagingSenderId: "735391127933",
        appId: "1:735391127933:web:7b8c91df905b7930509414",
        measurementId: "G-P5VBGEL894"
    },
    smart_contract: {
        chain: 'mumbai',
        adminAddress: "0x4c6348bf16FeA56F3DE86553c0653b817bca799A",
        auraAddress: "0x5210A32bC4d3aFf0C7596Fe592d28aE50F9b75fd",
        fuseBlockAddress: "0xb137b694E05D0C2D2E4D40C3267e1f4007A73b73",
        itemAddress: "0x90E45D789717c9A179F8DA5A8BDdd5c564E928fc",
        stakeAddress: "0x7B7c18ecA83455da4181bB48D52285d92F1487fc",
    },
    paymentBackendUrl: 'https://us-central1-readygames-devmp.cloudfunctions.net/paymentServer', // it's DEV!
    stripe: {
        /*
        debug account:
        login: ihorklymenko217@gmail.com
        pwd: *RZPFGYC)vUp
        emergency backup code: uqrb-akhr-dtut-bxjj-qbac
        */
        // publishableKey: 'pk_test_jcxxoVos8NI0yjNt8pXT40PM00QBowZsZH', // dev account
        publishableKey: 'pk_test_TYooMQauvdEDq54NiTphI7jx', // stripe test publishable key
        localUrl: false
    },
    rdyxGrantTypeformUrl: 'https://readygamesofficial.typeform.com/to/wAE05obt',
    envName: 'Staging',
    altEnvInstance: { // alternative environment instance
        name: 'Production',
        url: 'https://dev.ready.gg'
    },
    auth: {
        url: 'https://development-oauth.ready.gg'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
