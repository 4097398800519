// Angular
import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';

// Rxjs
import { Subject } from 'rxjs';

// Services
import { WalletService } from 'app/modules/admin/components/wallet/wallet.service';

// Interface
import { WalletV3Ext } from 'app/modules/admin/components/wallet/wallet.interface';

@Component({
	selector: 'wallet-connect',
	styleUrls: ['wallet.component.scss'],
	encapsulation: ViewEncapsulation.None,
	template: `
		<div
			class="wallet-connect"
			[class.active]="!!wallet"
			(click)="connectWallet()"
		>
			<a>
				<mat-icon svgIcon="header-wallet"></mat-icon>
				<small class="w-max">{{ label }}</small>
			</a>
		</div>
	`,
})
export class WalletConnectComponent implements OnDestroy {
	public wallet: WalletV3Ext | null = null;
	public label: string;
	public loading = false;

	constructor(private readonly _walletService: WalletService) {
		this._walletService.getRgnWallet();

		this._walletService.wallet$.subscribe((data) => {
			this.wallet = data;
			if (this.wallet?.wallets?.length) {
				this.label = this.wallet.wallets[0].address;
			} else {
				this.label = 'Connect wallet';
			}
		});
	}

	ngOnDestroy(): void {}

	public async connectWallet() {
		if (this.loading) return; // block clicks if request going

		this.loading = true;
		this._walletService.getRgnWallet();
		this.loading = false;
	}
}
