// Angular
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";

// Angular Material
import { MatIconModule } from "@angular/material/icon";

// Components
import { WalletConnectComponent } from "./wallet.component";

@NgModule({
    imports: [
        // Angular
        CommonModule,

        // Angular Material
        MatIconModule,
        MatDialogModule,
    ],
    declarations: [
        WalletConnectComponent,
    ],
    exports: [
        WalletConnectComponent,
    ],
})

export class WalletConnectModule { }
