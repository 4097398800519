import { Component, ViewEncapsulation } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
	selector: 'grant-button',
	styleUrls: ['./grant-button.component.scss'],
	template: `
		<div class="grant-button" (click)="gotoRdyxGrantForm()">
			<a>
				<mat-icon class="md:mr-2 text-white">check_circle</mat-icon>
				<small class="">Apply for an $PLAY Grant</small>
			</a>
		</div>
	`,
	encapsulation: ViewEncapsulation.None,
})
export class GrantButtonComponent {
	public gotoRdyxGrantForm() {
		window.location.href = environment.rdyxGrantTypeformUrl;
	}
}
