import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlService } from 'app/url.service';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { Project } from 'app/core/defs/project';

export interface ProjectsResponse {
  projects: Project[];
}

export interface ProjectResponse {
  project: Project;
}

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends UrlService {
  constructor(
    private readonly _http: HttpClient,
  ) {
    super();
  }

  public createGoogleProjectFromName(data: any) {
    const url = this.getUrlGen1('projectManagement-createGoogleProjectFromName');
    return firstValueFrom(this._http.post<any>(url, data));
  }

  public async getProjects() {
    const url = this.getUrlGen1('projectsCollection-getUserProjects');
    const result = await firstValueFrom(this._http.post<ProjectsResponse>(
      url,
      { version: 2 }));
    return result.projects || [];
  }

  public async getProjectById(projectId: string) {
    const url = this.getUrlGen1('projectsCollection-getProjectById');
    const response = await firstValueFrom(this._http.post<ProjectResponse>(
      url,
      {
        projectId,
        version: 2,
      }));
    return response.project;
  }

  public async createAndroidApp(appId: string, appNickname: string, packageName: string): Promise<any> {
    const url = this.getUrlGen1('projectManagement-createAndroidApp');
    const data = {
      appId,
      appNickname,
      packageName,
    };
    const response = await lastValueFrom(this._http.post<any>(url, data));
    return {
      message: response.message,
      appName: response.appName,
      appId: response.appId,
      projectId: response.projectId,
    };
  }
  public async deleteAndroidApp(appId: string, packageName: string): Promise<any> {
    const url = this.getUrlGen1('projectManagement-deleteAndroidApp');
    const data = {
      appId,
      packageName,
    };
    const response = await lastValueFrom(this._http.post<any>(url, data));
    return {
      message: response.message,
      appId: response.appId,
      projectId: response.projectId,
    };
  }
  public async addShaToAndroidApp(appId: string, packageName: string, sha: string): Promise<any> {
    const url = this.getUrlGen1('projectManagement-addShaToAndroidApp');
    const data = {
      appId,
      packageName,
      sha,
    };
    const response = await lastValueFrom(this._http.post<any>(url, data));
    return {
      message: response.message,
      androidAppId: response.androidAppId,
      projectId: response.projectId,
    };
  }
  public async createiOSApp(appId: string, appNickname: string, bundleId: string): Promise<any> {
    const url = this.getUrlGen1('projectManagement-createiOSApp');
    const data = {
      appId,
      appNickname,
      bundleId,
    };
    const response = await lastValueFrom(this._http.post<any>(url, data));
    return {
      message: response.message,
      appName: response.appName,
      appId: response.appId,
      projectId: response.projectId,
    };
  }
  public async deleteiOSApp(appId: string, bundleId: string): Promise<any> {
    const url = this.getUrlGen1('projectManagement-deleteiOSApp');
    const data = {
      appId,
      bundleId,
    };
    const response = await lastValueFrom(this._http.post<any>(url, data));
    return {
      message: response.message,
      appId: response.appId,
      projectId: response.projectId,
    };
  }
  public async createWebApp(appId: string, appNickname: string): Promise<any> {
    const url = this.getUrlGen1('projectManagement-createWebApp');
    const data = {
      appId,
      appNickname,
    };
    const response = await lastValueFrom(this._http.post<any>(url, data));
    return {
      message: response.message,
      appName: response.appName,
      appId: response.appId,
      projectId: response.projectId,
    };
  }
  public async deleteWebApp(appId: string, appIdWeb: string): Promise<any> {
    const url = this.getUrlGen1('projectManagement-deleteWebApp');
    const data = {
      appId,
      appIdWeb,
    };
    const response = await lastValueFrom(this._http.post<any>(url, data));
    return {
      message: response.message,
      appId: response.appId,
      projectId: response.projectId,
    };
  }
  public async getAndroidFirebaseAppConfig(appId: string, packageName: string): Promise<any> {
    const url = this.getUrlGen1('projectManagement-getAndroidFirebaseAppConfig');
    const data = {
      appId,
      packageName,
    };
    const response = await lastValueFrom(this._http.post<any>(url, data));
    return {
      configFilename: response.configFilename,
      configFileContents: response.configFileContents,
    };
  }
  public async getiOSFirebaseAppConfig(appId: string, bundleId: string): Promise<any> {
    const url = this.getUrlGen1('projectManagement-getiOSFirebaseAppConfig');
    const data = {
      appId,
      bundleId,
    };
    const response = await lastValueFrom(this._http.post<any>(url, data));
    return {
      configFilename: response.configFilename,
      configFileContents: response.configFileContents,
    };
  }
  public async getWebFirebaseAppConfig(appId: string): Promise<any> {
    const url = this.getUrlGen1('projectManagement-getWebFirebaseAppConfig');
    const data = {
      appId,
    };
    const response = await lastValueFrom(this._http.post<any>(url, data));
    return {
      configFilename: response.configFilename,
      configFileContents: response.configFileContents,
    };
  }
}
