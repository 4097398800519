import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

export const appRoutes: Route[] = [
	// Redirect empty path to base project selection
	{ path: '', pathMatch: 'full', redirectTo: 'project' },

	// Redirect signed in user to the sign up flow
	{ path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'project' },

	// Auth routes for guests
	{
		path: '',
		canActivate: [NoAuthGuard],
		canActivateChild: [NoAuthGuard],
		component: LayoutComponent,
		data: {
			layout: 'empty',
		},
		children: [
			{
				path: 'auth',
				loadChildren: () =>
					import('app/modules/auth/auth.module').then((m) => m.AuthModule),
			},
		],
	},

	// Admin routes - Select Project
	{
		path: '',
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		component: LayoutComponent,
		resolve: {
			initialData: InitialDataResolver,
		},
		data: {
			layout: 'empty',
		},
		children: [
			{
				path: 'project',
				loadChildren: () =>
					import('app/modules/admin/project/project.module').then(
						(m) => m.ProjectModule,
					),
			},
		],
	},

	// Admin routes - Approved Developers
	{
		path: '',
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		component: LayoutComponent,
		resolve: {
			initialData: InitialDataResolver,
		},
		children: [
			{
				path: 'project/:id/dashboard',
				loadChildren: () =>
					import('app/modules/admin/project/dashboard/dashboard.module').then(
						(m) => m.DashboardModule,
					),
			},
			{
				path: 'project/:id/settings',
				loadChildren: () =>
					import('app/modules/admin/project/detail/detail.module').then(
						(m) => m.DetailModule,
					),
			},
			{
				path: 'project/:id/overview',
				loadChildren: () =>
					import('app/modules/admin/project/overview/overview.module').then(
						(m) => m.OverviewModule,
					),
			},
			{
				path: 'project/:id/team',
				loadChildren: () =>
					import('app/modules/admin/project/team/team.module').then(
						(m) => m.TeamModule,
					),
			},
			{
				path: 'project/:id/leaderboard',
				loadChildren: () =>
					import(
						'app/modules/admin/project/leaderboard/leaderboard.module'
					).then((m) => m.LeaderboardModule),
			},
			{
				path: 'project/:id/achievements',
				loadChildren: () =>
					import(
						'app/modules/admin/project/achievements/achievements.module'
					).then((m) => m.AchievementsModule),
			},
			{
				path: 'project/:id/play',
				loadChildren: () =>
					import('app/modules/admin/project/play-menu/play-menu.module').then(
						(m) => m.PlayMenuModule,
					),
			},
			{
				path: 'project/:id/beta-test',
				loadChildren: () =>
					import('app/modules/admin/project/beta-test/beta-test.module').then(
						(m) => m.BetaTestModule,
					),
			},
			{
				path: 'project/:id/fuseblock',
				loadChildren: () =>
					import('app/modules/admin/project/fuseblock/fuseblock.module').then(
						(m) => m.FuseBlockModule,
					),
			},
			{
				path: 'project/:id/stake',
				loadChildren: () =>
					import('app/modules/admin/project/stake/stake.module').then(
						(m) => m.StakeModule,
					),
			},
			{
				path: 'project/:id/program',
				loadChildren: () =>
					import(
						'app/modules/admin/project/developer-program/developer-program.module'
					).then((m) => m.DeveloperProgramModule),
			},
			{
				path: 'project/:id/wallet',
				loadChildren: () =>
					import('app/modules/admin/project/wallet/wallet.module').then(
						(m) => m.WalletModule,
					),
			},
			{
				path: 'project/:id/virtual-item',
				loadChildren: () =>
					import(
						'app/modules/admin/project/virtual-item/virtual-item.module'
					).then((m) => m.VirtualItemModule),
			},
			{
				path: 'project/:id/mint',
				loadChildren: () =>
					import('app/modules/admin/project/mint/mint.module').then(
						(m) => m.MintModule,
					),
			},
			{
				path: 'project/:id/reports',
				loadChildren: () =>
					import('app/modules/admin/project/reports/reports.module').then(
						(m) => m.ReportsModule,
					),
			},
			{
				path: 'project/:id/breed-craft',
				loadChildren: () =>
					import(
						'app/modules/admin/project/breed-craft/breed-craft.module'
					).then((m) => m.BreedCraftModule),
			},
			{
				path: 'project/:id/fuseblock-shop',
				loadChildren: () =>
					import(
						'app/modules/admin/project/fuseblock-shop/fuseblock-shop.module'
					).then((m) => m.FuseblockShopModule),
			},
			{
				path: 'project/:id/shopping-cart',
				loadChildren: () =>
					import(
						'app/modules/admin/project/shopping-cart/shopping-cart.module'
					).then((m) => m.ShoppingCartModule),
			},
			{
				path: 'project/:id/milestones',
				loadChildren: () =>
					import('app/modules/admin/project/milestones/milestones.module').then(
						(m) => m.MilestonesModule,
					),
			},
		],
	},

	// Admin routes - Unapproved Developers
	{
		path: '',
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		component: LayoutComponent,
		resolve: {
			initialData: InitialDataResolver,
		},
		children: [
			{
				path: 'welcome/dashboard',
				loadChildren: () =>
					import('app/modules/admin/project/dashboard/dashboard.module').then(
						(m) => m.DashboardModule,
					),
			},
			{
				path: 'welcome/leaderboard',
				loadChildren: () =>
					import(
						'app/modules/admin/project/leaderboard/leaderboard.module'
					).then((m) => m.LeaderboardModule),
			},
			{
				path: 'welcome/achievements',
				loadChildren: () =>
					import(
						'app/modules/admin/project/achievements/achievements.module'
					).then((m) => m.AchievementsModule),
			},
			{
				path: 'welcome/play',
				loadChildren: () =>
					import('app/modules/admin/project/play-menu/play-menu.module').then(
						(m) => m.PlayMenuModule,
					),
			},
			{
				path: 'welcome/sign-up-flow',
				loadChildren: () =>
					import(
						'app/modules/admin/project/developer-sign-up/developer-sign-up.module'
					).then((m) => m.DeveloperSignUpModule),
			},
		],
	},
];
