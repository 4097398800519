<div class="aside-docs p-4 rounded-md text-white font-poppins">
	<div class="uppercase text-sm font-semibold tracking-wide mb-1 smallTitle">
		Grow
	</div>
	<h2 class="text-xl font-effraHeavy mb-2">Discover $PLAY</h2>
	<p class="text-md mb-4">
		The PLAYhub ecosystem revolves around the $PLAY token economy.
	</p>
	<div class="space-y-2 text-white text-md">
		<a
			href="https://readygames.gitbook.io/readyx"
			target="_blank"
			class="info-link"
		>
			Whitepaper
		</a>
		<a
			href="https://readygamesofficial.typeform.com/to/wAE05obt"
			target="_blank"
			class="info-link"
		>
			Apply for grant
		</a>
	</div>
</div>

<div class="aside-docs p-4 rounded-md text-white font-poppins">
	<div class="uppercase text-sm font-semibold tracking-wide mb-1 smallTitle">
		Ask
	</div>
	<h2 class="text-xl font-effraHeavy mb-2">$PLAY Documentation</h2>
	<p class="text-md mb-4">
		Read our public SDK and learn why play tools are one step ahead of the
		industry.
	</p>
	<div class="space-y-2 text-white text-md">
		<a
			href="https://readygames.gitbook.io/readygg-sdk-documentation"
			target="_blank"
			class="info-link"
		>
			Go to docs
		</a>
	</div>
</div>
