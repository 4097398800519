import { Component, HostListener, OnInit } from '@angular/core';

@Component({
    selector: 'app-parallax-stars',
    templateUrl: './parallax-stars.component.html',
    styleUrls: ['./parallax-stars.component.scss']
})
export class ParallaxStarsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    @HostListener('document:mousemove', ['$event'])
    onMouseMove(e: MouseEvent) {
        const width = window.innerWidth / 2;
        const height = window.innerHeight / 2;
        const mouseX = e.clientX;
        const mouseY = e.clientY;
        const moveX = -(mouseX - width) * 0.02;
        const moveY = -(mouseY - height) * 0.02;

        const starsImg = document.querySelector('picture img') as HTMLElement;
        if (starsImg) {
            starsImg.style.transform = `translate(${moveX}px, ${moveY}px)`;
        }
    }

}
