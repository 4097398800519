// Angular
import { Injectable } from "@angular/core";

// Angular Material
// import { MatDialog } from "@angular/material/dialog";

// Rxjs
import { BehaviorSubject, firstValueFrom } from "rxjs";

// Service
// import { AuthService } from "app/core/auth/auth.service";
import { AuthNewService } from "app/core/auth/auth.new.service";

// Component
// import { WalletConnectDialogComponent } from "./dialog/connect.component";

// Interface
import { WalletV3Ext, WalletsResponseV3 } from "./wallet.interface";
import { IAuthNewUserData } from "app/core/auth/interface/auth.new.interface";
import { UrlService } from "app/url.service";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: 'root' })
export class WalletService extends UrlService {
    private user: IAuthNewUserData;
    private readonly wallet = new BehaviorSubject<WalletV3Ext | null>(null);
    readonly wallet$ = this.wallet.asObservable();

    constructor(
        private readonly _authNewService: AuthNewService,
        // private readonly _dialog: MatDialog,
        private readonly _http: HttpClient,
    ) {
        super();

        this._authNewService.user$.subscribe((data) => {
            this.user = data;

            if (!data) {
                this.clearWallet();
            }
        });
    }

    /*
    private setRgnWallet(walletAddress: string): Promise<boolean> {
        const url = this.getUrlGen1('wallets-setPrimaryRgnWallet');

        return firstValueFrom(
            this._http.post(url, { walletAddress, version: 2 }).pipe(
                map(() => true),
                catchError(() => of(false)),
            )
        );
    }

    public getWallet(): Observable<Wallet> {
        return this.wallet$;
    }

    public getWalletNow(): Wallet {
        return this.wallet$.value;
    }
    */

    public clearWallet(): void {
        this.wallet.next(null);
    }

    /*
    public async setWallet(address: string, type: 'metamask'|'rgn'): Promise<boolean> {
        if (type === 'rgn') {
            return this.setRgnWallet(address).then(response => {
                if (response) {
                    this.wallet$.next({
                        isSelected: true,
                        type: type,
                        address: address,
                    });
                }

                return response;
            });
        }
    }
    */

    public async getRgnWallet() {
        try {
            const data = await firstValueFrom(this._http.post<WalletsResponseV3>(
                this.getUrlGen1('wallets-getUserWallets'),
                {
                    data: { version: 3 }
                }
            ));

            const wallets = Object.entries(data.result.hash).map((data): WalletV3Ext => ({
                wallets: data[1],
                id: `0x${data[0]}`,
            }));

            if (wallets.length === 0) return false;

            this.wallet.next(wallets[0]);

            return true;
        } catch (err) {
            return false;
        }
    }

    /*
    public async createRgnWallet(password: string) {
        const url = this.getUrlGen1('wallets-createWallet');
        const response = await firstValueFrom<any>(this._http.post(url, { version: 2, password }));
        return response;
    }

    public connectWallet(): Observable<any> {
        const dialogRef = this._dialog.open(WalletConnectDialogComponent, {
            data: null,
            maxWidth: '600px',
            panelClass: '',
            width: '90%',
        });

        return dialogRef.afterClosed();
    }
    */
}
