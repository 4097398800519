import { HttpClient } from '@angular/common/http';
import { map, of, Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

import { environment } from 'environments/environment';
import { PaymentData } from '../defs/shop';
import { BasePaymentProvider, PaymentResult, PaymentStatus } from './base.provider';

export class AliPayProvider extends BasePaymentProvider {
    constructor(
        private http: HttpClient,
        private logger: NGXLogger,
    ) {
        super();
    }

    // override prepare(params: PaymentData): Observable<boolean> {}
    // override reset() {}

    override pay(
        formData: Record<string, unknown>,
        params: Record<string, unknown>,
    ): Observable<PaymentResult> {
        // TODO
        return of({
            status: PaymentStatus.Failed,
            errorMessage: 'Not implemented'
        } as PaymentResult);
    }
}
