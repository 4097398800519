import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

// Components
import { AsideDocsComponent } from './docs.component';

@NgModule({
	imports: [CommonModule, MatIconModule],
	declarations: [AsideDocsComponent],
	exports: [AsideDocsComponent],
})
export class AsideDocsModule {}
