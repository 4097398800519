We use cookies to enhance your browsing experience. By clicking “Accept”, you consent to our use of cookies.

<button mat-flat-button
    color="primary"
    (click)="onConfirm()"
    [disabled]="onProcess"
>
    <span *ngIf="!onProcess">ACCEPT</span>

    <mat-progress-spinner
        *ngIf="onProcess"
        [diameter]="24"
        [mode]="'indeterminate'"></mat-progress-spinner>
</button>

<button mat-flat-button (click)="onClose()" [disabled]="onProcess">CLOSE</button>
