import { Component } from '@angular/core';
import { FuseNavigationService } from '@fuse/components/navigation';

@Component({
    selector: 'ready-aside-menu',
    templateUrl: 'menu.component.html',
    styleUrls: ['menu.component.scss'],
})

export class ReadyAsideMenuComponent {
    constructor(
        private readonly _fuseNavigationService: FuseNavigationService,
    ) {}

    getLink(link: string): string {
        if (this._fuseNavigationService?.projectId) {
            return 'project/'+[this._fuseNavigationService.projectId]+'/'+[link];
        }

        return 'project/'
    }
}
