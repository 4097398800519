/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
	{
		id: 'project',
		title: 'Your Projects',
		type: 'basic',
		icon: 'mat_outline:assignment',
		link: '/',
		function: () => {},
		exactMatch: true,
	},
	// {
	// 	id: 'milestones',
	// 	title: 'Milestones',
	// 	type: 'basic',
	// 	icon: 'mat_outline:flag',
	// 	link: '/milestones',
	// },
	{
		id: 'overview',
		title: 'Overview',
		type: 'basic',
		icon: 'menu-overview',
		link: '/overview',
	},
	{
		id: 'play',
		title: '$PLAY',
		type: 'basic',
		icon: 'menu-play',
		link: '/play',
	},
	{
		id: 'dashboard',
		title: 'Analytics',
		type: 'basic',
		icon: 'menu-analytics',
		link: '/dashboard',
	},
	{
		id: 'leaderboards',
		title: 'Leaderboards',
		type: 'basic',
		icon: 'menu-leaderboard',
		link: '/leaderboard',
	},
	{
		id: 'achievements',
		title: 'Achievements',
		type: 'basic',
		icon: 'menu-achievements',
		link: '/achievements',
	},
	{
		id: 'virtual-item',
		title: 'Virtual Items',
		type: 'basic',
		icon: 'menu-virtual-items',
		link: '/virtual-item',
	},
	{
		id: 'mint',
		title: 'Minting Desk',
		type: 'basic',
		icon: 'menu-minting-desk',
		link: '/mint',
	},
	{
		id: 'fuseblock-shop',
		title: 'Fuseblock Shop',
		type: 'basic',
		icon: 'credit_card',
		link: '/fuseblock-shop',
	},

	// --------------------
	// Disabled for release
	// --------------------

	// {
	//     id: 'team',
	//     title: 'Team',
	//     type: 'basic',
	//     icon: 'mat_outline:account_balance_wallet',
	//     link: '/team'
	// },
	// {
	//     id: '',
	//     title: 'Breed & Craft',
	//     type: 'basic',
	//     icon: 'mat_outline:account_balance_wallet',
	//     link: '/breed-craft'
	// },
	// {
	//     id: 'beta',
	//     title: 'Beta test',
	//     type: 'basic',
	//     icon: 'mat_outline:account_balance_wallet',
	//     link: '/beta-test'
	// },
	// {
	//     id: 'reports',
	//     title: 'Reports',
	//     type: 'basic',
	//     icon: 'mat_outline:bug_report',
	//     link: '/reports'
	// },
	// {
	//     id: 'fuseblock',
	//     title: 'Fuseblock',
	//     type: 'basic',
	//     icon: 'mat_outline:account_balance_wallet',
	//     link: '/fuseblock'
	// },
	// {
	//     id: 'stake',
	//     title: 'Stake',
	//     type: 'basic',
	//     icon: 'mat_outline:account_balance_wallet',
	//     link: '/stake'
	// },
	// {
	//     id: 'program',
	//     title: 'Dev Program',
	//     type: 'basic',
	//     icon: 'mat_outline:account_balance_wallet',
	//     link: '/program'
	// },
	// {
	//     id: 'wallet',
	//     title: 'Wallet',
	//     type: 'basic',
	//     icon: 'mat_outline:account_balance_wallet',
	//     link: '/wallet'
	// },
];

export const preLaunchNavigation: FuseNavigationItem[] = [
	{
		id: 'developer-sign-up',
		title: 'Developer Sign Up',
		type: 'basic',
		icon: 'mat_outline:group',
		link: 'welcome/sign-up-flow',
		function: () => {},
	},
	{
		id: 'project',
		title: 'Project',
		type: 'basic',
		icon: 'heroicons_outline:lock-closed',
	},
	{
		id: 'team',
		title: 'Team',
		type: 'basic',
		icon: 'heroicons_outline:lock-closed',
	},
	{
		id: 'dashboard',
		title: 'Analytics',
		type: 'basic',
		icon: 'mat_outline:analytics',
		link: 'welcome/dashboard',
		function: () => {},
	},
	{
		id: 'leaderboards',
		title: 'Leaderboards',
		type: 'basic',
		icon: 'mat_outline:leaderboard',
		link: 'welcome/leaderboard',
		function: () => {},
	},
	{
		id: 'achievements',
		title: 'Achievements',
		type: 'basic',
		icon: 'mat_outline:emoji_events',
		link: 'welcome/achievements',
		function: () => {},
	},
	{
		id: 'play',
		title: '$PLAY',
		type: 'basic',
		icon: 'mat_outline:account_balance_wallet',
		link: 'welcome/play',
		function: () => {},
	},
];

export const compactNavigation: FuseNavigationItem[] = [
	{
		id: 'example',
		title: 'Example',
		type: 'basic',
		icon: 'heroicons_outline:chart-pie',
		link: '/example',
	},
];

export const futuristicNavigation: FuseNavigationItem[] = [
	{
		id: 'example',
		title: 'Example',
		type: 'basic',
		icon: 'heroicons_outline:chart-pie',
		link: '/example',
	},
];

export const horizontalNavigation: FuseNavigationItem[] = [
	{
		id: 'example',
		title: 'Example',
		type: 'basic',
		icon: 'heroicons_outline:chart-pie',
		link: '/example',
	},
];
