import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ShoppingCart, ShoppingCartItem } from "./interface/cart.type";

@Injectable()
export class ShoppingCartService
{
    private _cart: BehaviorSubject<ShoppingCart>;

    /**
     * Constructor
     */
    constructor() {
        this._cart = new BehaviorSubject({
            items: {
                list: [],
                total_price: 0,
                total_quantity: 0,
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for shortcuts
     */
    get cart$(): Observable<ShoppingCart> {
        return this._cart.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Item Management
    // -----------------------------------------------------------------------------------------------------
    private mockId(prefix: string) {
        return Math.random().toString(36).replace('0.',prefix || '');
    }

    private sumTotal(cart: ShoppingCart) {
        cart.items.total_price = 0;
        cart.items.total_quantity = 0;

        cart.items.list.forEach(item => {
            cart.items.total_price += item.price_total;
            cart.items.total_quantity += item.quantity;
        });

        this._cart.next(cart);
    }

    public mockAdd(): void {
        // --Mock data
        let qty = Math.floor(Math.random() * 5);
        qty = qty === 0 ? 1 : qty;

        let price = Math.floor(Math.random() * 20);
        price = price === 0 ? 1 : price;

        const newItem: ShoppingCartItem = {
            id: this.mockId('uuid-'),
            name: 'New item',
            image: 'assets/images/ui/placeholder-fuseblock.png',
            price: price,
            quantity: qty,
            price_total: price * qty,
        };
        console.log('New Item: ', newItem);

        const newCart = this._cart.value;
        console.log('New Cart: ', newCart)
        // --Mock data

        newCart.items.list.push(newItem);
        this.sumTotal(newCart);
    }

    public itemAdd(item: ShoppingCartItem): void {
        const newCart = { ...this._cart.value };

        const cartItem = this._cart.value.items.list.find((ci) => ci.name === item.name);

        if (cartItem) {
            cartItem.quantity += item.quantity;
            cartItem.price_total = item.quantity * cartItem.price;
        } else {
            newCart.items.list.push(item);
        }

        this.sumTotal(newCart);
    }

    public itemRemove(index: number) {
        const newCart = this._cart.value;
        newCart.items.list.splice(index, 1);

        this.sumTotal(newCart);
    }

    public itemQuantity(item: ShoppingCartItem, type: 'sub'|'add') {
        const cart = this._cart.value;
        const itemIndex = cart.items.list.findIndex(findItem => findItem.id === item.id);
        const nItem = cart.items.list[itemIndex];

        if (nItem.quantity === 1 && type === 'sub') return;

        type === 'add'
            ? nItem.quantity++
            : nItem.quantity--;

        nItem.price_total = nItem.quantity * nItem.price;

        this.sumTotal(cart);
    }

    public clear() {
        this._cart.next({
            items: {
                list: [],
                total_price: 0,
                total_quantity: 0,
            }
        });
    }
}
