import { NgModule } from "@angular/core";
import { ParallaxStarsComponent } from "./parallax-stars.component";

@NgModule({

    declarations: [
        ParallaxStarsComponent,
    ],
    exports: [
        ParallaxStarsComponent,
    ],
})

export class ParallaxStarsModule { }
