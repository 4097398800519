import { V } from "@angular/cdk/keycodes";

/* eslint-disable */
export const faqCategories = [
    {
        id: '28924eab-97cc-465a-ba21-f232bb95843f',
        slug: 'most-asked',
        title: 'Most asked'
    },
    {
        id: '395b0d41-b9a8-4cd6-8b5c-f07855e82d62',
        slug: 'general-inquiries',
        title: 'General inquiries'
    },
    {
        id: 'b388a87f-bfbb-44d0-800c-0ddbce2a5d22',
        slug: 'licenses',
        title: 'Licenses'
    },
    {
        id: '71c34043-d89d-4aca-951d-8606c3943c43',
        slug: 'payments',
        title: 'Payments'
    },
    {
        id: 'bea49ee0-26da-46ad-97be-116cd7ab416d',
        slug: 'support',
        title: 'Support'
    }
];

export const faqs = [
    {
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question: 'What is the READYgg SDK',
        answer: `<p>The READYgg SDK enables dev teams to quickly build & release web3 games, with blockchain enabled social game economies through our Developer dashboard, Dev-To-Earn program and our $RDYX block chain feature enabled token.</p> <p>READYgg SDK is built in consideration of modularized system and offers a full suite of web3 (and web2!) API and SDK developer tools.</p><p>In addition to our web3 API and Unity SDKs READYgg also includes:</p> <ul><li>Ready-to-use player and creator dashboards</li><li>Pre-built player loyalty systems</li><li>UGC marketplace with blockchain-based property rights</li><li>Compliant token economy</li><li>Easy and effective game monetization</li><li>Platform UA channels</li></ul></p><p><a href="https://ready.gg/" target="_blank">Learn more: https://ready.gg/</a> </p>`
    },
    {
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question: 'What platforms does the SDK support?',
        answer: '<p>The Ready SDK supports mobile app creation, PC, and console, and cross-platform play.</p>'
    },
    {
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question: 'What are the requirements of using the READYgg SDKs?',
        answer: `<p>The API is platform agnostic- it supports events on mobile, desktop, console. It allows for cross-game and cross-platform play.
        </p><p>While the current SDK is built for Unity projects, it can easily be adapted for use in other popular game engines, such as Unreal. The API itself is agnostic to the game engine on the front-end.</p>
        `
    },
    {
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question: 'What solutions can I apply to my project?',
        answer: '<p>Our list of integrations include (bur are not limited to):</p><ul><li>Avatar systems<ul><li>Blockchain:<ul><li>NFT minting</li><li>Wallet Creation</li><li>Staking</li><li>Bridging</li><li>FuseBlock</li><li>$RDYX &amp; Whitelabel tokenization</li></ul></li></ul></li><li>Partner sign-in</li><li>Friends List &amp; Invite System</li><li>User-generated Content Marketplace</li><li><a href="https://readygames.gitbook.io/readygg-sdk-documentation/sdk-integration-guides/user-profile">User profile Management</a></li><li>Game progress persistence</li><li>Remote configuration</li><li>Virtual Currency</li><li>In-game Social Feed</li><li>Customization Systems</li><li>PVP Matchmaking</li><li><a href="https://readygames.gitbook.io/readygg-sdk-documentation/sdk-integration-guides/achievements">Achievements &amp; Rewards</a></li><li><a href="https://readygames.gitbook.io/readygg-sdk-documentation/sdk-integration-guides/inventory">Inventory</a></li><li>Stores<ul><li><a href="https://readygames.gitbook.io/readygg-sdk-documentation/sdk-integration-guides/virtual-items">Virtual Item Store</a></li><li><a href="https://readygames.gitbook.io/readygg-sdk-documentation/sdk-integration-guides/currency-iap">In-App Purchasing Store</a></li></ul></li></ul><p>For full details please review our Tech Doc and whitepaper here:&nbsp;</p><ul><li><a href="https://readygames.gitbook.io/readygg-sdk-documentation/environment-setup/overview">https://readygames.gitbook.io/readygg-sdk-documentation/environment-setup/overview</a>&nbsp;</li><li><a href="https://readygames.gitbook.io/readyx/">https://readygames.gitbook.io/readyx/</a></li></ul>'
    },
    {
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question: 'If I have a web2 game, how can I make it web3?',
        answer: 'You can visit <a href="https://dev.ready.gg/" target="_blank">https://dev.ready.gg/</a> and create an account. Please follow the steps from there to join the Ready ecosystem!'
    },
    {
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question: 'What is $RDYX?',
        answer: '<p>The $RDYX token allows you to build web3 environments without having to deal with the hassle of the crypto and blockchain ecosystem - we make NFT&rsquo;s as easy as 1-2-3! Fund your game Development, stake to vote and earn, mint NFT&rsquo;s for players, &amp; more!</p><p>Watch our $RDYX video here: <a href="https://www.youtube.com/watch?v=RpfDHGwAlcw" target="_blank">https://www.youtube.com/watch?v=RpfDHGwAlcw</a></p>'
    },
    {
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question: 'What would I use a whitelabeled $RDYX token for?',
        answer: '<p>$RDYX is designed to be flexible- think of it as a &laquo; Whitelabel &raquo; process, where the $RDYX token can be used to backstop the branded developer token, and unlock web3 tokenomics in-game.&nbsp;</p><p>The process is as follows</p><ul><li>All digital assets that are minted on chain are NFTs, and these NFTs must be infused with some quantity of the $RDYX token.</li><li>The developer sets the quantity of $RDYX to infuse, by acquiring a &laquo; FuseBlock &raquo; from Ready that a) contains the SmartContract framework for the NFT minting and b) a quantity of $RDYX that can then c) be infused in the digital assets that the developer decides to put on chain.</li><li>A developer can opt to mint an in-game &laquo; Token &raquo; using the FuseBlocks. This means setting the fixed supply of in-game tokens the developer wants to generate, and then backing each in-game token with some quantity of $RDYX. <strong>The net effect is to have backed the in-game token using the $RDYX token</strong>.</li></ul>'
    },
    {
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question: 'What are FuseBlocks?',
        answer: '<p>FuseBlocks are smart contracts backed by a quantity of $RDYX. For example, a &quot;$10&quot; FuseBlock contains $10 of $RDYX at that moment.</p><p>A FuseBlock is a precondition to mint game object(s) on the ecosystem. For example, a developer purchases a $100 FuseBlock and mints 100 collectible in-game rewards. These rewards are given to players who&#39;ve achieved a certain level of game mastery, by a metric set by the developer (levels cleared, XP earned, etc).</p><p>The minted game objects in the above example now have two measures of value:</p><ul><li>Extrinsic Value: The value as perceived by a player in the game context. This is the artistry of game design, that gives status and meaning to a reward consistent with the quality of the game experience (e.g. a super-rare &quot;Skin&quot; for an avatar, where a player has reached the top 1% of players in skill.).</li><li>Intrinsic Value: This is the underlying value of $RDYX behind the game object. In the example given, the 100 in-game rewards, using a $100 FuseBlock, have an intrinsic value of $1 each (at the moment of minting, given the price of $RDYX at that instant).</li></ul>'
    },
    {
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question: 'What is Dev-To-Earn?',
        answer: '<p>Get $RDYX for performing certain development tasks&nbsp;</p><p>Play-To-Earn Mechanics: Get bonus tokens for your players activity&nbsp;</p><p>Earn $RDYX by minting and selling NFTs&nbsp;Apply for grants for even more funding&nbsp;</p><p>Early adopters of the READYgg Dev Dashboard get a sign-up grant!</p>'
    },
    {
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question: 'Does READYgg provide Developers analytic tools for their projects?',
        answer: '<p>Ready provides live analytics on the performance of game economies, by leveraging the Google Firebase integration with Google Analytics.</p><p>This offers developers and creators a uniquely powerful window into the game and product economies, with the ability to track and analyze performance.</p><p>This data can be used to A/B test pricing, adjust pricing, and optimize pricing as needed.</p><p>It means blockchain-backed assets can be tracked- where they appear in the gaming ecosystem, the downstream royalties (e.g. from the sale and re-sale of an NFT where the dev has royalty rights).</p><p>The integration of analytics with Web 3 economic management is a critically important aspect needed to &ldquo;steer&rdquo; a distributed economy in a healthy direction.</p>'
    },
    {
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question: 'What exactly does a player have to achieve to be rewarded? What are the requirements for getting rewards? Are they different in each game and depend on game developers or are they general for the whole ecosystem?',
        answer: '<p>Rewards can be different in each game, so the method of achieving rewards within network titles will vary depending on the title and the game developers.</p><p>However, there are ecosystem rewards that players can achieve by participation in the player loyalty program - loyalty points are referred to as Halo. Players can earn Halo in several ways, including gaining a certain amount of Halo if they purchase an NFT - similar to a loyalty program like &ldquo;My Nintendo&rdquo; or &ldquo;PC Optimum&rdquo;-with the difference being that the point quantity is based on the $RDYX value of the NFT purchase.</p><p>Players may also log in to the dashboard and complete quests to collect Halo, quests include actions like linking to their account to a new READYgg title for the first time, playing an title they&rsquo;ve played before, logging into the dashboard, and joining the community discord. (All to the developers choosing).</p><p>Players can redeem their Halo for rewards like digital downloads of wallpapers, special skins for their profile showcase, or vouchers for future NFT airdrops. Halo accrued also contributes to a players ranking which is visible to players on READYgg.</p>'
    },
    {
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question: 'How do I create NFTs to add to my game?',
        answer: 'Using a combination of Ready’s SDK and Developer Dashboard, a dd the SDK to your game to support the NFTs. To create the NFTs for your game it’s as simple as adding your virtual items and minting them with our easy-to-use developer consoles.'
    },
    {
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question: 'How will my users purchase NFTs in the game?',
        answer: 'An NFT can be purchased like any other game item. You can have an NFT as an IAP or have the NFT purchasable with in-game currency.'
    },
    {
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question: 'Will my users have to purchase an NFT using their credit card?',
        answer: 'Users will purchase their NFTs directly in the Apple or Google store. If a game is outside of Google or Apple stores, the payment processing can be done via a credit card or through another payment processing tool.'
    },
    {
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question: 'Will my users need a crypto wallet before downloading my app and making a web3 purchase?',
        answer: 'No. Our SDK comes with a wallet creation and integration tool for a more seamless experience.'
    },
    {
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question: 'Will the integration be seamless for my users?',
        answer: 'Yes! SDKs do all the hard work under the hood so that your community only sees the smooth and polished finish. READYgg APIs & SDK make it so that your already established community can seamlessly use web3 in-game features.'
    },
    {
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question: 'Who is behind READYgg?',
        answer: '<p>Founded in 2016, READYgg has focused on building creator communities, and democratizing access to game creation for amateurs and professionals. Now Ready takes its creator philosophy to web3 by solving hard problems in the transition from web2 gaming to web3: live game operations that synchronize on-chain, accessible through a turnkey API and SDK; modular smart contract systems; on-chain player profiles, with game achievements serving as a store of &ldquo;reputational capital&rdquo; for the players; along with distributed token economics to align the interests of game developers, creators, and players. To learn more about READYgg, please visit <a href="https://ready.gg" target="_blank">https://ready.gg</a>.</p>'
    },
    {
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question: 'Who backs READYgg?',
        answer: '<p>Our partner list includes (but is not limited to)</p><ul><li>Hashed - <a href="https://www.hashed.com/" target="_blank">https://www.hashed.com/</a></li><li>Bitkraft - <a href="https://www.bitkraft.vc/" target="_blank">https://www.bitkraft.vc/</a></li><li>Tribe Capitol - <a href="https://tribecap.co/" target="_blank">https://tribecap.co/</a></li><li>IOSG Ventures - <a href="https://iosg.vc/" target="_blank">https://iosg.vc/</a></li><li>Fundamental Labs - <a href="https://www.cth.group/what_we_do/fundamental_labs/" target="_blank">https://www.cth.group/what_we_do/fundamental_labs/</a></li><li>Polygon Studios - <a href="https://polygonstudios.com/" target="_blank">https://polygonstudios.com/</a></li><li>GSR - <a href="https://www.gsr.io/" target="_blank">https://www.gsr.io/</a></li><li>Spartan - <a href="http://spartangroup.io/" target="_blank">http://spartangroup.io/</a></li><li>Mulana - <a href="https://www.mulana.vc/" target="_blank">https://www.mulana.vc/</a></li><li>Mapleblock Capital - <a href="https://mapleblock.capital/" target="_blank">https://mapleblock.capital/</a></li><li>DWeb3 Capital - <a href="https://dweb3.io/" target="_blank">https://dweb3.io/</a></li><li>Israeli Blockchain Association - <a href="https://blockchainisrael.io/" target="_blank">https://blockchainisrael.io/</a></li><li>MarketAcross - <a href="https://marketacross.com/" target="_blank">https://marketacross.com/</a></li><li>Chromia <a href="https://chromia.com/" target="_blank">https://chromia.com/</a></li><li>SNACKCLUB <a href="https://www.snackclub.gg/en" target="_blank">https://www.snackclub.gg/en</a></li><li>42 Studio <a href="https://42studio.io/" target="_blank">https://42studio.io/</a></li><li>Warburg Serres Investments <a href="https://www.animalventures.com/" target="_blank">https://www.animalventures.com/</a></li></ul>'
    },
    {
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question: 'I have some additional questions, who do I contact?',
        answer: 'For any guidance or support please email our team at contact@ready.gg. You should receive a reply within 2 business days.'
    },
];

export const guideCategories = [
  {
    id: 'guid-cat-1',
    slug: 'getting-started',
    title: 'Getting Started'
  },
  {
    id: 'unity-sdk-category-id',
    slug: 'unity-sdk',
    title: 'Unity SDK'
  },
  {
    id: 'unreal-sdk-category-id',
    slug: 'unreal-sdk',
    title: 'Unreal SDK'
  },
  {
    id: 'rest-api-category-id',
    slug: 'rest-api-documentation',
    title: 'REST API Documentation'
  }
];

export const guides = [
  {
    categoryId: 'guid-cat-1',
    question: 'What is the READYgg SDK',
    answer: `<p>The READYgg SDK enables dev teams to quickly build & release web3 games, with blockchain enabled social game economies through our Developer dashboard, Dev-To-Earn program and our $RDYX block chain feature enabled token.</p>
             <p>READYgg SDK is built in consideration of modularized system and offers a full suite of web3 (and web2!) API and SDK developer tools.</p>
             <p>In addition to our web3 API and Unity SDKs READYgg also includes:</p>
             <ul>
               <li>Ready-to-use player and creator dashboards</li>
               <li>Pre-built player loyalty systems</li>
               <li>UGC marketplace with blockchain-based property rights</li>
               <li>Compliant token economy</li>
               <li>Easy and effective game monetization</li>
               <li>Platform UA channels</li>
             </ul>
             <p><a href="https://ready.gg/" target="_blank">Learn more: https://ready.gg/</a> </p>`
  },
  {
    categoryId: 'guid-cat-1',
    question: 'What platforms does the SDK support?',
    answer: '<p>The READYgg SDK supports mobile app creation, PC, and console gaming, as well as cross-platform play.</p>'
  },
  {
    categoryId: 'guid-cat-1',
    question: 'What are the requirements of using the READYgg SDKs?',
    answer: `<p>The API is platform agnostic- it supports events on mobile, desktop, and console platforms. It also allows for cross-game and cross-platform play.</p>
             <p>While the current SDK is built for Unity projects, it can easily be adapted for use in other popular game engines, such as Unreal. The API itself is agnostic to the game engine on the front-end.</p>`
  },
  {
    categoryId: 'guid-cat-1',
    question: 'Game design guidelines',
    answer: `<p>READYgg provides comprehensive game design guidelines to help you build your game. These guidelines cover the following topics:</p>
              <ul>
                <li>User Authentication</li>
                <li>User Profile</li>
                <li>Game (Progression + Rewards)</li>
                <li>Virtual Items</li>
                <li>Currency (IAP)</li>
              </ul>
              <p><a href="https://readygames.gitbook.io/readygg-sdk-documentation/game-design-guides/overview" target="_blank">Learn more</a></p>`
  },
  {
    categoryId: 'unity-sdk-category-id',
    question: 'Getting Started with Unity SDK',
    answer: `<p>Learn how to get started with the READYgg Unity SDK. This guide provides an overview of the setup process, initial configuration, and basic usage. Ideal for developers new to the READYgg Unity SDK.</p>
             <p><a href="https://readygames.gitbook.io/readygg-sdk-documentation/environment-setup/getting-started" target="_blank">Start here</a></p>`
  },
  {
    categoryId: 'unity-sdk-category-id',
    question: 'Integrating Blockchain Features in Unity',
    answer: `<p>Explore how to integrate blockchain features using the READYgg Unity SDK. This guide covers the integration of blockchain technology into your Unity projects, including token utilization and blockchain-based transactions.</p>
             <p><a href="https://readygames.gitbook.io/readygg-sdk-documentation/sdk-integration-guides/wallets" target="_blank">Learn more</a></p>`
  },
  {
    categoryId: 'unity-sdk-category-id',
    question: 'Advanced Features of READYgg Unity SDK',
    answer: `<p>Dive into the advanced features of the READYgg Unity SDK. Understand how to leverage the full potential of the SDK in your Unity projects, including achievements, leaderboards and matchmaking.</p>
             <p><a href="https://readygames.gitbook.io/readygg-sdk-documentation/sdk-integration-guides/achievements" target="_blank">Discover advanced features</a></p>`
  },
  {
    categoryId: 'unreal-sdk-category-id',
    question: 'Configuring the Unreal Plugin',
    answer: `<p>Learn how to configure the READYgg Unreal Plugin. This guide covers the initial steps to check out the repository, compile the project, and configure settings in the Unreal Editor. Key steps include pasting the Project ID and selecting the environment (Staging or Production).</p>
             <p><a href="https://github.com/readyio/rgn_unreal#configuring-the-unreal-plugin" target="_blank">Configuration Guide</a></p>`
  },
  {
    categoryId: 'unreal-sdk-category-id',
    question: 'Opening the Project in Unreal',
    answer: `<p>Instructions on how to open and start working with the Unreal project. For Windows users, this includes generating Visual Studio project files and opening the solution for code review and debugging. Also provides guidance on opening the .uproject file in the Unreal Editor.</p>
             <p><a href="https://github.com/readyio/rgn_unreal#opening-the-project" target="_blank">Project Opening Guide</a></p>`
  },
  {
    categoryId: 'unreal-sdk-category-id',
    question: 'Understanding Important Paths in the Unreal SDK',
    answer: `<p>A guide to important file paths within the READYgg Unreal SDK. This includes the location of the Plugin Source (Plugins/ReadyGamesNetwork) and the READYgg Settings file (Config/DefaultGame.ini), which are crucial for effective plugin management and configuration.</p>
             <p><a href="https://github.com/readyio/rgn_unreal#important-paths" target="_blank">Important Paths Guide</a></p>`
  },
  {
    categoryId: 'rest-api-category-id',
    question: 'User Authentication in READYgg API',
    answer: `<p>To authorize a user you need to use the READYgg Web Login Form. This form is a simple HTML form that you can embed in your game. It will allow users to log in to READYgg using their email and password.</p>
             <p>Provide the redirect URL to the form as a parameter in the URL. After a successful login, the user will be redirected to the provided URL with the user token as a parameter.</p>
             <p>The web login form enables the user to create a crypto wallet and link it to their READYgg account.</p>
             <p>Here are the links to the login forms for each environment:</p>
             <ul>
               <li><a href="https://development-oauth.ready.gg/?url_redirect=" target="_blank">Development Login</a></li>
               <li><a href="https://staging-oauth.ready.gg/?url_redirect=" target="_blank">Staging Login</a></li>
               <li><a href="https://oauth.ready.gg/?url_redirect=" target="_blank">Production Login</a></li>
             </ul>`
  },
  {
    categoryId: 'rest-api-category-id',
    question: 'REST API Documentation Resources',
    answer: `<p>Access comprehensive REST API documentation automatically generated from our OpenAPI spec. Explore various documentation formats for convenience and clarity.</p>
             <ul>
               <li><a href="https://documenter.getpostman.com/view/24590396/2s93JushLS" target="_blank">Postman Collection</a></li>
               <li><a href="https://ready-games-network.readme.io/reference" target="_blank">Readme Documentation</a></li>
               <li><a href="https://rgn-docs-pxvfitzi2q-uc.a.run.app/" target="_blank">Swagger Documentation</a></li>
               <li><a href="https://rgn-docs-gb7.web.app/" target="_blank">ReDoc Documentation</a></li>
             </ul>`
  }
];