<!-- Button -->
<button
    mat-icon-button
    [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img
            class="w-7 h-7 rounded-full"
            *ngIf="showAvatar && user?.avatar"
            [src]="user.avatar">
        <mat-icon
            *ngIf="!showAvatar || !user?.avatar"
            [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span
            class="absolute right-0 bottom-0 w-2 h-2 rounded-full"
            [ngClass]="{'mr-px mb-px': !showAvatar || !user?.avatar,
                          'bg-green': user?.status === 'online',
                          'bg-yellow': user?.status === 'away',
                          'bg-primary': user?.status === 'busy',
                          'bg-gray': user?.status === 'not-visible'}"
        ></span>
    </span>
</button>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>Signed in as</span>
            <span class="mt-1.5 text-md font-medium">{{user?.email}}</span>
        </span>
    </button>
    <!--
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Profile</span>
    </button>
    -->
    <!--
    <button mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
        <span>Settings</span>
    </button>

    <button
        mat-menu-item
        [matMenuTriggerFor]="userStatus">
        <mat-icon [svgIcon]="'heroicons_outline:dots-circle-horizontal'"></mat-icon>
        <span>Status</span>
    </button>
    -->
    <mat-divider class="my-2"></mat-divider>
    <button
        mat-menu-item
        routerLink="/auth/sign-out"
    >
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>Sign out</span>
    </button>
</mat-menu>
<!--
<mat-menu
    class="user-status-menu"
    #userStatus="matMenu">
    <button
        mat-menu-item
        (click)="updateUserStatus('online')">
        <span class="w-4 h-4 mr-3 rounded-full bg-green"></span>
        <span>Online</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('away')">
        <span class="w-4 h-4 mr-3 rounded-full bg-yellow"></span>
        <span>Away</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('busy')">
        <span class="w-4 h-4 mr-3 rounded-full bg-primary"></span>
        <span>Busy</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('not-visible')">
        <span class="w-4 h-4 mr-3 rounded-full bg-gray"></span>
        <span>Invisible</span>
    </button>
</mat-menu>
-->
