import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FuseNavigationModule } from '@fuse/components/navigation';
import { FuseLoadingBarModule } from '@fuse/components/loading-bar';
import { FuseFullscreenModule } from '@fuse/components/fullscreen/fullscreen.module';
import { LanguagesModule } from 'app/layout/common/languages/languages.module';
import { MessagesModule } from 'app/layout/common/messages/messages.module';
import { NotificationsModule } from 'app/layout/common/notifications/notifications.module';
import { QuickChatModule } from 'app/layout/common/quick-chat/quick-chat.module';
import { SearchModule } from 'app/layout/common/search/search.module';
import { ShortcutsModule } from 'app/layout/common/shortcuts/shortcuts.module';
import { UserModule } from 'app/layout/common/user/user.module';
import { SharedModule } from 'app/shared/shared.module';
import { ClassyLayoutComponent } from 'app/layout/layouts/vertical/classy/classy.component';
import { WalletModule } from '../../../../modules/admin/components/wallet/wallet.module';
import { WalletConnectModule } from 'app/layout/common/wallet/wallet.module';
import { GrantButtonModule } from 'app/layout/common/grant-button/grant-button.module';
import { EnvSwitcherModule } from 'app/layout/common/env-switcher/env-switcher.module';
import { AsideDocsModule } from 'app/layout/common/docs/docs.module';
import { ShoppingCartHeaderModule } from 'app/layout/common/shopping-cart/shopping-cart.module';
import { PromoCodeModule } from 'app/modules/admin/components/promo-code/promo-code.module';
import { ProjectSwitcherModule } from 'app/modules/admin/components/project-switcher/project-switcher.module';
import { ToolbarModule } from 'app/layout/common/toolbar/toolbar.module';
import { ParallaxStarsModule } from 'app/modules/auth/parallax-stars/parallax-stars.module';

@NgModule({
	declarations: [ClassyLayoutComponent],
	imports: [
		HttpClientModule,
		RouterModule,
		MatButtonModule,
		MatDividerModule,
		MatIconModule,
		MatMenuModule,
		FuseFullscreenModule,
		FuseLoadingBarModule,
		FuseNavigationModule,
		LanguagesModule,
		MessagesModule,
		NotificationsModule,
		QuickChatModule,
		SearchModule,
		ShortcutsModule,
		UserModule,
		SharedModule,
		WalletModule,
		WalletConnectModule,
		AsideDocsModule,
		ShoppingCartHeaderModule,
		PromoCodeModule,
		GrantButtonModule,
		EnvSwitcherModule,
		ProjectSwitcherModule,
		ToolbarModule,
		ParallaxStarsModule,
	],
	exports: [ClassyLayoutComponent],
})
export class ClassyLayoutModule {}
