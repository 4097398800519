import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

import { StripeService } from 'ngx-stripe';
import { NGXLogger } from 'ngx-logger';

import { PaymentData, UserPaymentInfo } from '../defs/shop';

import { BasePaymentProvider } from '../payment-providers/base.provider';
import { StripeProvider } from '../payment-providers/stripe.provider';
import { AuraProvider } from '../payment-providers/aura.provider';
import { AliPayProvider } from '../payment-providers/alipay.provider';
import { WeChatPayProvider } from '../payment-providers/wechat-pay.provider';

export enum PaymentProviders {
	Aura = 'aura',
	Stripe = 'stripe',
	WeChatPay = 'wechat-pay',
	AliPay = 'alipay',
	XSolla = 'xsolla',
}

export interface ActivePaymentProvider {
	id: PaymentProviders;
	provider: BasePaymentProvider;
}

interface PaymentProviderBase {
	name: string;
	icon: string;
}

interface PaymentProviderInfo extends PaymentProviderBase {
	instance: BasePaymentProvider;
}

export interface PaymentProviderListItem extends PaymentProviderBase {
	key: string;
}

const LS_KEY_PAYMENT_INFO = 'paymentInfo';

@Injectable({
	providedIn: 'root',
})
export class PaymentService {
	private availableProviders: Record<string, PaymentProviderInfo> = {};
	private providerSubj = new BehaviorSubject<PaymentProviders | null>(null);

	get provider$() {
		return this.providerSubj.asObservable();
	}

	protected get provider() {
		return this.availableProviders[this.providerSubj.value].instance;
	}

	constructor(
		private http: HttpClient,
		private logger: NGXLogger,
		private ngxStripe: StripeService,
		private sanitizer: DomSanitizer,
	) {
		console.log('PaymentService constructor()');

		this.availableProviders = {
			[PaymentProviders.Aura]: {
				name: '$PLAY',
				icon: 'aura_icon',
				instance: new AuraProvider(
					this.http,
					this.logger,
				) as BasePaymentProvider,
			},
			[PaymentProviders.Stripe]: {
				name: 'Stripe',
				icon: 'payment-credit-card',
				instance: new StripeProvider(
					this.http,
					this.logger,
					this.ngxStripe,
				) as BasePaymentProvider,
			},
			[PaymentProviders.WeChatPay]: {
				name: 'WeChat Pay',
				icon: 'payment-wechat',
				instance: new WeChatPayProvider(
					this.http,
					this.logger,
				) as BasePaymentProvider,
			},
			[PaymentProviders.AliPay]: {
				name: 'Alipay',
				icon: 'payment-alipay',
				instance: new AliPayProvider(
					this.http,
					this.logger,
				) as BasePaymentProvider,
			},

			/*
            [PaymentProviders.XSolla]: {
                name: 'XSolla',
                icon: 'payment-xsolla',
                instance: (new XsollaProvider(
                    this.http,
                    this.logger,
                    this.sanitizer,
                )) as BasePaymentProvider
            },
            */
		};
	}

	getProvidersList(): PaymentProviderListItem[] {
		return Object.keys(this.availableProviders).map((key) => ({
			key,
			icon: this.availableProviders[key].icon,
			name: this.availableProviders[key].name,
		}));
	}

	protected getProviderKey() {
		const prov = this.providerSubj.value;

		if (!prov) {
			throw new Error('Payment provider not selected');
		}

		return prov;
	}

	changeProvider(provider: PaymentProviders) {
		if (this.providerSubj.value !== provider) {
			this.providerSubj.next(provider);
		}
	}

	getProviderProp(prop: string) {
		return this.provider.getProp(prop);
	}

	prepare(params: PaymentData) {
		return this.provider.prepare(params);
	}

	reset(allProviders = false, unselectProvider = false) {
		try {
			if (allProviders) {
				Object.values(this.availableProviders).forEach((prov) =>
					prov.instance.reset(),
				);
			} else {
				this.provider.reset();
			}

			if (unselectProvider) {
				this.providerSubj.next(null);
			}
		} catch (err) {}
	}

	pay(formData: Record<string, unknown>, params: Record<string, unknown>) {
		return this.provider.pay(formData, params);
	}

	onUiMessage(event: MessageEvent) {
		if (this.providerSubj.value) {
			this.provider.onUiMessage(event);
		}
	}

	async savePaymentInfo(data: UserPaymentInfo) {
		let encoded = JSON.stringify(data);
		localStorage.setItem(LS_KEY_PAYMENT_INFO, encoded);
	}

	async clearPaymentInfo() {
		localStorage.removeItem(LS_KEY_PAYMENT_INFO);
		return;
	}

	async loadPaymentInfo(): Promise<UserPaymentInfo | null> {
		let data = null;

		try {
			data = localStorage.getItem(LS_KEY_PAYMENT_INFO);

			if (data) {
				data = JSON.parse(data) as UserPaymentInfo;
			}
		} catch (err: unknown) {}

		return data;
	}
}
