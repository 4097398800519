// Angular
import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from "@angular/fire/auth";
import { ActivatedRoute, Router } from "@angular/router";

// Rxjs
import { catchError, Observable, of, Subject, switchMap } from 'rxjs';

// Fuse
import { FuseSplashScreenService } from '@fuse/services/splash-screen';

// Service
import { UserService } from 'app/core/user/user.service';
import { WalletService } from 'app/modules/admin/components/wallet/wallet.service';

// Environment
import { environment } from "../../../environments/environment";

// Firebase
import firebase from "firebase";
import auth = firebase.auth;

// Interface
import { AuthGuardCheck } from './auth.interface';

@Injectable()
export class AuthService {
    private readonly userData$: Subject<firebase.firestore.DocumentData>;
    private _authenticated: boolean = false;

    public readonly readymasterApp?: firebase.app.App;
    public readonly readymasterAppFireStore: firebase.firestore.Firestore | undefined;
    public readonly readymasterAppStorage: firebase.storage.Storage | undefined;
    public readonly vfiSandboxApp?: firebase.app.App;
    public realMasterData?: any;
    public userData: any;
    public userMasterData: any;
    public userMasterToken: any;

    constructor(
        private readonly _afAuth: AngularFireAuth,
        private readonly _httpClient: HttpClient,
        private readonly _userService: UserService,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _router: Router,
        private readonly _fuseSplashScreenService: FuseSplashScreenService,
        private readonly _injector: Injector,
    ) {
        this.userData$ = new Subject();

        this.readymasterApp = firebase.initializeApp(environment.firebaseMaster, "readymasterFirebase");
        this.readymasterAppFireStore = this.readymasterApp.firestore();
        this.readymasterAppStorage = this.readymasterApp.storage();

        this._afAuth.authState.subscribe(async (user: firebase.User) => {
            /**
             * This is being done to control what login method was used
             * previously, for now we disabled google and facebook sign in
             * providers, so it must abort and sign out any of these cases.
             */
            if (user) {
                let abort = false;
                user.providerData.forEach(item => {
                    if (item.providerId.includes('google' || 'facebook')) {
                        abort = true;
                        return this.signOut();
                    }
                });

                if (abort) return;
            }

            if (user) {
                this.userData = user;

                const token = await user.getIdToken();
                const stringtoparse = JSON.stringify({ 'idToken': token, "appPackageName": 'io.getready.rgn-web' })
                const customToken = await this.readymasterApp.functions().httpsCallable('user-createCustomToken')(stringtoparse)

                this.readymasterApp.auth().signInWithCustomToken(customToken.data).then((userCredential) => {
                    this.userMasterData = userCredential.user;

                    this.signInProcess();
                });
            } else {
                this.userData = null;
                this.userMasterData = null;
                this._fuseSplashScreenService.hide();
            }
        })
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    public getUserData(): Observable<firebase.firestore.DocumentData> {
        return this.userData$.asObservable();
    }

    public async signInGoogle(): Promise<auth.UserCredential> {
        const provider = new firebase.auth.GoogleAuthProvider();
        return this.oAuthLogin(provider);
    }

    public async signInFacebook(): Promise<auth.UserCredential> {
        const provider = new firebase.auth.FacebookAuthProvider();
        return this.oAuthLogin(provider);
    }

    public async signInApple(): Promise<auth.UserCredential> {
        var provider = new firebase.auth.OAuthProvider('apple.com');
        return this.oAuthLogin(provider);
    }

    public async signInEmail(email: string, password: string, remember: boolean): Promise<auth.UserCredential> {
        /*
         * Currently not working. Only works if the app used is the one
         * initiliazed on the app.module.
         */

        // const persistence = remember
        //     ? firebase.auth.Auth.Persistence.LOCAL
        //     : firebase.auth.Auth.Persistence.NONE;

        // await this.readymasterApp.auth().setPersistence(persistence);

        return this.readymasterApp.auth().signInWithEmailAndPassword(email, password);
    }

    public async signUpEmail(email: string, password: string): Promise<auth.UserCredential> {
        return this.readymasterApp.auth().createUserWithEmailAndPassword(email, password)
    }

    public async forgotPasswordViaEmail(email: string): Promise<any> {
        return this.readymasterApp.auth().sendPasswordResetEmail(email);
    }

    public async resetUserPassword(password: string, code: string): Promise<any> {
        return this.readymasterApp.auth().confirmPasswordReset(code, password);
    }

    public signInProcess(): void {
        const userRef = this.readymasterAppFireStore.collection("users").doc(this.userMasterData?.uid)

        userRef.get().then((docSnapshot) => {
            const userRefData = docSnapshot.data();
            console.log('userRefData: ', userRefData);

            /**
             * Since we don't want user collection reference without email
             * verified, we only update the data if email is already verified.
             */
            if (this.userData && this.userData.emailVerified) {
                const createdAt = userRefData?.createdAt ??firebase.firestore.FieldValue.serverTimestamp();
                const developerDashboardCreatedAt = userRefData?.developerDashboardCreatedAt ?? firebase.firestore.FieldValue.serverTimestamp();

                const data = {
                    rgnWebUserId: this.userData.uid,
                    userId: this.userMasterData?.uid,
                    email: this.userData.email,
                    displayName: this.userData.displayName,
                    emailVerified: this.userData.emailVerified,
                    createdAt: createdAt,
                    lastLoginAt: firebase.firestore.FieldValue.serverTimestamp(),
                    developerDashboardCreatedAt: developerDashboardCreatedAt,
                };

                userRef.set(data, { merge: true }); // no need for await
            }

            this.userData$.next(userRefData);
            this.realMasterData = userRefData;

            // if ((environment as any).devForceIsApprovedDeveloper) { // dev stuff
            //     this.realMasterData.isApprovedDeveloper = true;
            //     this.realMasterData.emailVerified = true;
            // }
            this._authenticated = true;

            const redirectURL = this._activatedRoute.snapshot.queryParamMap.get('redirectURL') || '/signed-in-redirect';

            this.userMasterData.getIdToken().then((token: string) => {
                this.userMasterToken = token;

                this.processDeveloper(); // no need for await

                this._router.navigateByUrl(redirectURL);
                this._fuseSplashScreenService.hide();
            });
        });
    }

    public async signUpEmailProcess(userCredential: auth.UserCredential, name: string): Promise<void> {
        this.userMasterData = userCredential.user;

        if (!userCredential.user.displayName) {
            await userCredential.user.updateProfile({
                displayName: name,
            });
        }

        if (!userCredential.user.emailVerified) {
            await userCredential.user.sendEmailVerification()
                .then(() => {
                    this._fuseSplashScreenService.hide();
                    return this._router.navigateByUrl('/auth/confirmation-required');
                });
        } else {
            this._router.navigateByUrl('/signed-in-redirect');
        }
    }

    public signOut(): Observable<any> {
        localStorage.removeItem('accessToken');

        const wallet = this._injector.get(WalletService);
        wallet.clearWallet();

        this._authenticated = false;
        this._afAuth.signOut();

        this._router.navigate(['/auth/sign-out']);

        return of(true);
    }

    public check(): AuthGuardCheck {
        if (this._authenticated) {
            if (!this.userData.emailVerified) {
                return {
                    approved: false,
                    type: 'emailVerified',
                }
            }

            // if (!this.realMasterData.hasOwnProperty('isApprovedDeveloper') || !this.realMasterData.isApprovedDeveloper) {
            //     return {
            //         approved: true,
            //         type: 'approvedDeveloper',
            //     }
            // }

            return {
                approved: true,
                type: 'authenticated',
            }
        }

        return {
            approved: false,
            type: 'authenticated',
        }

        // Will these be used?

        // // Check the access token availability
        // if (!this.accessToken) {
        //     return of(false);
        // }

        // // Check the access token expire date
        // if (AuthUtils.isTokenExpired(this.accessToken)) {
        //     return of(false);
        // }

        // // If the access token exists and it didn't expire, sign in using it
        // return this.signInUsingToken();
    }

    public async confirmGdpr(): Promise<void> {
        const userRef = this.readymasterAppFireStore.collection("users").doc(this.userMasterData?.uid);
        const data = {
            hasApprovedGDPR: true,
        };

        return userRef.set(data, { merge: true });
    }

    private async processDeveloper(): Promise<void> {
        const processNewDeveloper = this.readymasterApp
            .functions()
            .httpsCallable('ProcessNewDeveloperUser');

        return processNewDeveloper({ token: this.userMasterToken },)
            .then((result) => {
                console.log('result processNewDeveloper');
                console.log(result);
            });
    }

    private oAuthLogin(provider: auth.AuthProvider): Promise<auth.UserCredential> {
        return this._afAuth.signInWithPopup(provider);
    }

    /**
     * @deprecated
     */
    public unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * @deprecated
     */
    private signInUsingToken(): Observable<any> {
        // Renew token
        return this._httpClient.post('api/auth/refresh-access-token', {
            accessToken: this.accessToken
        }).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.accessToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return true
                return of(true);
            })
        );
    }
}
