<!-- Notifications toggle -->
<button mat-icon-button (click)="openPanel()" #notificationsOrigin>
	<ng-container *ngIf="unreadCount > 0">
		<span
			class="absolute bottom-3 right-0 left-0 flex items-center justify-center h-3"
		>
			<span
				class="flex items-center justify-center flex-shrink-0 min-w-4 h-4 px-1 ml-4 mt-5 rounded-full text-indigo-50 text-md font-medium text-violet font-effraHeavy"
			>
				{{ unreadCount }}
			</span>
		</span>
	</ng-container>

	<app-custom-icon icon="notifications"></app-custom-icon>
</button>

<!-- Notifications panel -->
<ng-template #notificationsPanel>
	<div
		class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg"
	>
		<!-- Header -->
		<div
			class="flex flex-shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary"
		>
			<div class="sm:hidden -ml-1 mr-3">
				<button mat-icon-button (click)="closePanel()">
					<mat-icon
						class="icon-size-5 text-current"
						[svgIcon]="'heroicons_solid:x'"
					></mat-icon>
				</button>
			</div>
			<div class="text-lg font-medium leading-10">Notifications</div>
			<div class="ml-auto">
				<button
					mat-icon-button
					[matTooltip]="'Mark all as read'"
					[disabled]="unreadCount === 0"
					(click)="markAllAsRead()"
				>
					<mat-icon
						class="icon-size-5 text-current"
						[svgIcon]="'heroicons_solid:mail-open'"
					></mat-icon>
				</button>
			</div>
		</div>

		<!-- Content -->
		<div
			class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card"
		>
			<!-- Notifications -->
			<ng-container
				*ngFor="let notification of notifications; trackBy: trackByFn"
			>
				<div
					class="flex group hover:bg-gray dark:hover:bg-dark dark:hover:bg-opacity-5"
					[ngClass]="{ unread: !notification.read }"
				>
					<!-- Notification with a link -->
					<ng-container *ngIf="notification.link">
						<!-- Normal links -->
						<ng-container *ngIf="!notification.useRouter">
							<a
								class="flex flex-auto py-5 pl-6 cursor-pointer"
								[href]="notification.link"
							>
								<ng-container
									*ngTemplateOutlet="notificationContent"
								></ng-container>
							</a>
						</ng-container>
						<!-- Router links -->
						<ng-container *ngIf="notification.useRouter">
							<a
								class="flex flex-auto py-5 pl-6 cursor-pointer"
								[routerLink]="notification.link"
							>
								<ng-container
									*ngTemplateOutlet="notificationContent"
								></ng-container>
							</a>
						</ng-container>
					</ng-container>

					<!-- Notification without a link -->
					<ng-container *ngIf="!notification.link">
						<div class="flex flex-auto py-5 pl-6">
							<ng-container
								*ngTemplateOutlet="notificationContent"
							></ng-container>
						</div>
					</ng-container>

					<!-- Actions -->
					<div class="relative flex flex-col my-5 mr-6 ml-2">
						<!-- Indicator -->
						<button
							class="w-6 h-6 min-h-6"
							mat-icon-button
							(click)="toggleRead(notification)"
							[matTooltip]="
								notification.read ? 'Mark as unread' : 'Mark as read'
							"
						>
							<span
								class="w-2 h-2 rounded-full"
								[ngClass]="{
									'bg-gray dark:bg-dark sm:opacity-0 sm:group-hover:opacity-100':
										notification.read,
									'bg-primary': !notification.read
								}"
							></span>
						</button>
						<!-- Remove -->
						<button
							class="w-6 h-6 min-h-6 sm:opacity-0 sm:group-hover:opacity-100"
							mat-icon-button
							(click)="delete(notification)"
							[matTooltip]="'Remove'"
						>
							<mat-icon
								class="icon-size-4"
								[svgIcon]="'heroicons_solid:x'"
							></mat-icon>
						</button>
					</div>
				</div>

				<!-- Notification content template -->
				<ng-template #notificationContent>
					<!-- Icon -->
					<ng-container *ngIf="notification.icon && !notification.image">
						<div
							class="flex flex-shrink-0 items-center justify-center w-8 h-8 mr-4 rounded-full bg-gray dark:bg-dark"
						>
							<mat-icon class="icon-size-5" [svgIcon]="notification.icon">
							</mat-icon>
						</div>
					</ng-container>
					<!-- Image -->
					<ng-container *ngIf="notification.image">
						<img
							class="flex-shrink-0 w-8 h-8 mr-4 rounded-full overflow-hidden object-cover object-center"
							[src]="notification.image"
							[alt]="'Notification image'"
						/>
					</ng-container>
					<!-- Title, description & time -->
					<div class="flex flex-col flex-auto">
						<ng-container *ngIf="notification.title">
							<div
								class="font-semibold line-clamp-1"
								[innerHTML]="notification.title"
							></div>
						</ng-container>
						<ng-container *ngIf="notification.description">
							<div
								class="line-clamp-2"
								[innerHTML]="notification.description"
							></div>
						</ng-container>
						<div class="mt-2 text-sm leading-none text-green">
							{{ notification.time | date: 'MMM dd, h:mm a' }}
						</div>
					</div>
				</ng-template>
			</ng-container>

			<!-- No notifications -->
			<ng-container *ngIf="!notifications || !notifications.length">
				<div
					class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8"
				>
					<div
						class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100"
					>
						<mat-icon
							class="text-primary-500-700"
							[svgIcon]="'heroicons_outline:bell'"
						></mat-icon>
					</div>
					<div class="mt-5 text-2xl font-semibold tracking-tight">
						No notifications
					</div>
					<div class="w-full max-w-60 mt-1 text-md text-center text-green">
						When you have notifications, they will appear here.
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</ng-template>
