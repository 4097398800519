<div
	class="project-switcher h-10 rounded-full ml-1 border-2 border-bluishGray hover:border-gray"
>
	<mat-select
		class="rounded-full w-full max-w-40 h-full px-4 flex items-center justify-center gap-1 bg-transparent text-white font-poppins"
		[(ngModel)]="selectedProject"
		[compareWith]="compareObjects"
		(selectionChange)="onProjectChange($event.value)"
	>
		<mat-option *ngFor="let project of projects" [value]="project">{{
			project.name
		}}</mat-option>
	</mat-select>
</div>
