import { environment } from "environments/environment";

export abstract class UrlService {
    protected getUrlGen1(fName: string): string {
        const base = environment.url.gen1.base;

        return `${base}${fName}`;
    }

    protected getUrlGen2(fName: string): string {
        const prefix = environment.url.gen2.prefix;
        const sufix = environment.url.gen2.sufix;
        const project = environment.url.gen2.project;

        return `${prefix}${fName}-${project}-${sufix}`;
    }
}
