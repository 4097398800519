// Angular
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

// Module
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";

// Rxjs
import { BehaviorSubject, Observable } from "rxjs";

// Service
import { AuthService } from "app/core/auth/auth.service";
import { UrlService } from "app/url.service";

// Component
import { PromoCodeClaimComponent } from "./dialog/claim.component";

// Config
import { snackWarning } from "app/core/config/snackbar.config";

// Interface
import { PromoResponse } from "./promo-code.interface";

@Injectable()
export class PromoCodeService extends UrlService {
    private readonly promoCode$: BehaviorSubject<string> = new BehaviorSubject(null);
    private readonly promoObject$ = new BehaviorSubject<PromoResponse>(null);

    constructor(
        private readonly _http: HttpClient,
        private readonly _authService: AuthService,
        private readonly _snackBar: MatSnackBar,
        private readonly _dialog: MatDialog,
    ) {
        super();
    }

    public setPromoCode(promoCode: string) {
        this.promoCode$.next(promoCode);
    }

    public getPromoCode(): Observable<string> {
        return this.promoCode$;
    }

    public getPromoCodeValue(): string {
        return this.promoCode$.value;
    }

    public getPromoObject(): Observable<PromoResponse> {
        return this.promoObject$;
    }

    public openDialog() {
        const dialogRef = this._dialog.open(PromoCodeClaimComponent, {
            data: null,
            maxWidth: '600px',
            panelClass: '',
            width: '90%',
        });

        return dialogRef.afterClosed();
    }

    public addUserToPromoCode() {
        const code = this.getPromoCodeValue();
        if (!code) {
            return this._snackBar.open('There is no code to process.', 'Ok', snackWarning);
        }

        const url = this.getUrlGen1('promoCodes-addUserToPromoCode');

        let headers = new HttpHeaders();
        headers = headers.append('Authorization', `Bearer ${this._authService.userMasterToken}`);

        return this._http.post(url, {
            promoCodeId: code,
        }, { headers }).subscribe({
            next: (response: any) => {
                console.log('Response on code: ', response)

                const result: PromoResponse = {
                    type: 'success',
                    message: response.message,
                }

                this.promoObject$.next(result);
            },
            error: (error: any) => {
                console.log('Failed on code: ', error);

                const result: PromoResponse = {
                    type: 'error',
                    message: error.error.message ?? 'Invalid code or the code doesn\'t exists',
                }

                this.promoObject$.next(result);
            }
        });
    }
}
