// Angular
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

// Angular Material
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSnackBarModule } from "@angular/material/snack-bar";

// Service
import { PromoCodeService } from "./promo-code.service";

// Components
import { PromoCodeComponent } from "./promo-code.component";
import { PromoCodeClaimComponent } from "./dialog/claim.component";
import { FuseAlertModule } from "@fuse/components/alert";

@NgModule({
    imports: [
        // Angular
        CommonModule,
        RouterModule,
        ReactiveFormsModule,

        // Angular Material
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,

        // Fuse
        FuseAlertModule,
    ],
    declarations: [
        PromoCodeComponent,
        PromoCodeClaimComponent,
    ],
    exports: [
        PromoCodeComponent,
    ],
    providers: [
        PromoCodeService,
    ],
})

export class PromoCodeModule { }
