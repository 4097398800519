import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

interface Type {
    title?: string;
    subtitle: string;
}

@Component({
    templateUrl: 'soon.component.html',
})
export class DialogFeatureComingSoon implements OnInit {
    public title = 'Coming Soon!';
    public subtitles = ['Feature not yet available, come back soon.'];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Type,
        private readonly _dialogRef: MatDialogRef<DialogFeatureComingSoon>,
    ) { }

    ngOnInit(): void {
        this.title = this.data.title ?? this.title;

        if (Array.isArray(this.data.subtitle)) {
            this.subtitles = this.data.subtitle;
        }

        if (typeof(this.data.subtitle) === 'string'){
            this.subtitles = [this.data.subtitle];
        }
    }

    public onClose(): void {
        this._dialogRef.close();
    }
}
