import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { playMenu } from 'app/mock-api/apps/play/data';

@Injectable({
	providedIn: 'root',
})
export class PlayMenuMockApi {
	/**
	 * Constructor
	 */
	constructor(private _fuseMockApiService: FuseMockApiService) {
		// Register Mock API handlers
		this.registerHandlers();
	}

	registerHandlers(): void {
		this._fuseMockApiService.onGet('api/apps/play/play-menu').reply(() => {
			return [200, playMenu];
		});
	}
}
