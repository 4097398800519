<section class="mt-5 px-4 text-white">
    <a
        class="
            w-full h-10 flex items-center justify-center px-2 text-center bg-gradient-secondary text-white rounded-md
            transition-all cursor-pointer
            hover:shadow-2xl
        "
        [routerLink]="getLink('milestone')"
        routerLinkActive="active"
    >
        <span>New</span>
        Milestones
    </a>
</section>
