import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
    templateUrl: 'gdpr.component.html',
    styleUrls: ['gdpr.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class GdprBottomSheet {
    public onProcess: boolean;

    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        private readonly _bottomSheetRef: MatBottomSheetRef<GdprBottomSheet>,
        private readonly _authService: AuthService,
    ) { }

    onConfirm(): void {
        this.onProcess = true;

        // Do nothing on then()
        this._authService.confirmGdpr().then().catch(error => {
            console.log('Error on confirm GDPR: ', error)
            this.onProcess = false;
        }).finally(() => {
            this._bottomSheetRef.dismiss();
        });
    }

    onClose(): void {
        this._bottomSheetRef.dismiss();
    }
}
