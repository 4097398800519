import { DOCUMENT } from '@angular/common';
import {
	Component,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { IAuthNewUserData } from 'app/core/auth/interface/auth.new.interface';
import { DeviceService } from 'app/core/services/device.service';

@Component({
	selector: 'app-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
	@Output() toggleSidebar = new EventEmitter<void>();
	@Input() isSidebarOpen: boolean = false;
	@Input() user: IAuthNewUserData | null = null;
	showLogo: boolean = false;
	elem: any;
	isFullscreen: boolean = false;
	isMobile: boolean;

	constructor(_device: DeviceService, @Inject(DOCUMENT) _document: Document) {
		this.elem = _document.documentElement;
		this.isMobile = _device.isMobile();
	}

	setShowLogo(isOpen: boolean = null) {
		this.showLogo = this.isMobile || (isOpen ?? !this.isSidebarOpen);
	}

	ngOnInit(): void {
		this.setShowLogo();
	}

	onToggleSidebar(): void {
		this.setShowLogo(!this.showLogo);
		this.toggleSidebar.emit();
	}
}
