import { HttpClient } from '@angular/common/http';
import { StripeService } from 'ngx-stripe';
import { map, of, Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import {
    PaymentIntent,
    StripeElements,
} from '@stripe/stripe-js';

import { PaymentData } from '../defs/shop';
import { BasePaymentProvider, PaymentResult, PaymentStatus } from './base.provider';
import { environment } from 'environments/environment';

export const STRIPE_PROP_CLIENT_SECRET = 'clientSecret';
export const STRIPE_PROP_PAYMENT_INTENT = 'paymentIntent';

export class StripeProvider extends BasePaymentProvider {
    constructor(
        private http: HttpClient,
        private logger: NGXLogger,
        private stripe: StripeService,
    ) {
        super();
    }

    override prepare(params: PaymentData): Observable<boolean> {
        if (typeof (params.amount) !== 'number') {
            throw new Error('amount not passed');
        }

        if (this.getProp(STRIPE_PROP_CLIENT_SECRET)) {
            return of(true);
        } else {
            const url = environment.stripe.localUrl
                ? `${environment.stripe.localUrl}payments-stripeCreatePaymentIntent`
                : this.getUrlGen1('payments-stripeCreatePaymentIntent');

            return this.http.post<{ paymentIntent: PaymentIntent; }>(url,
                {
                    amount: (params.amount) * 100, //  always in cents
                    user: params.userData,
                    description: params.description,
                    orderData: params.orderData || {}
                },
            ).pipe(
                map((result) => {
                const pi = result.paymentIntent;

                this.setProp(STRIPE_PROP_PAYMENT_INTENT, pi);

                if (pi.client_secret !== null) {
                    this.setProp(STRIPE_PROP_CLIENT_SECRET, pi.client_secret);
                    this.logger.debug('stripe SK', pi.client_secret);
                }

                return true;
            }));
        }
    }

    override reset() {
        this.setProp(STRIPE_PROP_PAYMENT_INTENT, undefined);
        this.setProp(STRIPE_PROP_CLIENT_SECRET, undefined);
    }

    override pay(
        formData: Record<string, unknown>,
        params: Record<string, unknown>,
    ): Observable<PaymentResult> {
        if (typeof (params['elements']) !== 'object' || params['elements'] === null) {
            throw new Error('No "elements" prop');
        }

        return this.stripe.confirmPayment({
            elements: params['elements'] as StripeElements,
            confirmParams: {
                payment_method_data: {
                    billing_details: {
                        name: formData['name'] as string,
                        email: formData['email'] as string,
                        address: {
                            line1: formData['address'] as string || '',
                            line2: '',
                            city: formData['city'] as string || '',
                            postal_code: formData['zipcode'] as string || '',
                            country: formData['country'] as string || '',
                        },
                    },
                },
            },
            redirect: 'if_required',
        }).pipe(map((result) => {
            this.logger.debug('pay result', result);

            const payResult: PaymentResult = {
                status: PaymentStatus.Success,
            };

            try {
                if (result.error) {
                    payResult.errorMessage = result.error.message;
                    throw new Error('');
                }

                if (!result.paymentIntent) {
                    payResult.errorMessage = 'API error';

                    payResult.details = {
                        debug: 'No "paymentIntent" field in response'
                    };

                    throw new Error();
                }

                if (result.paymentIntent.status !== 'succeeded') {
                    throw new Error('Operation status' + result.paymentIntent?.status);
                }
            } catch (err) {
                payResult.status = PaymentStatus.Failed;
            }

            return payResult;
        }));
    }
}
