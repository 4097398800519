/* eslint-disable */
export const play = [
	{
		info: '10 $PLAY Tokens',
		name: 'Current Balance',
	},
	{
		info: '10 $PLAY Tokens',
		name: 'Unvested Balance',
	},
	{
		info: '10/12/22',
		name: 'Next Vesting Date',
		redirectUrl: 'link',
	},
	{
		info: '10 $PLAY Tokens',
		name: 'Next Vest',
	},
];

export const staking = [
	{
		info: '10 $PLAY Tokens',
		name: 'Current Balance',
	},
	{
		info: '10 $PLAY Tokens',
		name: 'Current Balance',
		redirectUrl: 'link',
	},
	{
		info: '10 $PLAY Tokens',
		name: 'Current Balance',
		redirectUrl: 'link',
	},
];

export const govern = [
	{
		info: '10 $PLAY Tokens',
		name: 'Current Balance',
	},
];

export const fuseblocks = [
	{
		info: '10 $PLAY Tokens',
		name: 'Current Balance',
		redirectUrl: 'link',
	},
	{
		info: '10 $PLAY Tokens',
		name: 'Current Balance',
		redirectUrl: 'link',
	},
];

export const playMenu = [
	{
		category: 'play',
		path: 'play',
		items: play,
		icon: 'play_icon',
		name: '$PLAY',
	},
	{
		category: 'staking',
		path: 'staking',
		items: staking,
		icon: 'staking_icon',
		name: 'Staking',
	},
	{
		category: 'govern',
		path: 'govern',
		items: govern,
		icon: 'governance_icon',
		name: 'Govern',
	},
	{
		category: 'fuseblocks',
		path: 'fuseblocks',
		items: fuseblocks,
		icon: 'fuseblock_icon',
		name: 'Fuseblock',
	},
];
