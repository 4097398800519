// Angular
import { Component } from '@angular/core';

// Services
import { ShoppingCartService } from 'app/modules/admin/project/shopping-cart/shopping-cart.service';
import { FuseNavigationService } from '@fuse/components/navigation';

// Interface
import { ShoppingCart } from 'app/modules/admin/project/shopping-cart/interface/cart.type';
import { MatDialog } from '@angular/material/dialog';
import { DialogFeatureComingSoon } from 'app/modules/admin/components/soon/soon.component';

// Template to use when using the shopping cart
// <div class="shopping-cart" [routerLink]="getLink('shopping-cart')" routerLinkActive="active">

@Component({
	selector: 'shopping-cart',
	styleUrls: ['shopping-cart.component.scss'],
	template: `
		<div class="shopping-cart" (click)="commingSoon()">
			<a>
				<app-custom-icon icon="cart"></app-custom-icon>

				<span *ngIf="cart.items.total_quantity > 0">{{
					cart.items.total_quantity
				}}</span>
			</a>
		</div>
	`,
})
export class ShoppingCartHeaderComponent {
	public cart: ShoppingCart = {} as ShoppingCart;
	public label: string;

	constructor(
		private readonly _shoppingCart: ShoppingCartService,
		private readonly _fuseNavigationService: FuseNavigationService,
		private readonly _dialog: MatDialog,
	) {
		this.label = 'Cart';

		this._shoppingCart.cart$.subscribe((value) => {
			this.cart = value;
		});
	}

	public commingSoon() {
		this._dialog.open(DialogFeatureComingSoon, {
			width: '80%',
			closeOnNavigation: true,
			maxWidth: '600px',
			data: {
				subtitle: [
					`Since Fuseblocks contain $PLAY, you won’t be able to purchase them until the token has gone live!`,
					`Until $PLAY launches you have a few options for minting items - completing onboarding milestones, switching to the staging environment, or getting in touch to request minting for your game in production!`,
				],
			},
		});
	}

	public getLink(link: string): string {
		if (this._fuseNavigationService?.projectId) {
			return (
				'project/' + [this._fuseNavigationService.projectId] + '/' + [link]
			);
		}

		return 'project/';
	}
}
