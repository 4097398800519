<button class="border-0 outline-none flex justify-between w-full max-w-full"
    mat-icon-button
    [matMenuTriggerFor]="blockchainMenu"
    [disabled]="disabled"
    >
    <span class="truncate text-white">{{ selected ? selected.name : '' }}</span>
    <mat-icon class="text-gray-7">expand_more</mat-icon>
</button>

<mat-menu #blockchainMenu="matMenu" class="aura-dropdown-menu {{dropdownClass}}">
    <button mat-menu-item
        *ngFor="let item of items"
        [ngClass]="{ 'active': selected && selected.value === item.value}"
        (click)="selectItem(item)"
        >
        {{ item.name }}
    </button>
</mat-menu>
