/* eslint-disable */

// Updated at: 20210425 - 1792 icons
export const material = [
	'10k',
	'10mp',
	'11mp',
	'12mp',
	'13mp',
	'14mp',
	'15mp',
	'16mp',
	'17mp',
	'18mp',
	'19mp',
	'1k',
	'1k_plus',
	'1x_mobiledata',
	'20mp',
	'21mp',
	'22mp',
	'23mp',
	'24mp',
	'2k',
	'2k_plus',
	'2mp',
	'30fps',
	'30fps_select',
	'360',
	'3d_rotation',
	'3g_mobiledata',
	'3k',
	'3k_plus',
	'3mp',
	'3p',
	'4g_mobiledata',
	'4g_plus_mobiledata',
	'4k',
	'4k_plus',
	'4mp',
	'5g',
	'5k',
	'5k_plus',
	'5mp',
	'60fps',
	'60fps_select',
	'6_ft_apart',
	'6k',
	'6k_plus',
	'6mp',
	'7k',
	'7k_plus',
	'7mp',
	'8k',
	'8k_plus',
	'8mp',
	'9k',
	'9k_plus',
	'9mp',
	'ac_unit',
	'access_alarm',
	'access_alarms',
	'access_time',
	'access_time_filled',
	'accessibility',
	'accessibility_new',
	'accessible',
	'accessible_forward',
	'account_balance',
	'account_balance_wallet',
	'account_box',
	'account_circle',
	'account_tree',
	'ad_units',
	'adb',
	'add',
	'add_a_photo',
	'add_alarm',
	'add_alert',
	'add_box',
	'add_business',
	'add_chart',
	'add_circle',
	'add_circle_outline',
	'add_comment',
	'add_ic_call',
	'add_link',
	'add_location',
	'add_location_alt',
	'add_moderator',
	'add_photo_alternate',
	'add_reaction',
	'add_road',
	'add_shopping_cart',
	'add_task',
	'add_to_drive',
	'add_to_home_screen',
	'add_to_photos',
	'add_to_queue',
	'addchart',
	'adjust',
	'admin_panel_settings',
	'ads_click',
	'agriculture',
	'air',
	'airline_seat_flat',
	'airline_seat_flat_angled',
	'airline_seat_individual_suite',
	'airline_seat_legroom_extra',
	'airline_seat_legroom_normal',
	'airline_seat_legroom_reduced',
	'airline_seat_recline_extra',
	'airline_seat_recline_normal',
	'airplane_ticket',
	'airplanemode_active',
	'airplanemode_inactive',
	'airplay',
	'airport_shuttle',
	'alarm',
	'alarm_add',
	'alarm_off',
	'alarm_on',
	'album',
	'align_horizontal_center',
	'align_horizontal_left',
	'align_horizontal_right',
	'align_vertical_bottom',
	'align_vertical_center',
	'align_vertical_top',
	'all_inbox',
	'all_inclusive',
	'all_out',
	'alt_route',
	'alternate_email',
	'analytics',
	'anchor',
	'android',
	'animation',
	'announcement',
	'aod',
	'apartment',
	'api',
	'app_blocking',
	'app_registration',
	'app_settings_alt',
	'approval',
	'apps',
	'architecture',
	'archive',
	'area_chart',
	'arrow_back',
	'arrow_back_ios',
	'arrow_back_ios_new',
	'arrow_circle_down',
	'arrow_circle_up',
	'arrow_downward',
	'arrow_drop_down',
	'arrow_drop_down_circle',
	'arrow_drop_up',
	'arrow_forward',
	'arrow_forward_ios',
	'arrow_left',
	'arrow_right',
	'arrow_right_alt',
	'arrow_upward',
	'art_track',
	'article',
	'aspect_ratio',
	'assessment',
	'assignment',
	'assignment_ind',
	'assignment_late',
	'assignment_return',
	'assignment_returned',
	'assignment_turned_in',
	'assistant',
	'assistant_direction',
	'assistant_photo',
	'atm',
	'attach_email',
	'attach_file',
	'attach_money',
	'attachment',
	'attractions',
	'attribution',
	'audiotrack',
	'auto_awesome',
	'auto_awesome_mosaic',
	'auto_awesome_motion',
	'auto_delete',
	'auto_fix_high',
	'auto_fix_normal',
	'auto_fix_off',
	'auto_graph',
	'auto_stories',
	'autofps_select',
	'autorenew',
	'av_timer',
	'baby_changing_station',
	'back_hand',
	'backpack',
	'backspace',
	'backup',
	'backup_table',
	'badge',
	'bakery_dining',
	'balcony',
	'ballot',
	'bar_chart',
	'batch_prediction',
	'bathroom',
	'bathtub',
	'battery_alert',
	'battery_charging_full',
	'battery_full',
	'battery_saver',
	'battery_std',
	'battery_unknown',
	'beach_access',
	'bed',
	'bedroom_baby',
	'bedroom_child',
	'bedroom_parent',
	'bedtime',
	'beenhere',
	'bento',
	'bike_scooter',
	'biotech',
	'blender',
	'block',
	'bloodtype',
	'bluetooth',
	'bluetooth_audio',
	'bluetooth_connected',
	'bluetooth_disabled',
	'bluetooth_drive',
	'bluetooth_searching',
	'blur_circular',
	'blur_linear',
	'blur_off',
	'blur_on',
	'bolt',
	'book',
	'book_online',
	'bookmark',
	'bookmark_add',
	'bookmark_added',
	'bookmark_border',
	'bookmark_remove',
	'bookmarks',
	'border_all',
	'border_bottom',
	'border_clear',
	'border_color',
	'border_horizontal',
	'border_inner',
	'border_left',
	'border_outer',
	'border_right',
	'border_style',
	'border_top',
	'border_vertical',
	'branding_watermark',
	'breakfast_dining',
	'brightness_1',
	'brightness_2',
	'brightness_3',
	'brightness_4',
	'brightness_5',
	'brightness_6',
	'brightness_7',
	'brightness_auto',
	'brightness_high',
	'brightness_low',
	'brightness_medium',
	'broken_image',
	'browser_not_supported',
	'brunch_dining',
	'brush',
	'bubble_chart',
	'bug_report',
	'build',
	'build_circle',
	'bungalow',
	'burst_mode',
	'bus_alert',
	'business',
	'business_center',
	'cabin',
	'cable',
	'cached',
	'cake',
	'calculate',
	'calendar_today',
	'calendar_view_day',
	'calendar_view_month',
	'calendar_view_week',
	'call',
	'call_end',
	'call_made',
	'call_merge',
	'call_missed',
	'call_missed_outgoing',
	'call_received',
	'call_split',
	'call_to_action',
	'camera',
	'camera_alt',
	'camera_enhance',
	'camera_front',
	'camera_indoor',
	'camera_outdoor',
	'camera_rear',
	'camera_roll',
	'cameraswitch',
	'campaign',
	'cancel',
	'cancel_presentation',
	'cancel_schedule_send',
	'car_rental',
	'car_repair',
	'card_giftcard',
	'card_membership',
	'card_travel',
	'carpenter',
	'cases',
	'casino',
	'cast',
	'cast_connected',
	'cast_for_education',
	'catching_pokemon',
	'category',
	'celebration',
	'cell_wifi',
	'center_focus_strong',
	'center_focus_weak',
	'chair',
	'chair_alt',
	'chalet',
	'change_circle',
	'change_history',
	'charging_station',
	'chat',
	'chat_bubble',
	'chat_bubble_outline',
	'check',
	'check_box',
	'check_box_outline_blank',
	'check_circle',
	'check_circle_outline',
	'checklist',
	'checklist_rtl',
	'checkroom',
	'chevron_left',
	'chevron_right',
	'child_care',
	'child_friendly',
	'chrome_reader_mode',
	'circle',
	'circle_notifications',
	'class',
	'clean_hands',
	'cleaning_services',
	'clear',
	'clear_all',
	'close',
	'close_fullscreen',
	'closed_caption',
	'closed_caption_disabled',
	'closed_caption_off',
	'cloud',
	'cloud_circle',
	'cloud_done',
	'cloud_download',
	'cloud_off',
	'cloud_queue',
	'cloud_upload',
	'code',
	'code_off',
	'coffee',
	'coffee_maker',
	'collections',
	'collections_bookmark',
	'color_lens',
	'colorize',
	'comment',
	'comment_bank',
	'commute',
	'compare',
	'compare_arrows',
	'compass_calibration',
	'compost',
	'compress',
	'computer',
	'confirmation_number',
	'connect_without_contact',
	'connected_tv',
	'construction',
	'contact_mail',
	'contact_page',
	'contact_phone',
	'contact_support',
	'contactless',
	'contacts',
	'content_copy',
	'content_cut',
	'content_paste',
	'content_paste_off',
	'control_camera',
	'control_point',
	'control_point_duplicate',
	'copy_all',
	'copyright',
	'coronavirus',
	'corporate_fare',
	'cottage',
	'countertops',
	'create',
	'create_new_folder',
	'credit_card',
	'credit_card_off',
	'credit_score',
	'crib',
	'crop',
	'crop_16_9',
	'crop_3_2',
	'crop_5_4',
	'crop_7_5',
	'crop_din',
	'crop_free',
	'crop_landscape',
	'crop_original',
	'crop_portrait',
	'crop_rotate',
	'crop_square',
	'cruelty_free',
	'dangerous',
	'dark_mode',
	'dashboard',
	'dashboard_customize',
	'data_exploration',
	'data_saver_off',
	'data_saver_on',
	'data_usage',
	'date_range',
	'deck',
	'dehaze',
	'delete',
	'delete_forever',
	'delete_outline',
	'delete_sweep',
	'delivery_dining',
	'departure_board',
	'description',
	'design_services',
	'desktop_access_disabled',
	'desktop_mac',
	'desktop_windows',
	'details',
	'developer_board',
	'developer_board_off',
	'developer_mode',
	'device_hub',
	'device_thermostat',
	'device_unknown',
	'devices',
	'devices_other',
	'dialer_sip',
	'dialpad',
	'dining',
	'dinner_dining',
	'directions',
	'directions_bike',
	'directions_boat',
	'directions_boat_filled',
	'directions_bus',
	'directions_bus_filled',
	'directions_car',
	'directions_car_filled',
	'directions_off',
	'directions_railway',
	'directions_railway_filled',
	'directions_run',
	'directions_subway',
	'directions_subway_filled',
	'directions_transit',
	'directions_transit_filled',
	'directions_walk',
	'dirty_lens',
	'disabled_by_default',
	'disabled_visible',
	'disc_full',
	'dns',
	'do_disturb',
	'do_disturb_alt',
	'do_disturb_off',
	'do_disturb_on',
	'do_not_disturb',
	'do_not_disturb_alt',
	'do_not_disturb_off',
	'do_not_disturb_on',
	'do_not_disturb_on_total_silence',
	'do_not_step',
	'do_not_touch',
	'dock',
	'document_scanner',
	'domain',
	'domain_disabled',
	'domain_verification',
	'done',
	'done_all',
	'done_outline',
	'donut_large',
	'donut_small',
	'door_back',
	'door_front',
	'door_sliding',
	'doorbell',
	'double_arrow',
	'downhill_skiing',
	'download',
	'download_done',
	'download_for_offline',
	'downloading',
	'drafts',
	'drag_handle',
	'drag_indicator',
	'draw',
	'drive_eta',
	'drive_file_move',
	'drive_file_move_rtl',
	'drive_file_rename_outline',
	'drive_folder_upload',
	'dry',
	'dry_cleaning',
	'duo',
	'dvr',
	'dynamic_feed',
	'dynamic_form',
	'e_mobiledata',
	'earbuds',
	'earbuds_battery',
	'east',
	'edgesensor_high',
	'edgesensor_low',
	'edit',
	'edit_attributes',
	'edit_calendar',
	'edit_location',
	'edit_location_alt',
	'edit_note',
	'edit_notifications',
	'edit_off',
	'edit_road',
	'eject',
	'elderly',
	'electric_bike',
	'electric_car',
	'electric_moped',
	'electric_rickshaw',
	'electric_scooter',
	'electrical_services',
	'elevator',
	'email',
	'emergency',
	'emoji_emotions',
	'emoji_events',
	'emoji_flags',
	'emoji_food_beverage',
	'emoji_nature',
	'emoji_objects',
	'emoji_people',
	'emoji_symbols',
	'emoji_transportation',
	'engineering',
	'enhanced_encryption',
	'equalizer',
	'error',
	'error_outline',
	'escalator',
	'escalator_warning',
	'euro',
	'euro_symbol',
	'ev_station',
	'event',
	'event_available',
	'event_busy',
	'event_note',
	'event_seat',
	'exit_to_app',
	'expand',
	'expand_less',
	'expand_more',
	'explicit',
	'explore',
	'explore_off',
	'exposure',
	'exposure_neg_1',
	'exposure_neg_2',
	'exposure_plus_1',
	'exposure_plus_2',
	'exposure_zero',
	'extension',
	'extension_off',
	'face',
	'face_retouching_natural',
	'face_retouching_off',
	'facebook',
	'fact_check',
	'family_restroom',
	'fast_forward',
	'fast_rewind',
	'fastfood',
	'favorite',
	'favorite_border',
	'featured_play_list',
	'featured_video',
	'feed',
	'feedback',
	'female',
	'fence',
	'festival',
	'fiber_dvr',
	'fiber_manual_record',
	'fiber_new',
	'fiber_pin',
	'fiber_smart_record',
	'file_copy',
	'file_download',
	'file_download_done',
	'file_download_off',
	'file_present',
	'file_upload',
	'filter',
	'filter_1',
	'filter_2',
	'filter_3',
	'filter_4',
	'filter_5',
	'filter_6',
	'filter_7',
	'filter_8',
	'filter_9',
	'filter_9_plus',
	'filter_alt',
	'filter_b_and_w',
	'filter_center_focus',
	'filter_drama',
	'filter_frames',
	'filter_hdr',
	'filter_list',
	'filter_none',
	'filter_tilt_shift',
	'filter_vintage',
	'find_in_page',
	'find_replace',
	'fingerprint',
	'fire_extinguisher',
	'fireplace',
	'first_page',
	'fit_screen',
	'fitness_center',
	'flag',
	'flaky',
	'flare',
	'flash_auto',
	'flash_off',
	'flash_on',
	'flashlight_off',
	'flashlight_on',
	'flatware',
	'flight',
	'flight_land',
	'flight_takeoff',
	'flip',
	'flip_camera_android',
	'flip_camera_ios',
	'flip_to_back',
	'flip_to_front',
	'flourescent',
	'flutter_dash',
	'fmd_bad',
	'fmd_good',
	'folder',
	'folder_open',
	'folder_shared',
	'folder_special',
	'follow_the_signs',
	'font_download',
	'font_download_off',
	'food_bank',
	'format_align_center',
	'format_align_justify',
	'format_align_left',
	'format_align_right',
	'format_bold',
	'format_clear',
	'format_color_fill',
	'format_color_reset',
	'format_color_text',
	'format_indent_decrease',
	'format_indent_increase',
	'format_italic',
	'format_line_spacing',
	'format_list_bulleted',
	'format_list_numbered',
	'format_list_numbered_rtl',
	'format_paint',
	'format_quote',
	'format_shapes',
	'format_size',
	'format_strikethrough',
	'format_textdirection_l_to_r',
	'format_textdirection_r_to_l',
	'format_underlined',
	'forum',
	'forward',
	'forward_10',
	'forward_30',
	'forward_5',
	'forward_to_inbox',
	'foundation',
	'free_breakfast',
	'free_cancellation',
	'front_hand',
	'fullscreen',
	'fullscreen_exit',
	'functions',
	'g_mobiledata',
	'g_translate',
	'gamepad',
	'games',
	'garage',
	'gavel',
	'generating_tokens',
	'gesture',
	'get_app',
	'gif',
	'gite',
	'golf_course',
	'gpp_bad',
	'gpp_good',
	'gpp_maybe',
	'gps_fixed',
	'gps_not_fixed',
	'gps_off',
	'grade',
	'gradient',
	'grading',
	'grain',
	'graphic_eq',
	'grass',
	'grid_3x3',
	'grid_4x4',
	'grid_goldenratio',
	'grid_off',
	'grid_on',
	'grid_view',
	'group',
	'group_add',
	'group_off',
	'group_work',
	'groups',
	'h_mobiledata',
	'h_plus_mobiledata',
	'hail',
	'handyman',
	'hardware',
	'hd',
	'hdr_auto',
	'hdr_auto_select',
	'hdr_enhanced_select',
	'hdr_off',
	'hdr_off_select',
	'hdr_on',
	'hdr_on_select',
	'hdr_plus',
	'hdr_strong',
	'hdr_weak',
	'headphones',
	'headphones_battery',
	'headset',
	'headset_mic',
	'headset_off',
	'healing',
	'health_and_safety',
	'hearing',
	'hearing_disabled',
	'height',
	'help',
	'help_center',
	'help_outline',
	'hevc',
	'hide_image',
	'hide_source',
	'high_quality',
	'highlight',
	'highlight_alt',
	'highlight_off',
	'hiking',
	'history',
	'history_edu',
	'history_toggle_off',
	'holiday_village',
	'home',
	'home_max',
	'home_mini',
	'home_repair_service',
	'home_work',
	'horizontal_distribute',
	'horizontal_rule',
	'horizontal_split',
	'hot_tub',
	'hotel',
	'hotel_class',
	'hourglass_bottom',
	'hourglass_disabled',
	'hourglass_empty',
	'hourglass_full',
	'hourglass_top',
	'house',
	'house_siding',
	'houseboat',
	'how_to_reg',
	'how_to_vote',
	'http',
	'https',
	'hvac',
	'ice_skating',
	'icecream',
	'image',
	'image_aspect_ratio',
	'image_not_supported',
	'image_search',
	'imagesearch_roller',
	'import_contacts',
	'import_export',
	'important_devices',
	'inbox',
	'incomplete_circle',
	'indeterminate_check_box',
	'info',
	'input',
	'insert_chart',
	'insert_chart_outlined',
	'insert_comment',
	'insert_drive_file',
	'insert_emoticon',
	'insert_invitation',
	'insert_link',
	'insert_photo',
	'insights',
	'integration_instructions',
	'inventory',
	'inventory_2',
	'invert_colors',
	'invert_colors_off',
	'ios_share',
	'iron',
	'iso',
	'kayaking',
	'keyboard',
	'keyboard_alt',
	'keyboard_arrow_down',
	'keyboard_arrow_left',
	'keyboard_arrow_right',
	'keyboard_arrow_up',
	'keyboard_backspace',
	'keyboard_capslock',
	'keyboard_hide',
	'keyboard_return',
	'keyboard_tab',
	'keyboard_voice',
	'king_bed',
	'kitchen',
	'kitesurfing',
	'label',
	'label_important',
	'label_off',
	'landscape',
	'language',
	'laptop',
	'laptop_chromebook',
	'laptop_mac',
	'laptop_windows',
	'last_page',
	'launch',
	'layers',
	'layers_clear',
	'leaderboard',
	'leak_add',
	'leak_remove',
	'legend_toggle',
	'lens',
	'lens_blur',
	'library_add',
	'library_add_check',
	'library_books',
	'library_music',
	'light',
	'light_mode',
	'lightbulb',
	'line_style',
	'line_weight',
	'linear_scale',
	'link',
	'link_off',
	'linked_camera',
	'liquor',
	'list',
	'list_alt',
	'live_help',
	'live_tv',
	'living',
	'local_activity',
	'local_airport',
	'local_atm',
	'local_bar',
	'local_cafe',
	'local_car_wash',
	'local_convenience_store',
	'local_dining',
	'local_drink',
	'local_fire_department',
	'local_florist',
	'local_gas_station',
	'local_grocery_store',
	'local_hospital',
	'local_hotel',
	'local_laundry_service',
	'local_library',
	'local_mall',
	'local_movies',
	'local_offer',
	'local_parking',
	'local_pharmacy',
	'local_phone',
	'local_pizza',
	'local_play',
	'local_police',
	'local_post_office',
	'local_printshop',
	'local_see',
	'local_shipping',
	'local_taxi',
	'location_city',
	'location_disabled',
	'location_off',
	'location_on',
	'location_searching',
	'lock',
	'lock_clock',
	'lock_open',
	'login',
	'logout',
	'looks',
	'looks_3',
	'looks_4',
	'looks_5',
	'looks_6',
	'looks_one',
	'looks_two',
	'loop',
	'loupe',
	'low_priority',
	'loyalty',
	'lte_mobiledata',
	'lte_plus_mobiledata',
	'luggage',
	'lunch_dining',
	'mail',
	'mail_outline',
	'male',
	'manage_accounts',
	'manage_search',
	'map',
	'maps_home_work',
	'maps_ugc',
	'margin',
	'mark_as_unread',
	'mark_chat_read',
	'mark_chat_unread',
	'mark_email_read',
	'mark_email_unread',
	'markunread',
	'markunread_mailbox',
	'masks',
	'maximize',
	'media_bluetooth_off',
	'media_bluetooth_on',
	'mediation',
	'medical_services',
	'medication',
	'meeting_room',
	'memory',
	'menu',
	'menu_book',
	'menu_open',
	'merge_type',
	'message',
	'mic',
	'mic_external_off',
	'mic_external_on',
	'mic_none',
	'mic_off',
	'microwave',
	'military_tech',
	'minimize',
	'miscellaneous_services',
	'missed_video_call',
	'mms',
	'mobile_friendly',
	'mobile_off',
	'mobile_screen_share',
	'mobiledata_off',
	'mode',
	'mode_comment',
	'mode_edit',
	'mode_edit_outline',
	'mode_night',
	'mode_standby',
	'model_training',
	'monetization_on',
	'money',
	'money_off',
	'money_off_csred',
	'monitor',
	'monitor_weight',
	'monochrome_photos',
	'mood',
	'mood_bad',
	'moped',
	'more',
	'more_horiz',
	'more_time',
	'more_vert',
	'motion_photos_auto',
	'motion_photos_off',
	'motion_photos_on',
	'motion_photos_pause',
	'motion_photos_paused',
	'mouse',
	'move_to_inbox',
	'movie',
	'movie_creation',
	'movie_filter',
	'moving',
	'mp',
	'multiline_chart',
	'multiple_stop',
	'museum',
	'music_note',
	'music_off',
	'music_video',
	'my_location',
	'nat',
	'nature',
	'nature_people',
	'navigate_before',
	'navigate_next',
	'navigation',
	'near_me',
	'near_me_disabled',
	'nearby_error',
	'nearby_off',
	'network_cell',
	'network_check',
	'network_locked',
	'network_wifi',
	'new_label',
	'new_releases',
	'next_plan',
	'next_week',
	'nfc',
	'night_shelter',
	'nightlife',
	'nightlight',
	'nightlight_round',
	'nights_stay',
	'no_accounts',
	'no_backpack',
	'no_cell',
	'no_drinks',
	'no_encryption',
	'no_encryption_gmailerrorred',
	'no_flash',
	'no_food',
	'no_luggage',
	'no_meals',
	'no_meeting_room',
	'no_photography',
	'no_sim',
	'no_stroller',
	'no_transfer',
	'nordic_walking',
	'north',
	'north_east',
	'north_west',
	'not_accessible',
	'not_interested',
	'not_listed_location',
	'not_started',
	'note',
	'note_add',
	'note_alt',
	'notes',
	'notification_add',
	'notification_important',
	'notifications',
	'notifications_active',
	'notifications_none',
	'notifications_off',
	'notifications_paused',
	'offline_bolt',
	'offline_pin',
	'offline_share',
	'ondemand_video',
	'online_prediction',
	'opacity',
	'open_in_browser',
	'open_in_full',
	'open_in_new',
	'open_in_new_off',
	'open_with',
	'other_houses',
	'outbound',
	'outbox',
	'outdoor_grill',
	'outlet',
	'outlined_flag',
	'padding',
	'pages',
	'pageview',
	'paid',
	'palette',
	'pan_tool',
	'panorama',
	'panorama_fish_eye',
	'panorama_horizontal',
	'panorama_horizontal_select',
	'panorama_photosphere',
	'panorama_photosphere_select',
	'panorama_vertical',
	'panorama_vertical_select',
	'panorama_wide_angle',
	'panorama_wide_angle_select',
	'paragliding',
	'park',
	'party_mode',
	'password',
	'pattern',
	'pause',
	'pause_circle',
	'pause_circle_filled',
	'pause_circle_outline',
	'pause_presentation',
	'payment',
	'payments',
	'pedal_bike',
	'pending',
	'pending_actions',
	'people',
	'people_alt',
	'people_outline',
	'perm_camera_mic',
	'perm_contact_calendar',
	'perm_data_setting',
	'perm_device_information',
	'perm_identity',
	'perm_media',
	'perm_phone_msg',
	'perm_scan_wifi',
	'person',
	'person_add',
	'person_add_alt',
	'person_add_alt_1',
	'person_add_disabled',
	'person_off',
	'person_outline',
	'person_pin',
	'person_pin_circle',
	'person_remove',
	'person_remove_alt_1',
	'person_search',
	'personal_injury',
	'personal_video',
	'pest_control',
	'pest_control_rodent',
	'pets',
	'phone',
	'phone_android',
	'phone_bluetooth_speaker',
	'phone_callback',
	'phone_disabled',
	'phone_enabled',
	'phone_forwarded',
	'phone_in_talk',
	'phone_iphone',
	'phone_locked',
	'phone_missed',
	'phone_paused',
	'phonelink',
	'phonelink_erase',
	'phonelink_lock',
	'phonelink_off',
	'phonelink_ring',
	'phonelink_setup',
	'photo',
	'photo_album',
	'photo_camera',
	'photo_camera_back',
	'photo_camera_front',
	'photo_filter',
	'photo_library',
	'photo_size_select_actual',
	'photo_size_select_large',
	'photo_size_select_small',
	'piano',
	'piano_off',
	'picture_as_pdf',
	'picture_in_picture',
	'picture_in_picture_alt',
	'pie_chart',
	'pie_chart_outline',
	'pin',
	'pin_drop',
	'pin_end',
	'pin_invoke',
	'pivot_table_chart',
	'place',
	'plagiarism',
	'play_arrow',
	'play_circle',
	'play_circle_filled',
	'play_circle_outline',
	'play_disabled',
	'play_for_work',
	'play_lesson',
	'playlist_add',
	'playlist_add_check',
	'playlist_play',
	'plumbing',
	'plus_one',
	'podcasts',
	'point_of_sale',
	'policy',
	'poll',
	'polymer',
	'pool',
	'portable_wifi_off',
	'portrait',
	'post_add',
	'power',
	'power_input',
	'power_off',
	'power_settings_new',
	'precision_manufacturing',
	'pregnant_woman',
	'present_to_all',
	'preview',
	'price_change',
	'price_check',
	'print',
	'print_disabled',
	'priority_high',
	'privacy_tip',
	'private_connectivity',
	'production_quantity_limits',
	'psychology',
	'public',
	'public_off',
	'publish',
	'published_with_changes',
	'push_pin',
	'qr_code',
	'qr_code_2',
	'qr_code_scanner',
	'query_builder',
	'query_stats',
	'question_answer',
	'queue',
	'queue_music',
	'queue_play_next',
	'quickreply',
	'quiz',
	'r_mobiledata',
	'radar',
	'radio',
	'radio_button_checked',
	'radio_button_unchecked',
	'railway_alert',
	'ramen_dining',
	'rate_review',
	'raw_off',
	'raw_on',
	'read_more',
	'real_estate_agent',
	'receipt',
	'receipt_long',
	'recent_actors',
	'recommend',
	'record_voice_over',
	'recycling',
	'redeem',
	'redo',
	'reduce_capacity',
	'refresh',
	'remember_me',
	'remove',
	'remove_circle',
	'remove_circle_outline',
	'remove_done',
	'remove_from_queue',
	'remove_moderator',
	'remove_red_eye',
	'remove_shopping_cart',
	'reorder',
	'repeat',
	'repeat_on',
	'repeat_one',
	'repeat_one_on',
	'replay',
	'replay_10',
	'replay_30',
	'replay_5',
	'replay_circle_filled',
	'reply',
	'reply_all',
	'report',
	'report_gmailerrorred',
	'report_off',
	'report_problem',
	'request_page',
	'request_quote',
	'reset_tv',
	'restart_alt',
	'restaurant',
	'restaurant_menu',
	'restore',
	'restore_from_trash',
	'restore_page',
	'reviews',
	'rice_bowl',
	'ring_volume',
	'roofing',
	'room',
	'room_preferences',
	'room_service',
	'rotate_90_degrees_ccw',
	'rotate_left',
	'rotate_right',
	'rounded_corner',
	'router',
	'rowing',
	'rss_feed',
	'rsvp',
	'rtt',
	'rule',
	'rule_folder',
	'run_circle',
	'running_with_errors',
	'rv_hookup',
	'safety_divider',
	'sailing',
	'sanitizer',
	'satellite',
	'save',
	'save_alt',
	'saved_search',
	'savings',
	'scanner',
	'scatter_plot',
	'schedule',
	'schedule_send',
	'schema',
	'school',
	'science',
	'score',
	'screen_lock_landscape',
	'screen_lock_portrait',
	'screen_lock_rotation',
	'screen_rotation',
	'screen_search_desktop',
	'screen_share',
	'screenshot',
	'sd',
	'sd_card',
	'sd_card_alert',
	'sd_storage',
	'search',
	'search_off',
	'security',
	'security_update',
	'security_update_good',
	'security_update_warning',
	'segment',
	'select_all',
	'self_improvement',
	'sell',
	'send',
	'send_and_archive',
	'send_to_mobile',
	'sensor_door',
	'sensor_window',
	'sensors',
	'sensors_off',
	'sentiment_dissatisfied',
	'sentiment_neutral',
	'sentiment_satisfied',
	'sentiment_satisfied_alt',
	'sentiment_very_dissatisfied',
	'sentiment_very_satisfied',
	'set_meal',
	'settings',
	'settings_accessibility',
	'settings_applications',
	'settings_backup_restore',
	'settings_bluetooth',
	'settings_brightness',
	'settings_cell',
	'settings_ethernet',
	'settings_input_antenna',
	'settings_input_component',
	'settings_input_composite',
	'settings_input_hdmi',
	'settings_input_svideo',
	'settings_overscan',
	'settings_phone',
	'settings_power',
	'settings_remote',
	'settings_suggest',
	'settings_system_daydream',
	'settings_voice',
	'share',
	'share_location',
	'shield',
	'shop',
	'shop_2',
	'shop_two',
	'shopping_bag',
	'shopping_basket',
	'shopping_cart',
	'short_text',
	'shortcut',
	'show_chart',
	'shower',
	'shuffle',
	'shuffle_on',
	'shutter_speed',
	'sick',
	'signal_cellular_0_bar',
	'signal_cellular_4_bar',
	'signal_cellular_alt',
	'signal_cellular_connected_no_internet_0_bar',
	'signal_cellular_connected_no_internet_4_bar',
	'signal_cellular_no_sim',
	'signal_cellular_nodata',
	'signal_cellular_null',
	'signal_cellular_off',
	'signal_wifi_0_bar',
	'signal_wifi_4_bar',
	'signal_wifi_4_bar_lock',
	'signal_wifi_bad',
	'signal_wifi_connected_no_internet_4',
	'signal_wifi_off',
	'signal_wifi_statusbar_4_bar',
	'signal_wifi_statusbar_connected_no_internet_4',
	'signal_wifi_statusbar_null',
	'sim_card',
	'sim_card_alert',
	'sim_card_download',
	'single_bed',
	'sip',
	'skateboarding',
	'skip_next',
	'skip_previous',
	'sledding',
	'slideshow',
	'slow_motion_video',
	'smart_button',
	'smart_display',
	'smart_screen',
	'smart_toy',
	'smartphone',
	'smoke_free',
	'smoking_rooms',
	'sms',
	'sms_failed',
	'snippet_folder',
	'snooze',
	'snowboarding',
	'snowmobile',
	'snowshoeing',
	'soap',
	'social_distance',
	'sort',
	'sort_by_alpha',
	'source',
	'south',
	'south_east',
	'south_west',
	'spa',
	'space_bar',
	'space_dashboard',
	'speaker',
	'speaker_group',
	'speaker_notes',
	'speaker_notes_off',
	'speaker_phone',
	'speed',
	'spellcheck',
	'splitscreen',
	'sports',
	'sports_bar',
	'sports_baseball',
	'sports_basketball',
	'sports_cricket',
	'sports_esports',
	'sports_football',
	'sports_golf',
	'sports_handball',
	'sports_hockey',
	'sports_kabaddi',
	'sports_mma',
	'sports_motorsports',
	'sports_rugby',
	'sports_score',
	'sports_soccer',
	'sports_tennis',
	'sports_volleyball',
	'square_foot',
	'stacked_bar_chart',
	'stacked_line_chart',
	'stairs',
	'star',
	'star_border',
	'star_border_purple500',
	'star_half',
	'star_outline',
	'star_purple500',
	'star_rate',
	'stars',
	'stay_current_landscape',
	'stay_current_portrait',
	'stay_primary_landscape',
	'stay_primary_portrait',
	'sticky_note_2',
	'stop',
	'stop_circle',
	'stop_screen_share',
	'storage',
	'store',
	'store_mall_directory',
	'storefront',
	'storm',
	'straighten',
	'stream',
	'streetview',
	'strikethrough_s',
	'stroller',
	'style',
	'subdirectory_arrow_left',
	'subdirectory_arrow_right',
	'subject',
	'subscript',
	'subscriptions',
	'subtitles',
	'subtitles_off',
	'subway',
	'summarize',
	'superscript',
	'supervised_user_circle',
	'supervisor_account',
	'support',
	'support_agent',
	'surfing',
	'surround_sound',
	'swap_calls',
	'swap_horiz',
	'swap_horizontal_circle',
	'swap_vert',
	'swap_vertical_circle',
	'swipe',
	'switch_account',
	'switch_camera',
	'switch_left',
	'switch_right',
	'switch_video',
	'sync',
	'sync_alt',
	'sync_disabled',
	'sync_problem',
	'system_security_update',
	'system_security_update_good',
	'system_security_update_warning',
	'system_update',
	'system_update_alt',
	'tab',
	'tab_unselected',
	'table_chart',
	'table_rows',
	'table_view',
	'tablet',
	'tablet_android',
	'tablet_mac',
	'tag',
	'tag_faces',
	'takeout_dining',
	'tap_and_play',
	'tapas',
	'task',
	'task_alt',
	'taxi_alert',
	'terrain',
	'text_fields',
	'text_format',
	'text_rotate_up',
	'text_rotate_vertical',
	'text_rotation_angledown',
	'text_rotation_angleup',
	'text_rotation_down',
	'text_rotation_none',
	'text_snippet',
	'textsms',
	'texture',
	'theater_comedy',
	'theaters',
	'thermostat',
	'thermostat_auto',
	'thumb_down',
	'thumb_down_alt',
	'thumb_down_off_alt',
	'thumb_up',
	'thumb_up_alt',
	'thumb_up_off_alt',
	'thumbs_up_down',
	'time_to_leave',
	'timelapse',
	'timeline',
	'timer',
	'timer_10',
	'timer_10_select',
	'timer_3',
	'timer_3_select',
	'timer_off',
	'tips_and_updates',
	'title',
	'toc',
	'today',
	'toggle_off',
	'toggle_on',
	'toll',
	'tonality',
	'topic',
	'touch_app',
	'tour',
	'toys',
	'track_changes',
	'traffic',
	'train',
	'tram',
	'transfer_within_a_station',
	'transform',
	'transgender',
	'transit_enterexit',
	'translate',
	'travel_explore',
	'trending_down',
	'trending_flat',
	'trending_up',
	'trip_origin',
	'try',
	'tty',
	'tune',
	'tungsten',
	'turned_in',
	'turned_in_not',
	'tv',
	'tv_off',
	'two_wheeler',
	'umbrella',
	'unarchive',
	'undo',
	'unfold_less',
	'unfold_more',
	'unpublished',
	'unsubscribe',
	'upcoming',
	'update',
	'update_disabled',
	'upgrade',
	'upload',
	'upload_file',
	'usb',
	'usb_off',
	'verified',
	'verified_user',
	'vertical_align_bottom',
	'vertical_align_center',
	'vertical_align_top',
	'vertical_distribute',
	'vertical_split',
	'vibration',
	'video_call',
	'video_camera_back',
	'video_camera_front',
	'video_label',
	'video_library',
	'video_settings',
	'video_stable',
	'videocam',
	'videocam_off',
	'videogame_asset',
	'videogame_asset_off',
	'view_agenda',
	'view_array',
	'view_carousel',
	'view_column',
	'view_comfy',
	'view_compact',
	'view_day',
	'view_headline',
	'view_in_ar',
	'view_list',
	'view_module',
	'view_quilt',
	'view_sidebar',
	'view_stream',
	'view_week',
	'vignette',
	'villa',
	'visibility',
	'visibility_off',
	'voice_chat',
	'voice_over_off',
	'voicemail',
	'volume_down',
	'volume_mute',
	'volume_off',
	'volume_up',
	'volunteer_activism',
	'vpn_key',
	'vpn_lock',
	'vrpano',
	'wallpaper',
	'warning',
	'warning_amber',
	'wash',
	'watch',
	'watch_later',
	'water',
	'water_damage',
	'water_drop',
	'waterfall_chart',
	'waves',
	'waving_hand',
	'wb_auto',
	'wb_cloudy',
	'wb_incandescent',
	'wb_iridescent',
	'wb_shade',
	'wb_sunny',
	'wb_twilight',
	'wc',
	'web',
	'web_asset',
	'web_asset_off',
	'weekend',
	'west',
	'whatshot',
	'wheelchair_pickup',
	'where_to_vote',
	'widgets',
	'wifi',
	'wifi_calling',
	'wifi_calling_3',
	'wifi_lock',
	'wifi_off',
	'wifi_protected_setup',
	'wifi_tethering',
	'wifi_tethering_error_rounded',
	'wifi_tethering_off',
	'window',
	'wine_bar',
	'work',
	'work_off',
	'work_outline',
	'workspaces',
	'wrap_text',
	'wrong_location',
	'wysiwyg',
	'yard',
	'youtube_searched_for',
	'zoom_in',
	'zoom_out',
	'zoom_out_map',
];
export const iconsmind = [
	'a_z',
	'aa',
	'add_bag',
	'add_basket',
	'add_cart',
	'add_file',
	'add_spaceafterparagraph',
	'add_spacebeforeparagraph',
	'add_user',
	'add_userstar',
	'add_window',
	'add',
	'address_book',
	'address_book2',
	'administrator',
	'aerobics_2',
	'aerobics_3',
	'aerobics',
	'affiliate',
	'aim',
	'air_balloon',
	'airbrush',
	'airship',
	'alarm_clock',
	'alarm_clock2',
	'alarm',
	'alien_2',
	'alien',
	'aligator',
	'align_center',
	'align_justifyall',
	'align_justifycenter',
	'align_justifyleft',
	'align_justifyright',
	'align_left',
	'align_right',
	'alpha',
	'ambulance',
	'amx',
	'anchor_2',
	'anchor',
	'android_store',
	'android',
	'angel_smiley',
	'angel',
	'angry',
	'apple_bite',
	'apple_store',
	'apple',
	'approved_window',
	'aquarius_2',
	'aquarius',
	'archery_2',
	'archery',
	'argentina',
	'aries_2',
	'aries',
	'army_key',
	'arrow_around',
	'arrow_back',
	'arrow_back2',
	'arrow_back3',
	'arrow_barrier',
	'arrow_circle',
	'arrow_cross',
	'arrow_down',
	'arrow_down2',
	'arrow_down3',
	'arrow_downincircle',
	'arrow_fork',
	'arrow_forward',
	'arrow_forward2',
	'arrow_from',
	'arrow_inside',
	'arrow_inside45',
	'arrow_insidegap',
	'arrow_insidegap45',
	'arrow_into',
	'arrow_join',
	'arrow_junction',
	'arrow_left',
	'arrow_left2',
	'arrow_leftincircle',
	'arrow_loop',
	'arrow_merge',
	'arrow_mix',
	'arrow_next',
	'arrow_outleft',
	'arrow_outright',
	'arrow_outside',
	'arrow_outside45',
	'arrow_outsidegap',
	'arrow_outsidegap45',
	'arrow_over',
	'arrow_refresh',
	'arrow_refresh2',
	'arrow_right',
	'arrow_right2',
	'arrow_rightincircle',
	'arrow_shuffle',
	'arrow_squiggly',
	'arrow_through',
	'arrow_to',
	'arrow_turnleft',
	'arrow_turnright',
	'arrow_up',
	'arrow_up2',
	'arrow_up3',
	'arrow_upincircle',
	'arrow_xleft',
	'arrow_xright',
	'ask',
	'assistant',
	'astronaut',
	'at_sign',
	'atm',
	'atom',
	'audio',
	'auto_flash',
	'autumn',
	'baby_clothes',
	'baby_clothes2',
	'baby_cry',
	'baby',
	'back_media',
	'back_music',
	'back',
	'back2',
	'background',
	'bacteria',
	'bag_coins',
	'bag_items',
	'bag_quantity',
	'bag',
	'bakelite',
	'ballet_shoes',
	'balloon',
	'banana',
	'band_aid',
	'bank',
	'bar_chart',
	'bar_chart2',
	'bar_chart3',
	'bar_chart4',
	'bar_chart5',
	'bar_code',
	'barricade_2',
	'barricade',
	'baseball',
	'basket_ball',
	'basket_coins',
	'basket_items',
	'basket_quantity',
	'bat_2',
	'bat',
	'bathrobe',
	'batman_mask',
	'battery_0',
	'battery_25',
	'battery_50',
	'battery_75',
	'battery_100',
	'battery_charge',
	'bear',
	'beard_2',
	'beard_3',
	'beard',
	'bebo',
	'bee',
	'beer_glass',
	'beer',
	'bell_2',
	'bell',
	'belt_2',
	'belt_3',
	'belt',
	'berlin_tower',
	'beta',
	'betvibes',
	'bicycle_2',
	'bicycle_3',
	'bicycle',
	'big_bang',
	'big_data',
	'bike_helmet',
	'bikini',
	'bilk_bottle2',
	'billing',
	'bing',
	'binocular',
	'bio_hazard',
	'biotech',
	'bird_deliveringletter',
	'bird',
	'birthday_cake',
	'bisexual',
	'bishop',
	'bitcoin',
	'black_cat',
	'blackboard',
	'blinklist',
	'block_cloud',
	'block_window',
	'blogger',
	'blood',
	'blouse',
	'blueprint',
	'board',
	'bodybuilding',
	'bold_text',
	'bone',
	'bones',
	'book',
	'bookmark',
	'books_2',
	'books',
	'boom',
	'boot_2',
	'boot',
	'bottom_totop',
	'bow_2',
	'bow_3',
	'bow_4',
	'bow_5',
	'bow_6',
	'bow',
	'bowling_2',
	'bowling',
	'box_close',
	'box_full',
	'box_open',
	'box_withfolders',
	'box',
	'box2',
	'boy',
	'bra',
	'brain_2',
	'brain_3',
	'brain',
	'brazil',
	'bread_2',
	'bread',
	'bridge',
	'brightkite',
	'broke_link2',
	'broken_link',
	'broom',
	'brush',
	'bucket',
	'bug',
	'building',
	'bulleted_list',
	'bus_2',
	'bus',
	'business_man',
	'business_manwoman',
	'business_mens',
	'business_woman',
	'butterfly',
	'button',
	'cable_car',
	'cake',
	'calculator_2',
	'calculator_3',
	'calculator',
	'calendar_2',
	'calendar_3',
	'calendar_4',
	'calendar_clock',
	'calendar',
	'camel',
	'camera_2',
	'camera_3',
	'camera_4',
	'camera_5',
	'camera_back',
	'camera',
	'can_2',
	'can',
	'canada',
	'cancer_2',
	'cancer_3',
	'cancer',
	'candle',
	'candy_cane',
	'candy',
	'cannon',
	'cap_2',
	'cap_3',
	'cap_smiley',
	'cap',
	'capricorn_2',
	'capricorn',
	'car_2',
	'car_3',
	'car_coins',
	'car_items',
	'car_wheel',
	'car',
	'cardigan',
	'cardiovascular',
	'cart_quantity',
	'casette_tape',
	'cash_register',
	'cash_register2',
	'castle',
	'cat',
	'cathedral',
	'cauldron',
	'cd_2',
	'cd_cover',
	'cd',
	'cello',
	'celsius',
	'chacked_flag',
	'chair',
	'charger',
	'check_2',
	'check',
	'checked_user',
	'checkmate',
	'checkout_bag',
	'checkout_basket',
	'checkout',
	'cheese',
	'cheetah',
	'chef_hat',
	'chef_hat2',
	'chef',
	'chemical_2',
	'chemical_3',
	'chemical_4',
	'chemical_5',
	'chemical',
	'chess_board',
	'chess',
	'chicken',
	'chile',
	'chimney',
	'china',
	'chinese_temple',
	'chip',
	'chopsticks_2',
	'chopsticks',
	'christmas_ball',
	'christmas_bell',
	'christmas_candle',
	'christmas_hat',
	'christmas_sleigh',
	'christmas_snowman',
	'christmas_sock',
	'christmas_tree',
	'christmas',
	'chrome',
	'chrysler_building',
	'cinema',
	'circular_point',
	'city_hall',
	'clamp',
	'clapperboard_close',
	'clapperboard_open',
	'claps',
	'clef',
	'clinic',
	'clock_2',
	'clock_3',
	'clock_4',
	'clock_back',
	'clock_forward',
	'clock',
	'close_window',
	'close',
	'clothing_store',
	'cloud_',
	'cloud_camera',
	'cloud_computer',
	'cloud_email',
	'cloud_hail',
	'cloud_laptop',
	'cloud_lock',
	'cloud_minus',
	'cloud_moon',
	'cloud_music',
	'cloud_picture',
	'cloud_rain',
	'cloud_remove',
	'cloud_secure',
	'cloud_settings',
	'cloud_smartphone',
	'cloud_snow',
	'cloud_sun',
	'cloud_tablet',
	'cloud_video',
	'cloud_weather',
	'cloud',
	'clouds_weather',
	'clouds',
	'clown',
	'cmyk',
	'coat',
	'cocktail',
	'coconut',
	'code_window',
	'coding',
	'coffee_2',
	'coffee_bean',
	'coffee_machine',
	'coffee_togo',
	'coffee',
	'coffin',
	'coin',
	'coins_2',
	'coins_3',
	'coins',
	'colombia',
	'colosseum',
	'column_2',
	'column_3',
	'column',
	'comb_2',
	'comb',
	'communication_tower',
	'communication_tower2',
	'compass_2',
	'compass_3',
	'compass_4',
	'compass_rose',
	'compass',
	'computer_2',
	'computer_3',
	'computer_secure',
	'computer',
	'conference',
	'confused',
	'conservation',
	'consulting',
	'contrast',
	'control_2',
	'control',
	'cookie_man',
	'cookies',
	'cool_guy',
	'cool',
	'copyright',
	'costume',
	'couple_sign',
	'cow',
	'cpu',
	'crane',
	'cranium',
	'credit_card',
	'credit_card2',
	'credit_card3',
	'cricket',
	'criminal',
	'croissant',
	'crop_2',
	'crop_3',
	'crown_2',
	'crown',
	'crying',
	'cube_molecule',
	'cube_molecule2',
	'cupcake',
	'cursor_click',
	'cursor_click2',
	'cursor_move',
	'cursor_move2',
	'cursor_select',
	'cursor',
	'd_eyeglasses',
	'd_eyeglasses2',
	'dam',
	'danemark',
	'danger_2',
	'danger',
	'dashboard',
	'data_backup',
	'data_block',
	'data_center',
	'data_clock',
	'data_cloud',
	'data_compress',
	'data_copy',
	'data_download',
	'data_financial',
	'data_key',
	'data_lock',
	'data_network',
	'data_password',
	'data_power',
	'data_refresh',
	'data_save',
	'data_search',
	'data_security',
	'data_settings',
	'data_sharing',
	'data_shield',
	'data_signal',
	'data_storage',
	'data_stream',
	'data_transfer',
	'data_unlock',
	'data_upload',
	'data_yes',
	'data',
	'david_star',
	'daylight',
	'death',
	'debian',
	'dec',
	'decrase_inedit',
	'deer_2',
	'deer',
	'delete_file',
	'delete_window',
	'delicious',
	'depression',
	'deviantart',
	'device_syncwithcloud',
	'diamond',
	'dice_2',
	'dice',
	'digg',
	'digital_drawing',
	'diigo',
	'dinosaur',
	'diploma_2',
	'diploma',
	'direction_east',
	'direction_north',
	'direction_south',
	'direction_west',
	'director',
	'disk',
	'dj',
	'dna_2',
	'dna_helix',
	'dna',
	'doctor',
	'dog',
	'dollar_sign',
	'dollar_sign2',
	'dollar',
	'dolphin',
	'domino',
	'door_hanger',
	'door',
	'doplr',
	'double_circle',
	'double_tap',
	'doughnut',
	'dove',
	'down_2',
	'down_3',
	'down_4',
	'down',
	'download_2',
	'download_fromcloud',
	'download_window',
	'download',
	'downward',
	'drag_down',
	'drag_left',
	'drag_right',
	'drag_up',
	'drag',
	'dress',
	'drill_2',
	'drill',
	'drop',
	'dropbox',
	'drum',
	'dry',
	'duck',
	'dumbbell',
	'duplicate_layer',
	'duplicate_window',
	'dvd',
	'eagle',
	'ear',
	'earphones_2',
	'earphones',
	'eci_icon',
	'edit_map',
	'edit',
	'eggs',
	'egypt',
	'eifel_tower',
	'eject_2',
	'eject',
	'el_castillo',
	'elbow',
	'electric_guitar',
	'electricity',
	'elephant',
	'email',
	'embassy',
	'empire_statebuilding',
	'empty_box',
	'end_2',
	'end',
	'end2',
	'endways',
	'engineering',
	'envelope_2',
	'envelope',
	'environmental_2',
	'environmental_3',
	'environmental',
	'equalizer',
	'eraser_2',
	'eraser_3',
	'eraser',
	'error_404window',
	'euro_sign',
	'euro_sign2',
	'euro',
	'evernote',
	'evil',
	'explode',
	'eye_2',
	'eye_blind',
	'eye_invisible',
	'eye_scan',
	'eye_visible',
	'eye',
	'eyebrow_2',
	'eyebrow_3',
	'eyebrow',
	'eyeglasses_smiley',
	'eyeglasses_smiley2',
	'face_style',
	'face_style2',
	'face_style3',
	'face_style4',
	'face_style5',
	'face_style6',
	'facebook_2',
	'facebook',
	'factory_2',
	'factory',
	'fahrenheit',
	'family_sign',
	'fan',
	'farmer',
	'fashion',
	'favorite_window',
	'fax',
	'feather',
	'feedburner',
	'female_2',
	'female_sign',
	'female',
	'file_block',
	'file_bookmark',
	'file_chart',
	'file_clipboard',
	'file_clipboardfiletext',
	'file_clipboardtextimage',
	'file_cloud',
	'file_copy',
	'file_copy2',
	'file_csv',
	'file_download',
	'file_edit',
	'file_excel',
	'file_favorite',
	'file_fire',
	'file_graph',
	'file_hide',
	'file_horizontal',
	'file_horizontaltext',
	'file_html',
	'file_jpg',
	'file_link',
	'file_loading',
	'file_lock',
	'file_love',
	'file_music',
	'file_network',
	'file_pictures',
	'file_pie',
	'file_presentation',
	'file_refresh',
	'file_search',
	'file_settings',
	'file_share',
	'file_textimage',
	'file_trash',
	'file_txt',
	'file_upload',
	'file_video',
	'file_word',
	'file_zip',
	'file',
	'files',
	'film_board',
	'film_cartridge',
	'film_strip',
	'film_video',
	'film',
	'filter_2',
	'filter',
	'financial',
	'find_user',
	'finger_dragfoursides',
	'finger_dragtwosides',
	'finger_print',
	'finger',
	'fingerprint_2',
	'fingerprint',
	'fire_flame',
	'fire_flame2',
	'fire_hydrant',
	'fire_staion',
	'firefox',
	'firewall',
	'first_aid',
	'first',
	'fish_food',
	'fish',
	'fit_to',
	'fit_to2',
	'five_fingers',
	'five_fingersdrag',
	'five_fingersdrag2',
	'five_fingerstouch',
	'flag_2',
	'flag_3',
	'flag_4',
	'flag_5',
	'flag_6',
	'flag',
	'flamingo',
	'flash_2',
	'flash_video',
	'flash',
	'flashlight',
	'flask_2',
	'flask',
	'flick',
	'flickr',
	'flowerpot',
	'fluorescent',
	'fog_day',
	'fog_night',
	'folder_add',
	'folder_archive',
	'folder_binder',
	'folder_binder2',
	'folder_block',
	'folder_bookmark',
	'folder_close',
	'folder_cloud',
	'folder_delete',
	'folder_download',
	'folder_edit',
	'folder_favorite',
	'folder_fire',
	'folder_hide',
	'folder_link',
	'folder_loading',
	'folder_lock',
	'folder_love',
	'folder_music',
	'folder_network',
	'folder_open',
	'folder_open2',
	'folder_organizing',
	'folder_pictures',
	'folder_refresh',
	'folder_remove',
	'folder_search',
	'folder_settings',
	'folder_share',
	'folder_trash',
	'folder_upload',
	'folder_video',
	'folder_withdocument',
	'folder_zip',
	'folder',
	'folders',
	'font_color',
	'font_name',
	'font_size',
	'font_style',
	'font_stylesubscript',
	'font_stylesuperscript',
	'font_window',
	'foot_2',
	'foot',
	'football_2',
	'football',
	'footprint_2',
	'footprint_3',
	'footprint',
	'forest',
	'fork',
	'formspring',
	'formula',
	'forsquare',
	'forward',
	'fountain_pen',
	'four_fingers',
	'four_fingersdrag',
	'four_fingersdrag2',
	'four_fingerstouch',
	'fox',
	'frankenstein',
	'french_fries',
	'friendfeed',
	'friendster',
	'frog',
	'fruits',
	'fuel',
	'full_bag',
	'full_basket',
	'full_cart',
	'full_moon',
	'full_screen',
	'full_screen2',
	'full_view',
	'full_view2',
	'full_viewwindow',
	'function',
	'funky',
	'funny_bicycle',
	'furl',
	'gamepad_2',
	'gamepad',
	'gas_pump',
	'gaugage_2',
	'gaugage',
	'gay',
	'gear_2',
	'gear',
	'gears_2',
	'gears',
	'geek_2',
	'geek',
	'gemini_2',
	'gemini',
	'genius',
	'gentleman',
	'geo_',
	'geo_close',
	'geo_love',
	'geo_minus',
	'geo_number',
	'geo_star',
	'geo',
	'geo2_',
	'geo2_close',
	'geo2_love',
	'geo2_minus',
	'geo2_number',
	'geo2_star',
	'geo2',
	'geo3_',
	'geo3_close',
	'geo3_love',
	'geo3_minus',
	'geo3_number',
	'geo3_star',
	'geo3',
	'gey',
	'gift_box',
	'giraffe',
	'girl',
	'glass_water',
	'glasses_2',
	'glasses_3',
	'glasses',
	'global_position',
	'globe_2',
	'globe',
	'gloves',
	'go_bottom',
	'go_top',
	'goggles',
	'golf_2',
	'golf',
	'google_buzz',
	'google_drive',
	'google_play',
	'google_plus',
	'google',
	'gopro',
	'gorilla',
	'gowalla',
	'grave',
	'graveyard',
	'greece',
	'green_energy',
	'green_house',
	'guitar',
	'gun_2',
	'gun_3',
	'gun',
	'gymnastics',
	'hair_2',
	'hair_3',
	'hair_4',
	'hair',
	'half_moon',
	'halloween_halfmoon',
	'halloween_moon',
	'hamburger',
	'hammer',
	'hand_touch',
	'hand_touch2',
	'hand_touchsmartphone',
	'hand',
	'hands',
	'handshake',
	'hanger',
	'happy',
	'hat_2',
	'hat',
	'haunted_house',
	'hd_video',
	'hd',
	'hdd',
	'headphone',
	'headphones',
	'headset',
	'heart_2',
	'heart',
	'heels_2',
	'heels',
	'height_window',
	'helicopter_2',
	'helicopter',
	'helix_2',
	'hello',
	'helmet_2',
	'helmet_3',
	'helmet',
	'hipo',
	'hipster_glasses',
	'hipster_glasses2',
	'hipster_glasses3',
	'hipster_headphones',
	'hipster_men',
	'hipster_men2',
	'hipster_men3',
	'hipster_sunglasses',
	'hipster_sunglasses2',
	'hipster_sunglasses3',
	'hokey',
	'holly',
	'home_2',
	'home_3',
	'home_4',
	'home_5',
	'home_window',
	'home',
	'homosexual',
	'honey',
	'hong_kong',
	'hoodie',
	'horror',
	'horse',
	'hospital_2',
	'hospital',
	'host',
	'hot_dog',
	'hotel',
	'hour',
	'hub',
	'humor',
	'hurt',
	'ice_cream',
	'icq',
	'id_2',
	'id_3',
	'id_card',
	'idea_2',
	'idea_3',
	'idea_4',
	'idea_5',
	'idea',
	'identification_badge',
	'imdb',
	'inbox_empty',
	'inbox_forward',
	'inbox_full',
	'inbox_into',
	'inbox_out',
	'inbox_reply',
	'inbox',
	'increase_inedit',
	'indent_firstline',
	'indent_leftmargin',
	'indent_rightmargin',
	'india',
	'info_window',
	'information',
	'inifity',
	'instagram',
	'internet_2',
	'internet_explorer',
	'internet_smiley',
	'internet',
	'ios_apple',
	'israel',
	'italic_text',
	'jacket_2',
	'jacket',
	'jamaica',
	'japan',
	'japanese_gate',
	'jeans',
	'jeep_2',
	'jeep',
	'jet',
	'joystick',
	'juice',
	'jump_rope',
	'kangoroo',
	'kenya',
	'key_2',
	'key_3',
	'key_lock',
	'key',
	'keyboard',
	'keyboard3',
	'keypad',
	'king_2',
	'king',
	'kiss',
	'knee',
	'knife_2',
	'knife',
	'knight',
	'koala',
	'korea',
	'lamp',
	'landscape_2',
	'landscape',
	'lantern',
	'laptop_2',
	'laptop_3',
	'laptop_phone',
	'laptop_secure',
	'laptop_tablet',
	'laptop',
	'laser',
	'last_fm',
	'last',
	'laughing',
	'layer_1635',
	'layer_1646',
	'layer_backward',
	'layer_forward',
	'leafs_2',
	'leafs',
	'leaning_tower',
	'left__right',
	'left__right3',
	'left_2',
	'left_3',
	'left_4',
	'left_toright',
	'left',
	'leg_2',
	'leg',
	'lego',
	'lemon',
	'len_2',
	'len_3',
	'len',
	'leo_2',
	'leo',
	'leopard',
	'lesbian',
	'lesbians',
	'letter_close',
	'letter_open',
	'letter_sent',
	'libra_2',
	'libra',
	'library_2',
	'library',
	'life_jacket',
	'life_safer',
	'light_bulb',
	'light_bulb2',
	'light_bulbleaf',
	'lighthouse',
	'like_2',
	'like',
	'line_chart',
	'line_chart2',
	'line_chart3',
	'line_chart4',
	'line_spacing',
	'line_spacingtext',
	'link_2',
	'link',
	'linkedin_2',
	'linkedin',
	'linux',
	'lion',
	'livejournal',
	'loading_2',
	'loading_3',
	'loading_window',
	'loading',
	'location_2',
	'location',
	'lock_2',
	'lock_3',
	'lock_user',
	'lock_window',
	'lock',
	'lollipop_2',
	'lollipop_3',
	'lollipop',
	'loop',
	'loud',
	'loudspeaker',
	'love_2',
	'love_user',
	'love_window',
	'love',
	'lowercase_text',
	'luggafe_front',
	'luggage_2',
	'macro',
	'magic_wand',
	'magnet',
	'magnifi_glass_minus',
	'magnifi_glass',
	'magnifi_glass2',
	'mail_2',
	'mail_3',
	'mail_add',
	'mail_attachement',
	'mail_block',
	'mail_delete',
	'mail_favorite',
	'mail_forward',
	'mail_gallery',
	'mail_inbox',
	'mail_link',
	'mail_lock',
	'mail_love',
	'mail_money',
	'mail_open',
	'mail_outbox',
	'mail_password',
	'mail_photo',
	'mail_read',
	'mail_removex',
	'mail_reply',
	'mail_replyall',
	'mail_search',
	'mail_send',
	'mail_settings',
	'mail_unread',
	'mail_video',
	'mail_withatsign',
	'mail_withcursors',
	'mail',
	'mailbox_empty',
	'mailbox_full',
	'male_2',
	'male_sign',
	'male',
	'malefemale',
	'man_sign',
	'management',
	'mans_underwear',
	'mans_underwear2',
	'map_marker',
	'map_marker2',
	'map_marker3',
	'map',
	'map2',
	'marker_2',
	'marker_3',
	'marker',
	'martini_glass',
	'mask',
	'master_card',
	'maximize_window',
	'maximize',
	'medal_2',
	'medal_3',
	'medal',
	'medical_sign',
	'medicine_2',
	'medicine_3',
	'medicine',
	'megaphone',
	'memory_card',
	'memory_card2',
	'memory_card3',
	'men',
	'menorah',
	'mens',
	'metacafe',
	'mexico',
	'mic',
	'microphone_2',
	'microphone_3',
	'microphone_4',
	'microphone_5',
	'microphone_6',
	'microphone_7',
	'microphone',
	'microscope',
	'milk_bottle',
	'mine',
	'minimize_maximize_close_window',
	'minimize_window',
	'minimize',
	'mirror',
	'mixer',
	'mixx',
	'money_2',
	'money_bag',
	'money_smiley',
	'money',
	'monitor_2',
	'monitor_3',
	'monitor_4',
	'monitor_5',
	'monitor_analytics',
	'monitor_laptop',
	'monitor_phone',
	'monitor_tablet',
	'monitor_vertical',
	'monitor',
	'monitoring',
	'monkey',
	'monster',
	'morocco',
	'motorcycle',
	'mouse_2',
	'mouse_3',
	'mouse_4',
	'mouse_pointer',
	'mouse',
	'moustache_smiley',
	'movie_ticket',
	'movie',
	'mp3_file',
	'museum',
	'mushroom',
	'music_note',
	'music_note2',
	'music_note3',
	'music_note4',
	'music_player',
	'mustache_2',
	'mustache_3',
	'mustache_4',
	'mustache_5',
	'mustache_6',
	'mustache_7',
	'mustache_8',
	'mustache',
	'mute',
	'myspace',
	'navigat_start',
	'navigate_end',
	'navigation_leftwindow',
	'navigation_rightwindow',
	'nepal',
	'netscape',
	'network_window',
	'network',
	'neutron',
	'new_mail',
	'new_tab',
	'newspaper_2',
	'newspaper',
	'newsvine',
	'next_3',
	'next_music',
	'next',
	'next2',
	'no_battery',
	'no_drop',
	'no_flash',
	'no_smoking',
	'noose',
	'normal_text',
	'note',
	'notepad_2',
	'notepad',
	'nuclear',
	'numbering_list',
	'nurse',
	'office_lamp',
	'office',
	'oil',
	'old_camera',
	'old_cassette',
	'old_clock',
	'old_radio',
	'old_sticky',
	'old_sticky2',
	'old_telephone',
	'old_tv',
	'on_air',
	'on_off_2',
	'on_off_3',
	'on_off',
	'one_finger',
	'one_fingertouch',
	'one_window',
	'open_banana',
	'open_book',
	'opera_house',
	'opera',
	'optimization',
	'orientation_2',
	'orientation_3',
	'orientation',
	'orkut',
	'ornament',
	'over_time',
	'over_time2',
	'owl',
	'pac_man',
	'paint_brush',
	'paint_bucket',
	'paintbrush',
	'palette',
	'palm_tree',
	'panda',
	'panorama',
	'pantheon',
	'pantone',
	'pants',
	'paper_plane',
	'paper',
	'parasailing',
	'parrot',
	'password_2shopping',
	'password_field',
	'password_shopping',
	'password',
	'pause_2',
	'pause',
	'paw',
	'pawn',
	'paypal',
	'pen_2',
	'pen_3',
	'pen_4',
	'pen_5',
	'pen_6',
	'pen',
	'pencil_ruler',
	'pencil',
	'penguin',
	'pentagon',
	'people_oncloud',
	'pepper_withfire',
	'pepper',
	'petrol',
	'petronas_tower',
	'philipines',
	'phone_2',
	'phone_3',
	'phone_3g',
	'phone_4g',
	'phone_simcard',
	'phone_sms',
	'phone_wifi',
	'phone',
	'photo_2',
	'photo_3',
	'photo_album',
	'photo_album2',
	'photo_album3',
	'photo',
	'photos',
	'physics',
	'pi',
	'piano',
	'picasa',
	'pie_chart',
	'pie_chart2',
	'pie_chart3',
	'pilates_2',
	'pilates_3',
	'pilates',
	'pilot',
	'pinch',
	'ping_pong',
	'pinterest',
	'pipe',
	'pipette',
	'piramids',
	'pisces_2',
	'pisces',
	'pizza_slice',
	'pizza',
	'plane_2',
	'plane',
	'plant',
	'plasmid',
	'plaster',
	'plastic_cupphone',
	'plastic_cupphone2',
	'plate',
	'plates',
	'plaxo',
	'play_music',
	'plug_in',
	'plug_in2',
	'plurk',
	'pointer',
	'poland',
	'police_man',
	'police_station',
	'police_woman',
	'police',
	'polo_shirt',
	'portrait',
	'portugal',
	'post_mail',
	'post_mail2',
	'post_office',
	'post_sign',
	'post_sign2ways',
	'posterous',
	'pound_sign',
	'pound_sign2',
	'pound',
	'power_2',
	'power_3',
	'power_cable',
	'power_station',
	'power',
	'prater',
	'present',
	'presents',
	'press',
	'preview',
	'previous',
	'pricing',
	'printer',
	'professor',
	'profile',
	'project',
	'projector_2',
	'projector',
	'pulse',
	'pumpkin',
	'punk',
	'punker',
	'puzzle',
	'qik',
	'qr_code',
	'queen_2',
	'queen',
	'quill_2',
	'quill_3',
	'quill',
	'quotes_2',
	'quotes',
	'radio',
	'radioactive',
	'rafting',
	'rain_drop',
	'rainbow_2',
	'rainbow',
	'ram',
	'razzor_blade',
	'receipt_2',
	'receipt_3',
	'receipt_4',
	'receipt',
	'record_3',
	'record_music',
	'record',
	'record2',
	'recycling_2',
	'recycling',
	'reddit',
	'redhat',
	'redirect',
	'redo',
	'reel',
	'refinery',
	'refresh_window',
	'refresh',
	'reload_2',
	'reload_3',
	'reload',
	'remote_controll',
	'remote_controll2',
	'remove_bag',
	'remove_basket',
	'remove_cart',
	'remove_file',
	'remove_user',
	'remove_window',
	'remove',
	'rename',
	'repair',
	'repeat_2',
	'repeat_3',
	'repeat_4',
	'repeat_5',
	'repeat_6',
	'repeat_7',
	'repeat',
	'reset',
	'resize',
	'restore_window',
	'retouching',
	'retro_camera',
	'retro',
	'retweet',
	'reverbnation',
	'rewind',
	'rgb',
	'ribbon_2',
	'ribbon_3',
	'ribbon',
	'right_2',
	'right_3',
	'right_4',
	'right_toleft',
	'right',
	'road_2',
	'road_3',
	'road',
	'robot_2',
	'robot',
	'rock_androll',
	'rocket',
	'roller',
	'roof',
	'rook',
	'rotate_gesture',
	'rotate_gesture2',
	'rotate_gesture3',
	'rotation_390',
	'rotation',
	'router_2',
	'router',
	'rss',
	'ruler_2',
	'ruler',
	'running_shoes',
	'running',
	'safari',
	'safe_box',
	'safe_box2',
	'safety_pinclose',
	'safety_pinopen',
	'sagittarus_2',
	'sagittarus',
	'sailing_ship',
	'sand_watch',
	'sand_watch2',
	'santa_claus',
	'santa_claus2',
	'santa_onsled',
	'satelite_2',
	'satelite',
	'save_window',
	'save',
	'saw',
	'saxophone',
	'scale',
	'scarf',
	'scissor',
	'scooter_front',
	'scooter',
	'scorpio_2',
	'scorpio',
	'scotland',
	'screwdriver',
	'scroll_fast',
	'scroll',
	'scroller_2',
	'scroller',
	'sea_dog',
	'search_oncloud',
	'search_people',
	'secound',
	'secound2',
	'security_block',
	'security_bug',
	'security_camera',
	'security_check',
	'security_settings',
	'security_smiley',
	'securiy_remove',
	'seed',
	'selfie',
	'serbia',
	'server_2',
	'server',
	'servers',
	'settings_window',
	'sewing_machine',
	'sexual',
	'share_oncloud',
	'share_window',
	'share',
	'sharethis',
	'shark',
	'sheep',
	'sheriff_badge',
	'shield',
	'ship_2',
	'ship',
	'shirt',
	'shoes_2',
	'shoes_3',
	'shoes',
	'shop_2',
	'shop_3',
	'shop_4',
	'shop',
	'shopping_bag',
	'shopping_basket',
	'shopping_cart',
	'short_pants',
	'shoutwire',
	'shovel',
	'shuffle_2',
	'shuffle_3',
	'shuffle_4',
	'shuffle',
	'shutter',
	'sidebar_window',
	'signal',
	'singapore',
	'skate_shoes',
	'skateboard_2',
	'skateboard',
	'skeleton',
	'ski',
	'skirt',
	'skrill',
	'skull',
	'skydiving',
	'skype',
	'sled_withgifts',
	'sled',
	'sleeping',
	'sleet',
	'slippers',
	'smart',
	'smartphone_2',
	'smartphone_3',
	'smartphone_4',
	'smartphone_secure',
	'smartphone',
	'smile',
	'smoking_area',
	'smoking_pipe',
	'snake',
	'snorkel',
	'snow_2',
	'snow_dome',
	'snow_storm',
	'snow',
	'snowflake_2',
	'snowflake_3',
	'snowflake_4',
	'snowflake',
	'snowman',
	'soccer_ball',
	'soccer_shoes',
	'socks',
	'solar',
	'sound_wave',
	'sound',
	'soundcloud',
	'soup',
	'south_africa',
	'space_needle',
	'spain',
	'spam_mail',
	'speach_bubble',
	'speach_bubble2',
	'speach_bubble3',
	'speach_bubble4',
	'speach_bubble5',
	'speach_bubble6',
	'speach_bubble7',
	'speach_bubble8',
	'speach_bubble9',
	'speach_bubble10',
	'speach_bubble11',
	'speach_bubble12',
	'speach_bubble13',
	'speach_bubbleasking',
	'speach_bubblecomic',
	'speach_bubblecomic2',
	'speach_bubblecomic3',
	'speach_bubblecomic4',
	'speach_bubbledialog',
	'speach_bubbles',
	'speak_2',
	'speak',
	'speaker_2',
	'speaker',
	'spell_check',
	'spell_checkabc',
	'spermium',
	'spider',
	'spiderweb',
	'split_foursquarewindow',
	'split_horizontal',
	'split_horizontal2window',
	'split_vertical',
	'split_vertical2',
	'split_window',
	'spoder',
	'spoon',
	'sport_mode',
	'sports_clothings1',
	'sports_clothings2',
	'sports_shirt',
	'spot',
	'spray',
	'spread',
	'spring',
	'spurl',
	'spy',
	'squirrel',
	'ssl',
	'st_basilscathedral',
	'st_paulscathedral',
	'stamp_2',
	'stamp',
	'stapler',
	'star_track',
	'star',
	'starfish',
	'start_3',
	'start_ways',
	'start',
	'start2',
	'statistic',
	'stethoscope',
	'stop__2',
	'stop_music',
	'stop',
	'stopwatch_2',
	'stopwatch',
	'storm',
	'street_view',
	'street_view2',
	'strikethrough_text',
	'stroller',
	'structure',
	'student_female',
	'student_hat',
	'student_hat2',
	'student_male',
	'student_malefemale',
	'students',
	'studio_flash',
	'studio_lightbox',
	'stumbleupon',
	'suit',
	'suitcase',
	'sum_2',
	'sum',
	'summer',
	'sun_cloudyrain',
	'sun',
	'sunglasses_2',
	'sunglasses_3',
	'sunglasses_smiley',
	'sunglasses_smiley2',
	'sunglasses_w',
	'sunglasses_w2',
	'sunglasses_w3',
	'sunglasses',
	'sunrise',
	'sunset',
	'superman',
	'support',
	'surprise',
	'sushi',
	'sweden',
	'swimming_short',
	'swimming',
	'swimmwear',
	'switch',
	'switzerland',
	'sync_cloud',
	'sync',
	'synchronize_2',
	'synchronize',
	't_shirt',
	'tablet_2',
	'tablet_3',
	'tablet_orientation',
	'tablet_phone',
	'tablet_secure',
	'tablet_vertical',
	'tablet',
	'tactic',
	'tag_2',
	'tag_3',
	'tag_4',
	'tag_5',
	'tag',
	'taj_mahal',
	'talk_man',
	'tap',
	'target_market',
	'target',
	'taurus_2',
	'taurus',
	'taxi_2',
	'taxi_sign',
	'taxi',
	'teacher',
	'teapot',
	'technorati',
	'teddy_bear',
	'tee_mug',
	'telephone_2',
	'telephone',
	'telescope',
	'temperature_2',
	'temperature_3',
	'temperature',
	'temple',
	'tennis_ball',
	'tennis',
	'tent',
	'test_tube',
	'test_tube2',
	'testimonal',
	'text_box',
	'text_effect',
	'text_highlightcolor',
	'text_paragraph',
	'thailand',
	'the_whitehouse',
	'this_sideup',
	'thread',
	'three_arrowfork',
	'three_fingers',
	'three_fingersdrag',
	'three_fingersdrag2',
	'three_fingerstouch',
	'thumb',
	'thumbs_downsmiley',
	'thumbs_upsmiley',
	'thunder',
	'thunderstorm',
	'ticket',
	'tie_2',
	'tie_3',
	'tie_4',
	'tie',
	'tiger',
	'time_backup',
	'time_bomb',
	'time_clock',
	'time_fire',
	'time_machine',
	'time_window',
	'timer_2',
	'timer',
	'to_bottom',
	'to_bottom2',
	'to_left',
	'to_right',
	'to_top',
	'to_top2',
	'token_',
	'tomato',
	'tongue',
	'tooth_2',
	'tooth',
	'top_tobottom',
	'touch_window',
	'tourch',
	'tower_2',
	'tower_bridge',
	'tower',
	'trace',
	'tractor',
	'traffic_light',
	'traffic_light2',
	'train_2',
	'train',
	'tram',
	'transform_2',
	'transform_3',
	'transform_4',
	'transform',
	'trash_withmen',
	'tree_2',
	'tree_3',
	'tree_4',
	'tree_5',
	'tree',
	'trekking',
	'triangle_arrowdown',
	'triangle_arrowleft',
	'triangle_arrowright',
	'triangle_arrowup',
	'tripod_2',
	'tripod_andvideo',
	'tripod_withcamera',
	'tripod_withgopro',
	'trophy_2',
	'trophy',
	'truck',
	'trumpet',
	'tumblr',
	'turkey',
	'turn_down',
	'turn_down2',
	'turn_downfromleft',
	'turn_downfromright',
	'turn_left',
	'turn_left3',
	'turn_right',
	'turn_right3',
	'turn_up',
	'turn_up2',
	'turtle',
	'tuxedo',
	'tv',
	'twister',
	'twitter_2',
	'twitter',
	'two_fingers',
	'two_fingersdrag',
	'two_fingersdrag2',
	'two_fingersscroll',
	'two_fingerstouch',
	'two_windows',
	'type_pass',
	'ukraine',
	'umbrela',
	'umbrella_2',
	'umbrella_3',
	'under_linetext',
	'undo',
	'united_kingdom',
	'united_states',
	'university_2',
	'university',
	'unlike_2',
	'unlike',
	'unlock_2',
	'unlock_3',
	'unlock',
	'up__down',
	'up__down3',
	'up_2',
	'up_3',
	'up_4',
	'up',
	'upgrade',
	'upload_2',
	'upload_tocloud',
	'upload_window',
	'upload',
	'uppercase_text',
	'upward',
	'url_window',
	'usb_2',
	'usb_cable',
	'usb',
	'user',
	'ustream',
	'vase',
	'vector_2',
	'vector_3',
	'vector_4',
	'vector_5',
	'vector',
	'venn_diagram',
	'vest_2',
	'vest',
	'viddler',
	'video_2',
	'video_3',
	'video_4',
	'video_5',
	'video_6',
	'video_gamecontroller',
	'video_len',
	'video_len2',
	'video_photographer',
	'video_tripod',
	'video',
	'vietnam',
	'view_height',
	'view_width',
	'vimeo',
	'virgo_2',
	'virgo',
	'virus_2',
	'virus_3',
	'virus',
	'visa',
	'voice',
	'voicemail',
	'volleyball',
	'volume_down',
	'volume_up',
	'vpn',
	'wacom_tablet',
	'waiter',
	'walkie_talkie',
	'wallet_2',
	'wallet_3',
	'wallet',
	'warehouse',
	'warning_window',
	'watch_2',
	'watch_3',
	'watch',
	'wave_2',
	'wave',
	'webcam',
	'weight_lift',
	'wheelbarrow',
	'wheelchair',
	'width_window',
	'wifi_2',
	'wifi_keyboard',
	'wifi',
	'wind_turbine',
	'windmill',
	'window_2',
	'window',
	'windows_2',
	'windows_microsoft',
	'windows',
	'windsock',
	'windy',
	'wine_bottle',
	'wine_glass',
	'wink',
	'winter_2',
	'winter',
	'wireless',
	'witch_hat',
	'witch',
	'wizard',
	'wolf',
	'woman_sign',
	'womanman',
	'womans_underwear',
	'womans_underwear2',
	'women',
	'wonder_woman',
	'wordpress',
	'worker_clothes',
	'worker',
	'wrap_text',
	'wreath',
	'wrench',
	'x_box',
	'x_ray',
	'xanga',
	'xing',
	'yacht',
	'yahoo_buzz',
	'yahoo',
	'yelp',
	'yes',
	'ying_yang',
	'youtube',
	'z_a',
	'zebra',
	'zombie',
	'zoom_gesture',
	'zootool',
];
export const feather = [
	'activity',
	'airplay',
	'alert-circle',
	'alert-octagon',
	'alert-triangle',
	'align-center',
	'align-justify',
	'align-left',
	'align-right',
	'anchor',
	'aperture',
	'archive',
	'arrow-down-circle',
	'arrow-down-left',
	'arrow-down-right',
	'arrow-down',
	'arrow-left-circle',
	'arrow-left',
	'arrow-right-circle',
	'arrow-right',
	'arrow-up-circle',
	'arrow-up-left',
	'arrow-up-right',
	'arrow-up',
	'at-sign',
	'award',
	'bar-chart-2',
	'bar-chart',
	'battery-charging',
	'battery',
	'bell-off',
	'bell',
	'bluetooth',
	'bold',
	'book-open',
	'book',
	'bookmark',
	'box',
	'briefcase',
	'calendar',
	'camera-off',
	'camera',
	'cast',
	'check-circle',
	'check-square',
	'check',
	'chevron-down',
	'chevron-left',
	'chevron-right',
	'chevron-up',
	'chevrons-down',
	'chevrons-left',
	'chevrons-right',
	'chevrons-up',
	'chrome',
	'circle',
	'clipboard',
	'clock',
	'cloud-drizzle',
	'cloud-lightning',
	'cloud-off',
	'cloud-rain',
	'cloud-snow',
	'cloud',
	'code',
	'codepen',
	'codesandbox',
	'coffee',
	'columns',
	'command',
	'compass',
	'copy',
	'corner-down-left',
	'corner-down-right',
	'corner-left-down',
	'corner-left-up',
	'corner-right-down',
	'corner-right-up',
	'corner-up-left',
	'corner-up-right',
	'cpu',
	'credit-card',
	'crop',
	'crosshair',
	'database',
	'delete',
	'disc',
	'dollar-sign',
	'download-cloud',
	'download',
	'droplet',
	'edit-2',
	'edit-3',
	'edit',
	'external-link',
	'eye-off',
	'eye',
	'facebook',
	'fast-forward',
	'feather',
	'figma',
	'file-minus',
	'file-plus',
	'file-text',
	'file',
	'film',
	'filter',
	'flag',
	'folder-minus',
	'folder-plus',
	'folder',
	'framer',
	'frown',
	'gift',
	'git-branch',
	'git-commit',
	'git-merge',
	'git-pull-request',
	'github',
	'gitlab',
	'globe',
	'grid',
	'hard-drive',
	'hash',
	'headphones',
	'heart',
	'help-circle',
	'hexagon',
	'home',
	'image',
	'inbox',
	'info',
	'instagram',
	'italic',
	'key',
	'layers',
	'layout',
	'life-buoy',
	'link-2',
	'link',
	'linkedin',
	'list',
	'loader',
	'lock',
	'log-in',
	'log-out',
	'mail',
	'map-pin',
	'map',
	'maximize-2',
	'maximize',
	'meh',
	'menu',
	'message-circle',
	'message-square',
	'mic-off',
	'mic',
	'minimize-2',
	'minimize',
	'minus-circle',
	'minus-square',
	'minus',
	'monitor',
	'moon',
	'more-horizontal',
	'more-vertical',
	'mouse-pointer',
	'move',
	'music',
	'navigation-2',
	'navigation',
	'octagon',
	'package',
	'paperclip',
	'pause-circle',
	'pause',
	'pen-tool',
	'percent',
	'phone-call',
	'phone-forwarded',
	'phone-incoming',
	'phone-missed',
	'phone-off',
	'phone-outgoing',
	'phone',
	'pie-chart',
	'play-circle',
	'play',
	'plus-circle',
	'plus-square',
	'plus',
	'pocket',
	'power',
	'printer',
	'radio',
	'refresh-ccw',
	'refresh-cw',
	'repeat',
	'rewind',
	'rotate-ccw',
	'rotate-cw',
	'rss',
	'save',
	'scissors',
	'search',
	'send',
	'server',
	'settings',
	'share-2',
	'share',
	'shield-off',
	'shield',
	'shopping-bag',
	'shopping-cart',
	'shuffle',
	'sidebar',
	'skip-back',
	'skip-forward',
	'slack',
	'slash',
	'sliders',
	'smartphone',
	'smile',
	'speaker',
	'square',
	'star',
	'stop-circle',
	'sun',
	'sunrise',
	'sunset',
	'tablet',
	'tag',
	'target',
	'terminal',
	'thermometer',
	'thumbs-down',
	'thumbs-up',
	'toggle-left',
	'toggle-right',
	'tool',
	'trash-2',
	'trash',
	'trello',
	'trending-down',
	'trending-up',
	'triangle',
	'truck',
	'tv',
	'twitch',
	'twitter',
	'type',
	'umbrella',
	'underline',
	'unlock',
	'upload-cloud',
	'upload',
	'user-check',
	'user-minus',
	'user-plus',
	'user-x',
	'user',
	'users',
	'video-off',
	'video',
	'voicemail',
	'volume-1',
	'volume-2',
	'volume-x',
	'volume',
	'watch',
	'wifi-off',
	'wifi',
	'wind',
	'x-circle',
	'x-octagon',
	'x-square',
	'x',
	'youtube',
	'zap-off',
	'zap',
	'zoom-in',
	'zoom-out',
];
// heroicons v1.0.1 - 230 icons
export const heroicons = [
	'academic-cap',
	'annotation',
	'adjustments',
	'archive',
	'arrow-circle-down',
	'arrow-circle-left',
	'arrow-circle-right',
	'arrow-circle-up',
	'arrow-down',
	'arrow-left',
	'arrow-narrow-down',
	'arrow-narrow-left',
	'arrow-narrow-right',
	'arrow-narrow-up',
	'arrow-right',
	'arrow-sm-left',
	'arrow-sm-down',
	'arrow-sm-right',
	'arrow-sm-up',
	'arrow-up',
	'arrows-expand',
	'at-symbol',
	'badge-check',
	'backspace',
	'ban',
	'beaker',
	'bell',
	'book-open',
	'bookmark-alt',
	'bookmark',
	'briefcase',
	'cake',
	'calculator',
	'calendar',
	'camera',
	'cash',
	'chart-bar',
	'chart-pie',
	'chart-square-bar',
	'chat-alt-2',
	'chat-alt',
	'chat',
	'check-circle',
	'check',
	'chevron-double-down',
	'chevron-double-left',
	'chevron-double-up',
	'chevron-double-right',
	'chevron-down',
	'chevron-left',
	'chevron-right',
	'chevron-up',
	'chip',
	'clipboard-check',
	'clipboard-copy',
	'clipboard-list',
	'clipboard',
	'clock',
	'cloud-download',
	'cloud-upload',
	'cloud',
	'code',
	'cog',
	'collection',
	'credit-card',
	'color-swatch',
	'cube-transparent',
	'cube',
	'currency-bangladeshi',
	'currency-dollar',
	'currency-euro',
	'currency-pound',
	'currency-rupee',
	'currency-yen',
	'cursor-click',
	'database',
	'desktop-computer',
	'device-mobile',
	'device-tablet',
	'document-add',
	'document-download',
	'document-duplicate',
	'document-remove',
	'document-report',
	'document-search',
	'document-text',
	'document',
	'dots-circle-horizontal',
	'dots-horizontal',
	'dots-vertical',
	'download',
	'duplicate',
	'emoji-happy',
	'emoji-sad',
	'exclamation-circle',
	'exclamation',
	'external-link',
	'eye-off',
	'eye',
	'fast-forward',
	'film',
	'filter',
	'finger-print',
	'fire',
	'flag',
	'folder-add',
	'folder-download',
	'folder-open',
	'folder-remove',
	'folder',
	'gift',
	'globe-alt',
	'globe',
	'hand',
	'hashtag',
	'heart',
	'home',
	'identification',
	'inbox-in',
	'inbox',
	'information-circle',
	'key',
	'library',
	'light-bulb',
	'lightning-bolt',
	'link',
	'location-marker',
	'lock-closed',
	'lock-open',
	'login',
	'logout',
	'mail-open',
	'mail',
	'map',
	'menu-alt-1',
	'menu-alt-2',
	'menu-alt-3',
	'menu-alt-4',
	'menu',
	'microphone',
	'minus-circle',
	'minus-sm',
	'minus',
	'moon',
	'music-note',
	'newspaper',
	'office-building',
	'paper-airplane',
	'paper-clip',
	'pause',
	'pencil-alt',
	'pencil',
	'phone-incoming',
	'phone-missed-call',
	'phone-outgoing',
	'photograph',
	'phone',
	'play',
	'plus-circle',
	'plus-sm',
	'plus',
	'presentation-chart-bar',
	'presentation-chart-line',
	'printer',
	'qrcode',
	'puzzle',
	'question-mark-circle',
	'receipt-refund',
	'receipt-tax',
	'refresh',
	'reply',
	'rewind',
	'rss',
	'save-as',
	'save',
	'scale',
	'scissors',
	'search-circle',
	'search',
	'selector',
	'server',
	'share',
	'shield-check',
	'shield-exclamation',
	'shopping-bag',
	'shopping-cart',
	'sort-ascending',
	'sort-descending',
	'speakerphone',
	'sparkles',
	'star',
	'status-online',
	'status-offline',
	'stop',
	'sun',
	'support',
	'switch-vertical',
	'switch-horizontal',
	'table',
	'tag',
	'template',
	'terminal',
	'thumb-up',
	'thumb-down',
	'ticket',
	'translate',
	'trash',
	'trending-down',
	'trending-up',
	'truck',
	'upload',
	'user-add',
	'user-group',
	'user-circle',
	'user-remove',
	'user',
	'users',
	'variable',
	'video-camera',
	'view-boards',
	'view-grid-add',
	'view-grid',
	'view-list',
	'volume-up',
	'volume-off',
	'x-circle',
	'zoom-in',
	'wifi',
	'zoom-out',
	'x',
];
