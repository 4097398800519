import { Component, Inject } from '@angular/core';
import {
	MAT_BOTTOM_SHEET_DATA,
	MatBottomSheetRef,
} from '@angular/material/bottom-sheet';

@Component({
	selector: 'app-env-drawer',
	templateUrl: './env-drawer.component.html',
})
export class EnvDrawerComponent {
	constructor(
		@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
		private bottomSheetRef: MatBottomSheetRef<EnvDrawerComponent>,
	) {}

	selectOption(value: string): void {
		this.bottomSheetRef.dismiss(value);
	}
}
