import { Component, HostBinding } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { AuthService } from './core/auth/auth.service';
import { GTM_EVENT_CLICK, GTM_EVENT_PAGE } from './interface/gtm.interface';
import { GdprBottomSheet } from './modules/admin/components/gdpr/gdpr.component';
import { PromoCodeService } from './modules/admin/components/promo-code/promo-code.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	@HostBinding('class.dark') isDark: boolean = false;

	public isHelp: boolean = false;

	constructor(
		private readonly _matIconRegistry: MatIconRegistry,
		private readonly _domSanitizer: DomSanitizer,
		private readonly _authService: AuthService,
		private readonly _bottomSheet: MatBottomSheet,
		private readonly _router: Router,
		private readonly _route: ActivatedRoute,
		private readonly _gtm: GoogleTagManagerService,
		private readonly _promoService: PromoCodeService,
	) {
		this.initIcons();

		this._authService.getUserData().subscribe((value) => {
			if (value) this.checkGdpr(value);
		});

		this._router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.isDark = this._router.url === '/project' ? true : false;
				this.isHelp = this._router.url === '/project' ? false : true;

				const params = this._route.snapshot.queryParams;
				const promocode = this._promoService.getPromoCodeValue();
				if (!promocode && params.promocode) {
					this._promoService.setPromoCode(params.promocode);
				}

				// if (environment.production) {
				const data: GTM_EVENT_PAGE = {
					event: 'page',
					pageUrl: event.url,
				};
				this._gtm.pushTag(data);
				// }
			}
		});
	}

	private checkGdpr(userData: any) {
		if (!userData.hasApprovedGDPR) {
			setTimeout(() => {
				this._bottomSheet.open(GdprBottomSheet, {
					disableClose: true,
					hasBackdrop: false,
					closeOnNavigation: false,
					panelClass: 'ready-gdpr',
				});
			}, 5000);
		}
	}

	private initIcons() {
		// Header Icons
		this._matIconRegistry.addSvgIcon(
			'header-notifications',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/header-notifications.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'header-wallet',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/header-wallet.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'header-shopping-cart',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/header-shopping-cart.svg',
			),
		);

		// Menu Icons
		this._matIconRegistry.addSvgIcon(
			'menu-achievements',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/menu-achievements.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'menu-analytics',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/menu-analytics.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'menu-play',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/play-token.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'menu-leaderboard',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/menu-leaderboard.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'menu-minting-desk',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/menu-minting-desk.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'menu-overview',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/menu-overview.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'menu-virtual-items',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/menu-virtual-items.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'menu-signout',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/menu-signout.svg',
			),
		);

		// Utils
		this._matIconRegistry.addSvgIcon(
			'utils-info-outline',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/utils-info-outline.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'utils-file-upload',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/utils-file-upload.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'utils-file',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/utils-file.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'utils-link',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/utils-link.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'utils-calendar',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/utils-calendar.svg',
			),
		);

		// Logos
		this._matIconRegistry.addSvgIcon(
			'logo-etherium',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/logo-etherium.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'logo-play',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/logo-play.svg',
			),
		);

		// Payment
		this._matIconRegistry.addSvgIcon(
			'payment-alipay',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/payment-alipay.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'payment-credit-card',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/payment-credit-card.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'payment-wechat',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/payment-wechat.svg',
			),
		);

		// Review icons below
		this._matIconRegistry.addSvgIcon(
			'android_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/android-os.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'ios_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/ios-logo.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'info_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/info.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'download_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/download.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'unity_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/unity-logo.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'facebook_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/facebook-f.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'google_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/google.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'retention_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/retention.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'engagement_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/engagement.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'installs_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/installs.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'play_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/play-token.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'aura_color_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/AuraIcon.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'balance_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/balance.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'balance_staked_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/balance_staked.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'balance_unvested_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/balance_unvested.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'calendar_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/calendar.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'calendar_percent_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/calendar_percent.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'calendar_vest_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/calendar_vest.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'cycle_supply_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/cycle_supply.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'cycle_user_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/cycle_user.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'fuseblock_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/fuseblock.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'fuseblock_balance_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/fuseblock_balance.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'fuseblock_balance_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/fuseblock_balance.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'governance_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/governance.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'install_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/install.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'mining_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/mining.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'price_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/price.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'refer_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/refer.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'reward_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/reward.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'staking_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/staking.svg',
			),
		);
		this._matIconRegistry.addSvgIcon(
			'user_group_icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/icons/user_group.svg',
			),
		);
	}

	public onSupport() {
		const data: GTM_EVENT_CLICK = {
			event: 'button_click',
			buttonType: 'support',
			buttonLocation: 'float',
		};
		this._gtm.pushTag(data);
	}
}
