// Angular
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

// Angular Material
import { MatIconModule } from "@angular/material/icon";

// Components
import { GrantButtonComponent } from './grant-button.component';

@NgModule({
    imports: [
        // Angular
        CommonModule,

        // Angular Material
        MatIconModule,
    ],
    declarations: [GrantButtonComponent],
    exports: [GrantButtonComponent],
})

export class GrantButtonModule { }
