import { NgModule, isDevMode, APP_INITIALIZER } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';

import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { MarkdownModule } from 'ngx-markdown';
import { NgxStripeModule } from 'ngx-stripe';

import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';

import { CoreModule } from 'app/core/core.module';
import { LayoutModule } from 'app/layout/layout.module';

import { environment } from '../environments/environment';
import { appConfig } from 'app/core/config/app.config';
import { appRoutes } from 'app/app.routing';

import { mockApiServices } from 'app/mock-api';
import { AuthNewService } from './core/auth/auth.new.service';
import { AppInterceptor } from './app.interceptor';

import { AppComponent } from 'app/app.component';
import { GdprBottomSheet } from './modules/admin/components/gdpr/gdpr.component';

function initializeAppFactory(authService: AuthNewService) {
	return () => authService.init();
}

const routerConfig: ExtraOptions = {
	preloadingStrategy: PreloadAllModules,
	scrollPositionRestoration: 'enabled',
};

@NgModule({
	declarations: [AppComponent, GdprBottomSheet],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule.forRoot(appRoutes, routerConfig),

		AngularFireStorageModule,
		AngularFireModule.initializeApp(
			environment.readyGamesDeveloper,
			'firebase',
		),

		// Angular
		MatBottomSheetModule,
		MatButtonModule,
		MatIconModule,
		MatProgressSpinnerModule,

		// Fuse, FuseConfig & FuseMockAPI
		FuseModule,
		FuseConfigModule.forRoot(appConfig),
		FuseMockApiModule.forRoot(mockApiServices),

		// Core module of your application
		CoreModule,

		// Layout module of your application
		LayoutModule,

		// 3rd party modules that require global configuration via forRoot
		MarkdownModule.forRoot({}),
		LoggerModule.forRoot({
			// serverLoggingUrl: '/api/logs',
			// serverLogLevel: NgxLoggerLevel.ERROR
			level: isDevMode() ? NgxLoggerLevel.TRACE : NgxLoggerLevel.ERROR,
		}),

		NgxStripeModule.forRoot(environment.stripe.publishableKey),
	],
	providers: [
		{ provide: 'googleTagManagerId', useValue: 'GTM-MHXDB3X' },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AppInterceptor,
			multi: true,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: initializeAppFactory,
			deps: [AuthNewService],
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
