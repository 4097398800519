// Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

// Angular Material
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

// Service
import { ShoppingCartService } from 'app/modules/admin/project/shopping-cart/shopping-cart.service';

// Components
import { ShoppingCartHeaderComponent } from './view/shopping-cart.component';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
	imports: [
		// Angular
		CommonModule,
		RouterModule,
		SharedModule,

		// Angular Material
		MatIconModule,
		MatDialogModule,
	],
	providers: [ShoppingCartService],
	declarations: [ShoppingCartHeaderComponent],
	exports: [ShoppingCartHeaderComponent],
})
export class ShoppingCartHeaderModule {}
