import {
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import {
	FuseNavigationItem,
	FuseNavigationService,
	FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { AuthNewService } from 'app/core/auth/auth.new.service';
import { IAuthNewUserData } from 'app/core/auth/interface/auth.new.interface';
import { DeviceService } from 'app/core/services/device.service';

@Component({
	selector: 'classy-layout',
	templateUrl: './classy.component.html',
	styleUrls: ['./classy.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
	navigation: FuseNavigationItem[];
	user: IAuthNewUserData;
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	isMobile: boolean;

	/**
	 * Constructor
	 */
	constructor(
		private _navigationService: NavigationService,
		private _fuseNavigationService: FuseNavigationService,
		private _authService: AuthNewService,
		_device: DeviceService,
	) {
		this.isMobile = _device.isMobile();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Getter for current year
	 */
	get currentYear(): number {
		return new Date().getFullYear();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		// Subscribe to navigation data
		this._navigationService.navigation$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((navigation: Navigation) => {
				this.navigation = this.selectNavigationItem(navigation);
			});

		this._authService.user$.subscribe((data) => {
			this.user = data;
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Toggle navigation
	 *
	 * @param name
	 */
	toggleNavigation(name: string): void {
		// Get the navigation
		const navigation =
			this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
				name,
			);

		if (navigation) {
			// Toggle the opened status
			navigation.toggle();
		}
	}

	selectNavigationItem(navigation: Navigation): FuseNavigationItem[] {
		return navigation.default;

		// if (this.authService?.realMasterData?.isApprovedDeveloper) {
		//     return navigation.default;
		// }

		// return navigation.preLaunch;
	}

	closeNavigation(): void {
		const navigation =
			this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
				'mainNavigation',
			);

		if (navigation) {
			navigation.close();
		}
	}
}
