import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PromoCodeService } from 'app/modules/admin/components/promo-code/promo-code.service';
import { AuthNewService } from '../auth.new.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad
{
    constructor(
        private readonly _authService: AuthNewService,
        private readonly _router: Router,
        private readonly _promoService: PromoCodeService,
    ) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
    {
        const redirectUrl = state.url === '/auth/sign-out' ? '/' : state.url;
        return this._check(redirectUrl, route.queryParams);
    }

    /**
     * Can activate child
     *
     * @param childRoute
     * @param state
     */
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        const redirectUrl = state.url === '/auth/sign-out' ? '/' : state.url;
        return this._check(redirectUrl, childRoute.params);
    }

    /**
     * Can load
     *
     * @param route
     * @param segments
     */
    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean
    {
        return this._check('/', null);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @param redirectURL
     * @private
     */
    private async _check(redirectURL: string, params: any): Promise<boolean>
    {
        if (!this._authService.isAuthenticated()) {
            console.log('init refreshToken');
            await this._authService.refreshToken();
        }

        if (params && params.promocode) {
            this._promoService.setPromoCode(params.promocode);
        }

        if (!this._authService.isAuthenticated()) {
            this._router.navigate(['/auth/sign-in'], {queryParams: {redirectURL}});
            return false;
        }

        /**
         * @deprecated
         * Maybe this will be reactivated in the future when the functionality
         * for `isApprovedDeveloper` can be enabled.
         */
        // if (response.type === 'approvedDeveloper' && redirectURL.includes('project')) {
        //     const split = redirectURL.split('/');
        //     const index = split[0] === '' ? 1 : 0;
        //     const link = split[index] === 'project' ? 'sign-up-flow' : split[index];
        //     this._router.navigate(['welcome/' + link]);
        // }

        return true;
    }
}
