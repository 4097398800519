/* eslint-disable @typescript-eslint/no-unused-vars */
import { UrlService } from 'app/url.service';
import { Observable, of, Subject } from 'rxjs';
import { PaymentData } from '../defs/shop';

interface ChangedProp {
    prop: string;
    value: unknown;
}

export enum PaymentStatus {
    UserCancelled = -2,
    Failed = -1,
    Processing = 0,
    Success = 1
}

export interface PaymentResult {
    status: PaymentStatus,
    errorMessage?: string;
    details?: any;
}

export class BasePaymentProvider extends UrlService {
    private _props: Record<string, unknown> = {};
    protected _propsChanged = new Subject<ChangedProp>();
    get propsChanged$() { return this._propsChanged.asObservable(); }

    prepare(params: PaymentData): Observable<boolean> {
        return of(true);
    }

    reset() { }

    pay(
        userData: Record<string, unknown>,
        itemParams: Record<string, unknown>,
    ): Observable<PaymentResult> {
        throw new Error('pay() not implemented in class-inheritor');
    }

    getProp(prop: string): unknown {
        return (typeof (this._props[prop]) !== 'undefined')
            ? this._props[prop]
            : undefined;
    }

    protected setProp(prop: string, value: unknown) {
        this._props[prop] = value;

        this._propsChanged.next({ prop, value });
    }

    /**
     * Handle messages from UI iframe
     * @param event
     */
    onUiMessage(event: MessageEvent) { }
}
