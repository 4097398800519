import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable()
export class HttpErrorViewerInterceptor implements HttpInterceptor
{
    constructor(
      private _snackBar: MatSnackBar
      ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        let newReq = req.clone();

        return next.handle(newReq).pipe(
            catchError((error) => {
                this.showError(error.error.message ? error.error.message : error.error);
                throw error;
            })
        );
    }
    private showError(message: string) {
      this._snackBar.open("Backend response error: " + message, 'Ok', {
        panelClass: 'snackbar-error',
        duration: 10000,
      });
    }
}
