import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { EnvDrawerComponent } from './env-drawer.component/env-drawer.component';

@Component({
	selector: 'app-env-switcher',
	templateUrl: './env-switcher.component.html',
	styleUrls: ['./env-switcher.component.scss'],
})
export class EnvSwitcherComponent implements OnInit {
	public envName = environment.envName;
	public altEnv = environment.altEnvInstance;

	constructor(private bottomSheet: MatBottomSheet) {}

	ngOnInit(): void {}

	openBottomSheet(): void {
		const bottomSheetRef = this.bottomSheet.open(EnvDrawerComponent, {
			data: {
				envName: this.envName,
				altEnv: this.altEnv,
			},
		});
	}
}
