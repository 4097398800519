<ng-template #toggleMenuIcon>
	<mat-icon
		(click)="onToggleSidebar()"
		fontSet="material-icons-round"
		class="cursor-pointer hover:text-violet text-3xl text-white horizontal:w-full"
	>
		menu
	</mat-icon>
</ng-template>
<ng-template #projectSwitcher>
	<app-project-switcher
		class="flex-none md:flex-1 min-w-0 max-w-40"
	></app-project-switcher>
</ng-template>

<div
	class="toolbar-outer w-full horizontal:h-screen horizontal:items-start z-50 print:hidden horizontal:p-0 px-7 pt-5 md:pt-10 md:px-10"
>
	<div
		class="toolbar-inner flex items-center horizontal:flex-col pb-2 horizontal:h-screen"
	>
		<div
			id="left-icons-block"
			class="flex toolbar-icons items-center horizontal:items-start gap-0 horizontal:flex-col horizontal:mt-5"
		>
			<div class="flex horizontal:hidden">
				<ng-container [ngTemplateOutlet]="toggleMenuIcon"></ng-container>
			</div>

			<a
				[ngClass]="{ sideBarOpen: showLogo }"
				href="https://myplay.network/"
				class="logo horizontal:absolute inline-block ml-6"
			>
				<img
					src="assets/images/logo/logo-play.png"
					class="min-w-28 w-28 h-auto"
				/>
			</a>
		</div>

		<div
			class="w-full md:w-auto flex items-center horizontal:flex-1 horizontal:items-start horizontal:flex-col-reverse ml-auto gap-5 overflow-hidden"
		>
			<ng-container *ngIf="user">
				<div
					class="flex items-center justify-end flex-1 horizontal:flex-col horizontal:justify-evenly gap-0"
				>
					<div class="horizontal:flex h-auto hidden">
						<ng-container [ngTemplateOutlet]="toggleMenuIcon"></ng-container>
					</div>

					<div *ngIf="!isMobile" class="mr-3">
						<ng-container [ngTemplateOutlet]="projectSwitcher"></ng-container>
					</div>

					<div *ngIf="!isMobile" class="mr-6">
						<wallet-connect></wallet-connect>
					</div>
					<div class="min-w-14">
						<app-env-switcher></app-env-switcher>
					</div>

					<div *ngIf="!isMobile">
						<notifications></notifications>
					</div>
					<div *ngIf="!isMobile">
						<shopping-cart></shopping-cart>
					</div>

					<button
						class="min-w-14"
						mat-icon-button
						[routerLink]="['/auth/sign-out']"
					>
						<mat-icon
							class="hover:text-white icon-size-7"
							svgIcon="mat_solid:logout"
						></mat-icon>
					</button>
				</div>
			</ng-container>
		</div>
	</div>
</div>
