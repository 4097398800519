// Angular
import { Component } from '@angular/core';

// Angular Material
import { MatSnackBar } from '@angular/material/snack-bar';

// Service
import { PromoCodeService } from './promo-code.service';

// Config
import { snackError, snackSuccess } from 'app/core/config/snackbar.config';

@Component({
    selector: 'promo-code',
    templateUrl: 'promo-code.component.html',
    styleUrls: ['promo-code.component.scss'],
})

export class PromoCodeComponent {
    public hasCode: boolean;
    public promoCode: string;
    public onClaim: boolean = false;

    constructor(
        private readonly _promoCodeService: PromoCodeService,
        private readonly _snackBar: MatSnackBar,
    ) {
        this.promoCode = this._promoCodeService.getPromoCodeValue();
        this.hasCode = !!this.promoCode;

        this._promoCodeService.getPromoObject().subscribe(value => {
            this.onClaim = false;

            if (value && value.type && value.message) {
                const config = value.type === 'error' ? snackError : snackSuccess;
                return this._snackBar.open(value.message, 'Ok', config);
            }
        });
    }

    public claimCode() {
        this.onClaim = true;
        this._promoCodeService.addUserToPromoCode();
    }

    public changeCode() {
        this._promoCodeService.openDialog();
    }
}
