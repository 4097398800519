import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface DropdownItem {
    name: string;
    value: string;
}

@Component({
  selector: 'app-aura-dropdown',
  templateUrl: './aura-dropdown.component.html',
  styleUrls: ['./aura-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AuraDropdownComponent),
    }
  ]
})
export class AuraDropdownComponent implements OnInit, ControlValueAccessor {
  @Input() items: DropdownItem[] = [];
  @Input() value: string | null = null;
  @Input() dropdownClass = '';

  @Output() change = new EventEmitter<string>();

  selected!: DropdownItem;
  onChanged!: Function;
  onTouched!: Function;
  disabled = false;

  constructor() { }

  ngOnInit(): void {
    if (this.value) {
        this.selected = this.items.find((i) => i.value === this.value);
    }
  }

  selectItem(item: DropdownItem) {
    this.selected = item;
    this.change.emit(item.value);

    if (this.onChanged) this.onChanged(item);
    if (this.onTouched) this.onTouched(true);
  }

  writeValue(item: DropdownItem) {
    this.selected = item;
  }

  registerOnChange(fn: Function) {
    this.onChanged = fn;
  }

  registerOnTouched(fn: Function) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
