import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthNewService } from './core/auth/auth.new.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
    constructor(
        private readonly _authNewService: AuthNewService,
    ){ }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let newReq = req.clone();
        const accessToken = this._authNewService.getUserToken();

        if (accessToken) {
            newReq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + accessToken)
            });
        }

        // Response
        return next.handle(newReq).pipe(
            catchError((error) => {
                if ( error instanceof HttpErrorResponse && error.status === 401 ) {
                    this._authNewService.signOut();
                    location.reload();
                }

                return throwError(() => error);
            })
        );
    }
}
