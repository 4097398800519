<ng-container *ngIf="altEnv && envName">
	<div
		class="min-w-44 flex flex-col items-start hidden sm:block horizontal:hidden"
	>
		<div class="text-white relative px-4">
			<span class="mr-2 light-on"></span>
			{{ envName }}
		</div>
		<a
			class="relative px-4 hover:text-opacity-100 text-white text-opacity-50"
			[href]="altEnv.url"
		>
			<span class="mr-2 light-off"></span>
			Switch to {{ altEnv.name }}
		</a>
	</div>

	<div class="env-switcher block sm:hidden horizontal:block">
		<a>
			<div class="icon-container cursor-pointer" (click)="openBottomSheet()">
				<div class="light-icon">
					<span class="mr-2 light-on"></span>
				</div>
				<mat-icon class="small-icon text-white">swap_horiz</mat-icon>
			</div>
		</a>
	</div>
</ng-container>
