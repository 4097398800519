// Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// Angular Material
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

// Components
import { EnvSwitcherComponent } from './env-switcher.component';
import { MatListModule } from '@angular/material/list';
import { EnvDrawerComponent } from './env-drawer.component/env-drawer.component';

@NgModule({
	imports: [CommonModule, MatIconModule, MatListModule],
	declarations: [EnvSwitcherComponent, EnvDrawerComponent],
	exports: [EnvSwitcherComponent],
})
export class EnvSwitcherModule {}
